<template>
    <div class="mainer">
        <!-- 导航栏 -->
        <van-cell-group inset v-if="data.copise.je">
            <van-cell title="住院号" size="large" :value="data.zyxx.zyh">
                <template #label>
                    <span style="font-size: 12px">病人姓名:{{ data.zyxx.byxm }} 病人性别:{{ data.zyxx.xb }}</span>
                </template>
            </van-cell>

            <van-cell title="收件人姓名" size="large" :value="data.Address.name">
                <template #label>
                    <span style="font-size: 12px">收件人电话：{{ data.Address.tel }} </span>
                </template>
            </van-cell>


            <van-cell title="收件地区" size="large" :value="data.Addresscomplex">
                <template #label>
                    <span style="font-size: 12px">详细地址{{ data.Address.addressDetail }} </span>
                </template>
            </van-cell>

            <van-cell title="费用总额" size="large" is-link>
                <template #right-icon>
                    <span style="color: #ED6A0C;">¥{{ data.copise.je }}</span>
                </template>

            </van-cell>

            <van-cell-group inset>
                <van-cell size="small" v-for="(item, key) in data.copise.copiselist" :key="key" :title="item.mc"
                    :value="item.je" />

            </van-cell-group>




            <div class="button">
                <van-button type="primary" round block @click="order"> 确定下单</van-button>
            </div>
            <div class="shuoming">缴费成功以微信通知为准</div>

        </van-cell-group>
        <van-empty description="没有住院信息" v-else></van-empty>

    </div>
</template>
  
<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


const emit = defineEmits(['getorderid'])

const props = defineProps([
    "zyxx",
    "Address",
    "copise"
]
)
var data = ref({
    zyxx: null,
    Address: null,
    Addresscomplex: "",
    copise: []

}
)

function order() {
    let params = {
        lx: 'createorder',
        openid: useopenidStore().data.openid,
        card_no: data.value.zyxx.zykh,
        TradeType: 'JSAPI',
        order_type: 'copiseofcase',
        zje: data.value.copise.je,
        discount: 0,
        yhhje: data.value.copise.je,


        zyh: data.value.zyxx.zyh,
        byxm: data.value.zyxx.byxm,
        name: data.value.Address.name,
        tel: data.value.Address.tel,
        country: data.value.Address.country,
        province: data.value.Address.province,
        city: data.value.Address.city,
        county: data.value.Address.county,
        areaCode: data.value.Address.areaCode,
        addressDetail: data.value.Address.addressDetail,
        je: data.value.copise.je,
        list: data.value.copise.copiselist
    }
    console.log(params)
    console.log('病历复印下单入参')
    console.log(params)

  postJson('WxpayService', params, 2).then(result => {

    console.log(JSON.stringify(result.data));

    if (result.data != null) {
      console.log("拉起收银台1");
      const temp = {
        appId: result.data.appId,
        timeStamp: result.data.timeStamp,
        nonceStr: result.data.nonceStr,
        package: result.data.packageValue,
        signType: result.data.signType,
        paySign: result.data.paySign,
      }
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', temp,
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // router.replace({ path: '/PaymentResult', query: { orderid: result.data.orderid } });
            emit('getorderid',result.data.orderid)
            // wx.closeWindow();
          }
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。     
        }
      );

      // router.replace({ path: '/todayResult', query:{orderid: result.data.orderid}});
    }
    else {
      unlockSerial()
      showNotify({
        type: 'danger',
        message: '下单失败'
      })

    }
  }).catch(err => {
    // Indicator.close();
    console.log(err);
    showFailToast('下单失败')
    unlockSerial()

  });
}


onMounted(() => {
    console.log('进入order')
    console.log(props.zyxx)
    console.log(props.Address)
    console.log(props.copise)
    data.value.openid = useopenidStore().data.openid
    data.value.zyxx = props.zyxx
    data.value.Address = props.Address
    data.value.Addresscomplex = data.value.Address.province + '/' + data.value.Address.city + '/' + data.value.Address.county
    data.value.copise = props.copise

}
)







</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.office {
    width: 100%;
}

.shuoming {
    width: 90%;
    margin: 12px auto;
    font-size: 14px;
    color: #969799;
    text-align: center;
}
</style>