<template>
    <div class="mainer">
        <!-- 导航栏 -->
        <van-nav-bar title="交款记录" @click-left="onClickLeft"  @click-right="onClickRight">      
            <template #left>
                <van-icon name="arrow-left" /> 返回
            </template>
            <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
        </van-nav-bar>



        <div class="parter">
            <div v-if="data.jkjllist.length">
                <van-cell-group inset v-for="(info, i) in data.jkjllist" :key="k">
                    <van-cell title="交款收据号" size="large" :value="info.jksjh" />
                    <van-cell title="交款方式" size="large" :value="info.jkfs" />
                    <van-cell title="交款类型" size="large" :value="info.cyk" />
                    <van-cell title="交款时间" size="large" :value="new Date(info.skrq).format('yyyy-MM-dd')" />
                    <van-cell title="交款金额" size="large">
                        <template #right-icon>
                            <span style="color: #ED6A0C;">¥{{ info.jkje }}</span>
                        </template>
                    </van-cell>
                    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
                        下一个记录
                    </van-divider>
                </van-cell-group>


            </div>

            <van-empty description="暂无交款记录" v-else />
        </div>

        <!--切换就诊人 -->
        <van-popup v-model:show="data.show" round closeable position="bottom">
            <div class="footer">
                <van-cell-group>
                    <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
                        v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
                </van-cell-group>
            </div>
        </van-popup>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data = ref({
    openid: '',
    show: false,
    cardlist: [],
    defaultCard: {},
    jkjllist:[]

}
)

function onClickRight(){
  router.push({ path: "/" });
}


function getjkjl() {
    let params = {
        lx: 'getjkxx',
        zyh:router.currentRoute.value.query.zyh
    }
    console.log('交款记录查询入参')
    console.log(params)
    postJson('Wechat/Service', params, 2).then(res => {
        data.value.jkjllist = res.data
        console.log('交款记录查询出参')
    console.log(data.value.jkjllist)
    })
}

onMounted(() => {
  getjkjl()
}
)

function getcardlist() {
    let params = {
        lx: 'getcardbyopenid',
        openid: data.value.openid
    }
    console.log(params)
    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 10000
    });

    postJson('WxpayService', params, 2).then(res => {
        console.log(res)
        if (res != null) {
            if (res.data.length) {
                console.log('查询cardlist正常返回')
                data.value.cardlist = res.data
                bindcard();
                console.log(data.value.cardlist)
                closeToast();
            } else {
                showToast('请先绑定就诊卡');

            }
        }
    }).catch(err => {
        console.log('查询cardlist错误返回')
        console.log(err)
        showFailToast(err)
    })
}

function bindcard() {
    if (data.value.cardlist.length > 0) {
        data.value.cardlist.forEach(element => {
            console.log(element)
            if (element.xz) {
                data.value.defaultCard = element
                console.log(element)
            }
        });
    }
    else {
        showNotify({ type: "danger", message: "没有卡号" })
    }
}

function onClickLeft() {
    history.back();
}



function choosecard(k) {
    data.value.defaultCard = data.value.cardlist[k]
    data.value.show = false;
    setDefault(data.value.defaultCard.id)
}

function setDefault(id) {
    console.log('默认值id:' + id)
    let params = {
        lx: 'setdefaultcard',
        id: id,
        openid: data.value.openid,
    }
    postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret == 0) {
            getcardlist()

        }
        else {

            showFailToast(res.data.reinfo);
        }
    })
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.office {
    width: 100%;
}
</style>