<template>
  <div class="user">
    <van-nav-bar title="投诉建议" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>
    <div class="bodyer">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field name="content" v-model="data.content" rows="10" autosize label="" type="textarea" maxlength="200"
            placeholder="感谢您留下的投诉和建议，我们将不断优化服务体验～" show-word-limit :rules="[{ required: true, message: '请输入您的意见和建议' }]" />
          <van-field name="mobile" v-model="data.mobile" type="tel" label="手机号" placeholder="请输入您的手机号" maxlength="11"
            :rules="[{ required: true, message: '请输入您的手机号' }]" />
            <van-field
              name="code"
              v-model="data.code"
              center
              clearable
              label="验证码"
              placeholder="请输入短信验证码"
              type="digit"
              maxlength="4"
              :rules="[{ required: true, message: '请输入短信验证码' }]"
            >
              <template #button>
                <van-button size="small" type="primary" @click="sendSMS" :disabled="data.disabled">{{ data.daojishi }}</van-button>
              </template>
            </van-field>  
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block :loading="loading" type="primary" loading-text="提交中..." native-type="submit">确认提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify, showSuccessToast } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

var data =ref({
     openid:"",
      mobile: "",
      content: "",
      code: "",
      timerId: null,
      loading: false,
      disabled: false,
      daojishi: "发送验证码",
      total:60
})

const onClickLeft = () => history.back();
const onClickRight = () => router.push({ path: "/" });

onMounted(()=>{
data.value.openid=useopenidStore().data.openid;

})

function sendSMS() {
      if (!data.value.mobile) {
        showToast("请输入手机号");
        return;
      }
      data.value.disabled = true

    const params = {
    lx: "getverificationcode",
    name: '投诉建议',
    cardno: '100000001',
    mobile: data.value.mobile,
  }

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res.data.ret == 0) {
      showToast({
        message: res.data.reinfo,
        position: 'top'
      });

      data.value.total = 60
      let clock = window.setInterval(() => {
        data.value.total--
        data.value.daojishi = data.value.total + 's后重新发送'
        if (data.value.total <= 0) {
          window.clearInterval(clock)
          data.value.daojishi = '发送验证码'
          data.value.total = 60
          data.value.disabled = false
        }
      }, 1000)
    } else {
      data.value.disabled = false
      showFailToast({
        message: res.data.reinfo,
        position: 'top'
      });
    }
  })
}

function onSubmit(form) {
      data.value.loading = true      
      let params=form
      params.name='投诉建议';
      params.cardno='100000001';
      params.openid=data.value.openid
      params.lx='feedback'
      console.log("入参")
      console.log(form)
      postJson('WxpayService',params,2).then(res => {
        data.value.loading = false
        if (res.data.ret === 0) {
          data.value.contact = ""
          data.value.content = ""
          data.value.code = ""
       
          data.value.disabled = false
          data.value.daojishi = "发送验证码"
          data.value.timerId = null; 
          showToast({
            message: '感谢您的反馈！',
            forbidClick: true,
            duration: 3000,
            onClose: function () {
              router.push({ path: "/" });
            }
          });
        } else {
          showFailToast(res.data.reinfo);
        }
      })
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 16px;
}
</style>
