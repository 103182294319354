<template>
<div class="mainer">
<!-- 导航栏 -->
<van-nav-bar :title=data.ghlxmc @click-left="onClickLeft" @click-right="onClickRight">
<template #right>
切换就诊人
<van-icon name="exchange" size="18" />
</template>
<template #left>
<van-icon name="arrow-left" /> 返回
</template>
</van-nav-bar>

<van-cell-group class="byxx">
<van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
 :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
</van-cell>
<van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
<template #title>
  <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
</template>

</van-cell>
</van-cell-group>

<!--切换就诊人 -->
<van-popup v-model:show="data.show" round closeable position="bottom">
<div class="footer">
<van-cell-group>
<van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
</van-cell-group>
</div>
</van-popup>
</div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data = ref({
openid: '',
show: false,
cardlist: [],
defaultCard: {}
}
)


onMounted(() => {
data.value.openid = useopenidStore().data.openid
getcardlist()
}
)

function getcardlist() {
let params = {
lx: 'getcardbyopenid',
openid: data.value.openid
}
console.log(params)
showLoadingToast({
message: '加载中...',
forbidClick: true,
loadingType: 'spinner',
duration: 100000
});

postJson('WxpayService', params, 2).then(res => {
console.log(res)
if (res != null) {
if (res.data.length) {
console.log('查询cardlist正常返回')
data.value.cardlist = res.data
bindcard();
console.log(data.value.cardlist)
closeToast();
} else {
showToast('请先绑定就诊卡');
      
}
}
}).catch(err => {
console.log('查询cardlist错误返回')
console.log(err)
showFailToast(err)
})
}

function bindcard() {
if (data.value.cardlist.length > 0) {
data.value.cardlist.forEach(element => {
console.log(element)
if (element.xz) {
data.value.defaultCard = element
console.log(element)
}
});
}
else {
showNotify({ type: "danger", message: "没有卡号" })
}
}

function onClickLeft() {
history.back();
}


function onClickRight() {
data.value.show = true
}
function choosecard(k) {
data.value.defaultCard = data.value.cardlist[k]
data.value.show = false;
setDefault(data.value.defaultCard.id)
}

function setDefault(id) {
console.log('默认值id:' + id)
let params = {
lx: 'setdefaultcard',
id: id,
openid: data.value.openid,
}
postJson('WxpayService', params, 2).then(res => {
if (res.data.ret == 0) {
getcardlist()

}
else {

showFailToast(res.data.reinfo);
}
})
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
background: #1989fa;

/deep/ .van-nav-bar__title {
color: white;
}

/deep/ .van-icon {
color: white;
}

/deep/ .van-nav-bar__right {
color: white;
}

/deep/ .van-nav-bar__left {
color: white;
}
}

.byxx {
background-color: #888;
}

.office {
width: 100%;
}
</style>