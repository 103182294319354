<template>
  <div class="mainer">
    <van-nav-bar title="门诊缴费" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right v-if="data.cardlist">
        切换就诊人
        <van-icon name="exchange" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>

    <div class="bodyer">
      <van-cell-group>
        <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
          :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard"></van-cell>
        <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
          <template #title>
            <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
          </template>
        </van-cell>
      </van-cell-group>


      <div v-if="data.paylist.length">
        <div class="parter" v-for="(waitPay, k) in data.paylist" :key="k">
          <div v-if="waitPay.sfmx.length">
            <van-checkbox-group v-model="waitPay.checked" shape="square">
              <!-- 通过 CellGroup 的 inset 属性，可以将单元格转换为圆角卡片风格 -->
              <van-cell-group inset>
                <van-cell size="large">
                  <template #title>
                    就诊科室
                  </template>
                  <template #right-icon>
                    {{ waitPay.ksmc }}
                  </template>
                </van-cell>

                <van-cell size="large">
                  <template #title>
                    门 诊 号
                  </template>
                  <template #right-icon>
                    {{ waitPay.mzh }}
                  </template>
                </van-cell>

                <van-cell size="large" v-for="(item, key) in waitPay.sfmx" :key="key">
                  <template #title>
                    <van-checkbox :name="item.sxh">{{ item.xmmc }}</van-checkbox>
                  </template>
                  <template #label>
                    <span style="font-size: 12px">¥{{ item.dj }} / {{ item.dw }} * {{ item.sl }} {{ item.ysxm }} <hr/>注意事项： {{item.zysx}} </span>
                  </template>
                  <template #right-icon>
                    ¥{{ item.yhhje }}
                  </template>
                  

                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
            <!-- <div class="shuoming"><van-checkbox  shape="square" v-model="invoice" checked-color="#67C23A" icon-size="16px">需要纸制发票，选中即可到病员服务中心领取</van-checkbox></div> -->
            <div style="margin: 16px;">
              <van-button round block type="primary" @click="payOrder(waitPay, 'wxpay')">
                微信支付
              </van-button>             

              <div class="shuoming">缴费成功以微信通知为准</div>
            </div>
          
              <div style="margin: 16px;">
                <van-button round block type="primary" :disabled="data.ybzfDisabled"  @click="payOrder(waitPay,'ybpay')">
                医保支付
              </van-button>          
              <div class="shuoming">缴费成功以微信通知为准</div>
            </div>

          </div>
        </div>

      </div>
      <div v-else>
        <van-empty description="暂无待缴门诊收费" />
      </div>

      <van-popup v-model:show="data.show" round closeable position="bottom">
        <div class="footer">
          <van-cell-group>
            <van-cell icon="user-o" size="large" :title="card.xm" :label="card.kh" value="选择" is-link
              v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
          </van-cell-group>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify, showSuccessToast } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

const onClickLeft = () => history.back();
var data = ref({
  ybzfDisabled:true,
  show: false,
  cardlist: [],
  defaultCard: null,
  activeName: 'a',
  paylist: [],
  openid: '',
  dabh:''
})

function setDefaultbydabh(){

  if (data.value.dabh){
  data.value.cardlist.forEach(element => {
    
      if (element.kh==data.value.dabh) {
       //  setDefault(element.id);
         data.value.defaultCard = element
         console.log('缺省卡1')
        console.log(data.value.defaultCard)
        
      }
    });
 
}

}

function bindcard() {
  console.log("进入绑卡函数")
  console.log('cardlist')
  console.log(data.value.cardlist)
  console.log('data.value.dabh')
  console.log(data.value.dabh)


 if (data.value.cardlist.length > 0) {
    data.value.cardlist.forEach(element => {
      console.log(element)
      if (element.xz) {
        data.value.defaultCard = element
        console.log('缺省卡2')
        console.log(data.value.defaultCard)
        getdsfxx()

      }
    });
  }
  else {
    showNotify({ type: "danger", message: "没有卡号" })
  }
}



//获取卡信息
function getcardlist() {
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }
  console.log(params)

  console.log("查询cardlist")
  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        console.log('查询cardlist正常返回')

        data.value.cardlist = res.data
        bindcard()
        setDefaultbydabh()
        console.log(data.value.cardlist)

      } else {
        showToast('请先绑定就诊卡');
        showFailToast(err)
      }
    }
  }).catch(err => {
    console.log('查询cardlist错误返回')
    console.log(err)
    showFailToast(err)
  })
}


onMounted(() => {
  data.value.openid = useopenidStore().data.openid
  if (!data.value.openid) {
    showFailToast('未获取到openid');
    history.back();
  }
 
  if (data.value.openid == 'ooJ811XpfVpm-euqq8t7T_zRv_vM' || data.value.openid == 'ooJ811bjNwcb-e9LdISPGuQTJ6so') {
    data.value.ybzfDisabled = false
  }

  data.value.ybzfDisabled = false
  data.value.dabh = router.currentRoute.value.query.dabh;
  console.log('data.value.dabh')
  console.log(data.value.dabh)

  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 3000
  });
  getcardlist()

})



function onClickRight() {
  data.value.show = true
}
// 获取待收费信息
function getdsfxx() {
  console.log('进入待收费查询函数')

  data.value.paylist = []
  let params = {
    lx: 'mzsf_getdsfxx',
    kh: data.value.defaultCard.kh
  }
  console.log('进入待收费查询入参')
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  });
  postJson('Wechat/Service', params, 2).then(res => {
    closeToast()
    console.log('待收费查询正常返回')
    console.log(res.data)

    if (res.data.length > 0) {
      data.value.paylist = res.data

    } else {
      showNotify({
        type: 'warning',
        message: '没有待收费信息'
      })
    }
  }).catch(err => {
    showNotify({
      type: 'danger',
      message: '查询异常'
    })
  }

  )

}

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })
}
function choosecard(k) {
  data.value.defaultCard = data.value.cardlist[k]
  data.value.show = false
  setDefault(data.value.defaultCard.id)
  getdsfxx()
}




function ybpay(result) {
if (result.data.data.code === 1) {
  window.location.href = result.data.data.data.payUrl; 
}
else{
  showNotify({
        type: 'danger',
        message: result.data.data.message
      })
}
 
}



function wx_lqsyt(result) {
  console.log("拉起收银台1");
      const temp = {
        appId: result.data.appId,
        timeStamp: result.data.timeStamp,
        nonceStr: result.data.nonceStr,
        package: result.data.packageValue,
        signType: result.data.signType,
        paySign: result.data.paySign,
      }
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', temp,
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            router.replace({ path: '/PaymentResult', query: { orderid: result.data.orderid } });
            // wx.closeWindow();
          } else{
            showNotify({
              type: "danger",
              message: "未支付，或者支付失败"
            })
          }
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。     
        }
      );
}

function payOrder(waitPay,channel) {
  if (waitPay.checked.length < 1) {
    showFailToast("请选择付款项");
    return;
  }
  showLoadingToast({
    message: '提交中...',
    forbidClick: true,
    duration: 10000
  });

  let items = []
  let item = {}
  let zfje = 0
  waitPay.checked.forEach(checked_sxh => {
    item = { 'sxh': checked_sxh }
    items.push(item)
    waitPay.sfmx.forEach(sfmx => {
      if (sfmx.sxh === checked_sxh) {
        zfje = zfje + sfmx.yhhje
      }
    })
  })

  let params = {
    lx: 'createorder',
    openid: data.value.openid,
    card_no: data.value.defaultCard.kh,
    TradeType: 'JSAPI',
    order_type: 'mzsf',
    zje: zfje.toFixed(2),
    discount: 0,
    yhhje: zfje.toFixed(2),

    mzh: waitPay.mzh,
    zfje: zfje.toFixed(2),
    listsxh: items,
    invoice: 'invoice',
    channel: channel,
    sflx: 'mzsf'
  }
  console.log('收费下单入参')
  console.log(params)

  postJson('WxpayService', params, 2).then(result => {

    console.log(JSON.stringify(result.data));

    if (result.data != null) {
      if (channel == 'wxpay') {
        wx_lqsyt(result)
      }
      if (channel == 'ybpay') {
        ybpay(result)
      }
    }
    else {
      
      showNotify({
        type: 'danger',
        message: '下单失败'
      })

    }
  }).catch(err => {
    // Indicator.close();
    console.log(err);
    showFailToast('下单失败')
    unlockSerial()

  });
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 0px;
}

.footer {
  margin-top: 3em;
}

.parter {
  margin-top: 1em;
}

.lister {
  margin-top: 1em;
}

.shuoming {
  width: 90%;
  margin: 12px auto;
  font-size: 14px;
  color: #969799;
  text-align: center;
}
</style>
