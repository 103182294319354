<template>
    <van-tree-select v-model:active-id="data.activeId" v-model:main-active-index="data.activeIndex" :items="data.items"
            height="550px" @click-item="selectSymptom" />
            <div style="margin: 10px;">
            <van-button type="primary" block round @click="getid"> 确定 </van-button>
           </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

const emit = defineEmits(['getid'])
const props = defineProps([
    "activeIndex","xb"
]
)
var data = ref({
openid: '',
activeIndex: 1, //部位选择
activeId: 0 , //症状选择
brlx:1,
items:[],
department:{}
}
)


onMounted(() => {
data.value.openid = useopenidStore().data.openid
console.log('传入的activeIndex：'+props.activeIndex)
getsymptom();
}
)
function getid(){
     console.log(data.value.activeId)
    data.value.items[data.value.activeIndex].children.forEach(element => {
        if (data.value.activeId==element.id){
            console.log(element)
            data.value.department.ksbm=element.ksbm
            data.value.department.ksmc=element.ksmc;
        }
    });
    emit("getid",data.value.activeId)
}

// 按性别获取到病症信息
function getsymptom(){
    let params={
        lx:'symptom',
        brlx:props.xb
    }
    console.log('按性别获取到病症信息入参：'+JSON.stringify(params))
    postJson('WxpayService',params,2).then(res=>{
        data.value.items=res.data       
        data.value.activeIndex=props.activeIndex-1;
        if (props.activeIndex>15) {
            data.value.activeIndex= data.value.activeIndex-16
          }

        console.log('data.value.activeIndex：'+data.value.activeIndex)

    })
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>