<template>
  <div class="mainer">
    <!-- 导航栏 -->
    <van-nav-bar :title=data.ghlxmc @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        切换就诊人
        <van-icon name="exchange" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>

    <!-- 病人信息 -->
    <van-cell-group class="byxx">
      <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
        :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
      </van-cell>

      <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
        <template #title>
          <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
        </template>

      </van-cell>
    </van-cell-group>

    <van-cell-group>
      <van-cell size="large" :title="office.ksmc" is-link @click.native="xzks(office.ksbm, office.ksmc)"
        v-for="office in data.officelist" :key="office.ksbm" />
    </van-cell-group>

    <!-- <van-loading type="spinner" v-if="display"/>
  <div class="office">
    <template v-for="(vo)  in data">
      <van-cell :title="vo.ksmc" :label="vo.kswz" @click.native="xzks(vo.ksbm)" is-link></van-cell>
    </template>
  </div> -->

    <!--切换就诊人 -->
    <van-popup v-model:show="data.show" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>

    <!-- 协议确认 -->
    <van-popup v-model:show="data.showmessage" position="bottom" :style="{ height: '100%' }">

      <div style="text-align: center; margin-top: 1em;">
        <van-icon name="shield-o" size="3em" style="color: #006400;" />

      </div>
      <van-divider :style="{ color: '#006400', borderColor: '#006400', fontSize: '1em' }">挂号须知</van-divider>

      <!-- <van-image width="90%" height="1000" :src="imgUrl"  /> -->
  
      <van-image width=100%  src="./image/notice.png"  /> 
      <!-- <div v-html="data.config.notice" style="padding: 0 1em; height: 65%; overflow-y: auto;"></div> -->
   


      <div style="padding: 1em; font-size: 12px;"><van-checkbox v-model="data.checked" checked-color="#006400"
          icon-size="14px">阅读并同意<span style="color: #006400;">《挂号须知》</span></van-checkbox></div>

      <div style="padding: 1em; padding-top: 0;">
        <van-button color="#006400" block round  :disabled="data.discap" @click="confirm()">{{data.content}}</van-button>
      </div>
    </van-popup>

  </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson,postJson_async } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'
import imgUrl from '@/assets/guide/notice.png'



var data = ref({
  ghlx: 0,
  ghlxmc:'',
  openidstroe: {},
  showmessage: true,
  show: false,
  config: {
    notice: "本院门诊挂号费11元，医保减免6元（仅限眉山市参保人）。挂号成功后，请前往自助机处：点击门诊签到，并选择相应方式签到后，再前往相应诊室就诊。"
  },
  display: false,
  openid: "",
  officelist: [],
  cardlist: [],
  defaultCard: {},
  total:5,
  content:'确认',
  discap:true,
}
)





onMounted(() => {
  data.value.openidstroe = useopenidStore()
  data.value.openid = useopenidStore().data.openid;
  if (!data.value.openid) {
    showFailToast('未获取到openid');
    history.back();
  }

  
  data.value.ghlx = router.currentRoute.value.query.ghlx;
  if (data.value.ghlx==1){
    data.value.ghlxmc='选择科室(当日挂号)'
  }

  if (data.value.ghlx==2){
    data.value.ghlxmc='选择科室(预约挂号)'
  }
  
  console.log('挂号类型' + data.value.ghlx);
  data.value.openid = data.value.openidstroe.data.openid
  console.log('微信data.value.openidstroe.openid：' + data.value.openid);
  data.value.showmessage = data.value.openidstroe.data.showmessage;

if (data.value.openidstroe.data.showmessage) {

  let clock = window.setInterval(() => {
        data.value.total--
        data.value.content = data.value.total + 's后确认'
     

        if (data.value.total <= 0) {
          window.clearInterval(clock)
          data.value.content = '确认'
          data.value.total = 5
          data.value.discap = false;
        }
      }, 1000)

}

  console.log('data.value.showmessage：' + data.value.showmessage);
  if (1 == 1) {
    getofficelist();
    console.log('同步2')
    getcardlist()

  }

}
)



function confirm() {
  // 判断data.value.checked是否为true
  if (data.value.checked) {
    // 设置data.value.openidstroe.data.showmessage为false
    data.value.openidstroe.data.showmessage = false
    // 设置data.value.showmessage为false
    data.value.showmessage = false
  } else {
    // 调用showNotify函数，显示提示信息
    showNotify({ type: 'warning', message: '请阅读并同意《挂号须知》' });
  }
}

 function getofficelist() {

  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });
console.log('开始查询科室列表')
  postJson('Wechat/Service', { 'lx': 'getksxx' }, 2).then(res => {
    console.log('正常返回officelist')
    console.log(res)
    data.value.officelist = res.data
    closeToast();
  }).catch(err => {
    console.log('查询科室列表错误返回')
    console.log(err)
    showFailToast(err)
  })
}


function getcardlist() {
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        console.log('查询cardlist正常返回')
        data.value.cardlist = res.data
        bindcard();
        console.log(data.value.cardlist)
        closeToast();
      } else {
        showToast('请先绑定就诊卡');
        showFailToast(err)
      }
    }
  }).catch(err => {
    console.log('查询cardlist错误返回')
    console.log(err)
    showFailToast(err)
  })
}

function bindcard() {
  if (data.value.cardlist.length > 0) {
    data.value.cardlist.forEach(element => {
      console.log(element)
      if (element.xz) {
        data.value.defaultCard = element
        console.log(element)
      }
    });
  }
  else {
    showNotify({ type: "danger", message: "没有卡号" })
  }
}

function onClickLeft() {
  history.back();
}

function xzks(ksbm, ksmc) {
  if (data.value.cardlist.length == 0) {
    showNotify({ type: 'warning', message: '请先绑定卡后，再选择科室' });
    router.push({ path: '/Cards' })
    return
  }
  
  if  (JSON.stringify(data.value.defaultCard) == "{}") {
    showNotify({ type: 'warning', message: '请先设置默认卡，再选择科室' });
    return
  }

    console.log('传的选择的卡')
    console.log(data.value.defaultCard)
    let officeparams = {
      ksbm: ksbm,
      ksmc: ksmc,
      defaultCard: data.value.defaultCard,
      cardlist: data.value.cardlist
    }

    if (data.value.ghlx == 1) {
    router.push({ path: '/Doctor', query: { officeparams: JSON.stringify(officeparams) } })
  }else
  {
    router.push({ path: '/Doctor_yygh', query: { officeparams: JSON.stringify(officeparams) } })
  }



}

function onClickRight() {
  data.value.show = true
}

function choosecard(k) {
  data.value.defaultCard = data.value.cardlist[k]
  data.value.show = false;
  setDefault(data.value.defaultCard.id)
}

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.byxx {
  background-color: #888;
}

.office {
  width: 100%;
}
</style>