<template>
  <div class="mainer">
    <van-nav-bar title="医生排班" @click-left="onClickLeft" @click-right="showPopup">
      <template #right>
        切换科室<van-icon name="arrow-down" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>

     <div class="bodyer">

      <div style="text-align: center; margin-top: 12px; margin-bottom: 12px;">{{ data.defaultoffice.ksmc }}</div>
    
      <van-cell-group class="fenzu">  
        <van-row class="xingqi">
          <van-col span="3"></van-col>
          <van-col style="height: 32px; line-height: 32px;" span="3" v-for="(cw, index) in data.cw" :key="index" wrap="true">{{ cw.rq  }}</van-col>
        </van-row>
 
        <van-row class="liebiao">
          <van-col span="3" class="jieduan">上午</van-col>
          <van-col span="3" v-for="(ca, index) in data.cw" :key="index">
          <div style="background-color: #07c160; padding-top: 6px; padding-bottom: 6px;" v-if="ca.istoday" v-for="(xm,i) in ca.amysxm">{{ xm }}</div>
          <div style="padding-top: 6px; padding-bottom: 6px;" v-for="(xm,i) in ca.amysxm" v-else>{{ xm }}</div>
          </van-col>
        </van-row>

        <van-row class="liebiao">
          <van-col span="3" class="jieduan">下午</van-col>
          <van-col span="3" v-for="(cp, index) in data.cw" :key="index">
          <div style="background-color: #07c160; padding-top: 6px; padding-bottom: 6px;" v-if="cp.istoday" v-for="(xm,i) in cp.pmysxm">{{ xm }}</div>
          <div style="padding-top: 6px; padding-bottom: 6px;" v-for="(xm,i) in cp.pmysxm" v-else>{{ xm }}</div>
          </van-col>
        </van-row>
      </van-cell-group>
 
      <van-cell-group class="fenzu">
        <van-row class="xingqi">
          <van-col span="3"></van-col>
          <van-col style="height: 32px; line-height: 32px;" span="3" v-for="(nw, index) in data.nw" :key="index">{{ nw.rq  }}</van-col>
        </van-row>

        <van-row class="liebiao">
          <van-col span="3" class="jieduan">上午</van-col>
          <van-col span="3" v-for="(ca, index) in data.nw" :key="index">
            <div style="padding-top: 6px; padding-bottom: 6px;" v-for="(xm,i) in ca.amysxm" >{{ xm }}</div> 
          </van-col>
        </van-row>

        <van-row class="liebiao">
          <van-col span="3" class="jieduan">下午</van-col>
          <van-col span="3" v-for="(cp, index) in data.nw" :key="index">
            <div style="padding-top: 6px; padding-bottom: 6px;" v-for="(xm,i) in cp.pmysxm" >{{ xm }}</div>
          </van-col>
        </van-row> 
        
      </van-cell-group>
      
      <div style="font-size: 1em; text-align: center; margin-top: 1em;">今天({{ data.today  }})</div> 

      <van-popup v-model:show="data.show" round position="bottom" :style="{ height: '50%' }">
        <van-picker title="选择科室" :columns="data.offices" :columns-field-names="data.customFieldName" :visible-item-count="5"
          @confirm="onConfirm" @cancel="onCancel" />
      </van-popup>
      
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'


const onClickLeft = () => history.back();

var data = ref({
  today:'',
  customFieldName: {
    value: 'ksbm',
    text: 'ksmc'
    
  },
  show: false,
  offices: [],
  list: [],
  cw:[{
    rq:'',
    istoday:'',
    amysxm:[],
    pmysxm:[],
  }
  ],

  
  nw:[{
    rq:'',
    amysxm:[],
    pmysxm:[],
  }
],


defaultoffice:{
  ksbm:'',
  ksmc: "全部科室",
  }
  
 

})


onMounted(()=>{
  let myday=new Date()
data.value.today= myday.toLocaleDateString();
  getOffice()
  getpbrq()
  getysxm()
})

function getOffice() {
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  })


  let params={
    lx:'getksxx'
  }

  postJson('Wechat/Service', params, 2).then(res => {
    closeToast();
    if (res.error === 0) {

      data.value.offices = res.data
      data.value.offices.unshift({ksbm:'',ksmc:'全部科室'})    
 
    } else {
      showFailToast(res.errormessage)
    }
  })
}

// 获取排班日期
function getpbrq(){
  let params={
    lx:'pbxx_rq'
  }
  postJson('Wechat/Service',params,2).then(res=>{

    data.value.cw=res.data.slice(0,7);
    data.value.nw=res.data.slice(7,14);
    data.value.cw.forEach((w,i)=>{
      data.value.cw[i].amysxm=[]
      data.value.cw[i].pmysxm=[]
    }
    )

    data.value.nw.forEach((w,i)=>{
      data.value.nw[i].amysxm=[]
      data.value.nw[i].pmysxm=[]
    }
    )
    console.log('第一周')
    console.log(data.value.cw)
    console.log('第二周')
    console.log(data.value.nw)
   getDuty()
  
  })
}




function getysxm(){
  let amarr_ysxm=[]
  let pmarr_ysxm=[]


  // 当前周医生信息
  data.value.cw.forEach((cwitem,i)=>{
    amarr_ysxm=[]
    pmarr_ysxm=[]
    data.value.list.forEach(item=>{
       if (cwitem.rq===item.rq && item.bb===2) {      
        amarr_ysxm.push(item.xm)
       }
       if (cwitem.rq===item.rq && item.bb===3) {      
        pmarr_ysxm.push(item.xm)
       }
    }   
    )

    data.value.cw[i].amysxm=amarr_ysxm
    data.value.cw[i].pmysxm=pmarr_ysxm
  }

  )
// 下一周医生信息
  data.value.nw.forEach((nwitem,i)=>{
    amarr_ysxm=[]
    pmarr_ysxm=[]
    data.value.list.forEach(item=>{
       if (nwitem.rq===item.rq && item.bb===2) {      
        amarr_ysxm.push(item.xm)
       }
       if (nwitem.rq===item.rq && item.bb===3) {      
        pmarr_ysxm.push(item.xm)
       }
    }   
    )

    data.value.nw[i].amysxm=amarr_ysxm
    data.value.nw[i].pmysxm=pmarr_ysxm
  }

  )

}


function showPopup() {
  data.value.show = true;
}
function getDuty() {

  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  })

 let params={
  lx:'pbxx',
  ksbm:data.value.defaultoffice.ksbm,
 }

console.log('params')
console.log(params)
   postJson('Wechat/Service',params,2).then(res=>{
    if (res.error===0){
      closeToast()
       data.value.list=res.data     
       getysxm()
    }
    else{
      showFailToast(res.errormessage)
    }
   })
  }


function onCancel() {
  data.value.show = false
}

function onConfirm({ selectedValues }) {
  data.value.show = false
  let ksbm=selectedValues[0]

  data.value.defaultoffice= data.value.offices.find(
    function(item) {
    return item.ksbm === ksbm; 
})
  
  getDuty()
}




</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 0px;
}

.xingqi {
  text-align: center;
  font-size: 12px;
  background: #C8C9CC;
}

.liebiao {
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #DCDEE0;
}

.jieduan {
  font-size: 14px;
  padding-top: 6px;
}
</style>
