<template>
  <div class="page-index">
    <div class="hd">
      <div class="info">
        <div class="info-hd">付款给</div>
        <div class="info-bd">*****医院</div>
      </div>
      <div class="info-icon">
        <img class="info-icon-img" src="../../../assets/info-icon.png" alt="">
      </div>
      <div class="bg"></div>
    </div>
    <div class="bd">
      <div class="box">
        <div class="box-hd">
          <div class="box-hd-label">费用总额</div>
          <div class="box-hd-value">342.23元</div>
        </div>
        <div class="box-bd">
          <div class="box-item">
            <div class="box-bd-label">医保基金支付</div>
            <div class="box-bd-value">233元</div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">个人帐户支付</div>
            <div class="box-bd-value">233元</div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">其他抵扣金额</div>
            <div class="box-bd-value">233元</div>
          </div>
        </div>
        <div class="box-ft">
          <div class="box-ft-label">现金支付</div>
          <div class="box-ft-value">56元</div>
        </div>
        <div
          class="box-append"
          @click="handleActionsheet">
          查看明细
        </div>
      </div>
      <div class="bd-append">
        <i class="bd-append-icon"></i>
        <div class="bd-append-text">医保移动支付</div>
      </div>
    </div>
    <div class="ft">
      <div class="pay">
        <div class="pay-label">您还需支付：</div>
        <div class="pay-value">¥5823</div>
      </div>
      <div class="btn">去支付</div>
    </div>
    <div
      class="actionsheet"
      v-show="actionsheetVisible">
      <div class="actionsheet-mask" @click="handleActionsheet"></div>
      <div class="actionsheet-panel">
        <div class="actionsheet-hd">
          <div class="actionsheet-hd-tt">处方明细</div>
          <i class="actionsheet-icon-close" @click="handleActionsheet"></i>
        </div>
        <div class="actionsheet-bd">
          <div 
            class="actionsheet-box"
            v-for="item in prescriptionList"
            :key="item.title">
            <div class="actionsheet-box-hd">{{item.title}}</div>
            <div class="actionsheet-box-bd">
              <div
                class="actionsheet-box-item"
                v-for="boxItem in item.list"
                :key="boxItem.label">
                <div class="actionsheet-box-item-label-wrap">
                  <div class="actionsheet-box-item-label">{{boxItem.label}}</div>
                  <div class="actionsheet-box-item-sublabel">{{boxItem.subLabel}}</div>
                </div>
                <div 
                  class="actionsheet-box-item-value"
                  :class="[boxItem.highlight ? 'em' : '']">{{boxItem.value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIndex',
  data () {
    return {
      prescriptionList: [
        {
          title: '就诊信息',
          list: [
            { label: '门诊类别', value: '门(急)诊' },
            { label: '门诊科室', value: '普通内科' },
            { label: '医生姓名', value: '张三' },
            { label: '处方时间', value: '2021/06/08 14:54:00' },
            { label: '费用总额', value: '368.50元', highlight: true },
          ]
        },
        {
          title: '诊断信息',
          list: [
            { label: '诊断名称', value: '外伤肿胀' },
            { label: '诊断编号', value: 'E3D.25' },
          ]
        },
        // {
        //   title: '特殊信息',
        //   list: [
        //     { label: '病情名称', value: '高血压' },
        //     { label: '病情编号', value: '2220003495858' },
        //   ]
        // },
        {
          title: '费用信息',
          list: [
            { label: '万通胫骨贴*1', subLabel: '8g/片/3', value: '37.80元' },
            { label: '阿莫西林*1', subLabel: '8g/片/3', value: '7.80元' },
          ]
        },
        // {
        //   title: '其他抵扣金额',
        //   list: [
        //     { label: '住院押金抵扣', value: '50元' },
        //     { label: '医院负担金额抵扣', value: '50元' },
        //   ]
        // },
      ],
      actionsheetVisible: false
    }
  },
  methods: {
    handleActionsheet() {
      this.actionsheetVisible = !this.actionsheetVisible;
    }
  }
}
</script>

<style scoped>
@import './index.css';
@import './actionsheet.css';
</style>
