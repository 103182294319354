<template>
  <div class="mainer">
    <van-nav-bar title="满意度调查" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>
    <div class="bodyer">
      <van-notice-bar left-icon="volume-o" text="为进一步改善服务态度，提高服务质量，请对我们的服务进行评分。" />
      <van-cell-group>
        <van-cell icon="good-job-o" size="large" :title="item.title" :label="item.intro" v-for="item in data.survey"
          v-bind:key="item" @click="vote(item)">
          <template #right-icon>
            <van-icon name="arrow" />
          </template>
        </van-cell>
      </van-cell-group>
      <van-popup v-model:show="data.show" round position="bottom" closeable>
        <div class="toupiao">
          <h3>{{ title }}</h3>
          <van-rate v-model="data.score" icon="like" void-icon="like-o" :size="32" />
          <van-button :loading="loading" type="primary" block round class="tijiao" @click="examineRecord">提交评分
          </van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify, showSuccessToast } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data=ref({    
     openid:'',
      survey: [],
      show: false,
      score: 0,
      title: "",
      id: null,
      loading: false,    
  }) 
  const onClickLeft = () => history.back();
  const onClickRight = () => router.push({ path: "/" });

  onMounted(()=>{
    data.value.openid=useopenidStore().data.openid
   examine()
  })

//获取评分内容
  function examine() {
      showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        duration: 10000
      });
      let params={
        lx:'Satisfied'

      }
      postJson('WxpayService',params,2).then(res => {
        closeToast()
        console.log(res.data)
        if (res.error === 0) {

          data.value.survey = res.data

        } else {
          showToast(res.errromessage)
        }
      })
    }

    //显示
    function  vote(item) {
      data.value.show = true
      data.value.title = item.title
      data.value.id = item.id
    }

//提交评分
    function examineRecord() {
      if (!data.value.score) {
        return
      }
      data.value.show = false
      data.value.loading = true
    let params={
        lx:'SatisfiedRecord',
        openid:data.value.openid,
        id:data.value.id,
        Scrore:data.value.score
    }
    console.log(params)
      postJson('WxpayService',params,2).
      then(res => {
        data.value.loading = false
        data.value.score = 0
        if (res.error === 0) {
          showSuccessToast("感谢您的参与和支持！")
        } else {
          showFailToast(res.errormessage)
        }
      })
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 0px;
}

.toupiao {
  width: 80%;
  text-align: center;
  margin: 2em auto;
}

.tijiao {
  margin-top: 2em;
}
</style>
