<template>
      <van-cell-group inset v-if="data.department">
            <van-cell title="科室编码" size="large" :value="data.department.ksbm"> </van-cell>
            <van-cell title="科室名称" size="large" :value="data.department.ksmc"> </van-cell>
            <van-cell title="卡号" size="large" :value="data.defaultCard.kh"> </van-cell>
            <van-cell title="姓名" size="large" :value="data.defaultCard.xm"> </van-cell>
      </van-cell-group>
      <div class="button">
        <van-button type="primary" block round @click="drgh">当日挂号</van-button>

      </div>

      <div class="button">
        <van-button type="primary" block round @click="yygh">预约挂号</van-button>
      </div>
      
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

const props = defineProps([
    "department","defaultCard","cardlist"
]
)

var data = ref({
openid: '',
department:{},
defaultCard:{},
cardlist:[]
}
)


onMounted(() => {
data.value.openid = useopenidStore().data.openid
data.value.department=props.department;
console.log(data.value.department)
data.value.defaultCard=props.defaultCard;
console.log(data.value.defaultCard)
data.value.cardlist=props.cardlist
console.log(data.value.cardlist)

}
)

function drgh(){

    let officeparams = {
      ksbm: data.value.department.ksbm,
      ksmc: data.value.department.ksmc,
      defaultCard: data.value.defaultCard,
      cardlist: data.value.cardlist
    }

console.log(officeparams)

    router.push({ path: '/Doctor', query: { officeparams: JSON.stringify(officeparams) } })


}

function yygh(){
    
console.log('yygh')
    let officeparams = {
      ksbm: data.value.department.ksbm,
      ksmc: data.value.department.ksmc,
      defaultCard: data.value.defaultCard,
      cardlist: data.value.cardlist
    }

console.log(officeparams)

    router.push({ path: '/Doctor_yygh', query: { officeparams: JSON.stringify(officeparams) } })


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.button{
    margin: 10px;
}
</style>