<template>
  <van-nav-bar title="显示卡片" @click-left="onClickLeft" @click-right="onClickRight">
    <template #right>
      <van-icon name="wap-home-o" size="18" />
    </template>
    <template #left>
      <van-icon name="arrow-left" /> 返回
    </template>
  </van-nav-bar>

  <div class="show-card">
    <div class="show-card-mainer">
      <van-cell title="姓名" :value="data.name"></van-cell>
      <van-cell title="就诊卡号" :value="data.cardno"></van-cell>
      <van-cell title="身份证号" :value="data.idcard"></van-cell>
      <van-cell title="手机号码" :value="data.mobile"></van-cell>
    </div>
    <!-- <div class="qrcode"><img :src="data.qrcode" width="200" height="200" /></div> -->
    <div class="qrcode">
    <vue-qr :logoSrc="data.imageUrl" :text="data.qrcode" :size="200"  ></vue-qr>
  </div>
    <div class="show-card-footer">
      <van-button class="card-button" type="danger" :disabled="disabled"
        @click.native="delCard(data.id)">解除绑定</van-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

const onClickLeft = () => history.back();
const onClickRight = () => router.push({ path: "/" });


var data = ref({
  id: null,
  name: '',
  cardno: '',
  idcard: '',
  mobile: '',
  disabled: false,
  qrcode: '',
  imageUrl:''
})


onMounted(() => {
  console.log('进入显示卡片')
  if (useopenidStore().data.openid == '') {
    showFailToast("没有openid")
    return
  }

  let id = router.currentRoute.value.query.id;
  console.log("传入id:" + id)
  console.log("传入id类型:" + typeof(id))

  if (id=="undefined") {
    showNotify(
          {
            message: '没有默认就诊卡，请先默认就诊卡，或者自动注册就诊卡',
            type: 'danger',
          });
    router.push({ path: '/Cards' })    
  //  router.back(-1)
    return
  }

  if (id == undefined) {
    showNotify(
          {
            message: '!d=undefined',
            type: 'danger',
          });

    router.back(-1)
    return
  }



  getcard(id)
  //new URL('@/assets/tubiao/qiche.png', import.meta.url).href
 

  //data.value.qrcode = '9392C8C7BAFE77286083CB66D7FFD8250CB28FE3AE319AAF954CEB7B30DF7451:1::5100A0013'
})
//获取二维码
function geterw(kh) {

  data.value.imageUrl = new URL('./image/hyxrmyylogo.png', import.meta.url).href

    let params = {
      lx: 'geterw',
      kh: kh
    }

    postJson('Wechat/Service', params).then(res => {
      if (res != null) {
        console.log(res.data)      
        data.value.qrcode = res.data.dzjkk_erw
        console.log('二维码：'+ data.value.qrcode)  
        
        if (data.value.qrcode == null) {
          data.value.qrcode='00000'
        }
       
    
      }
    })
  }

function getcard(id) {
  let params = {
    lx: 'selectbyid',
    id: id
  }

  postJson('WxpayService', params).then(res => {
    if (res != null) {
      data.value.id = res.data.id
      data.value.name = res.data.xm
      data.value.cardno = res.data.kh
      data.value.idcard = res.data.idcard
      data.value.mobile = res.data.mobile
      data.value.qrcode = res.data.qrcode_text
      geterw(data.value.cardno)
    }
  })
}

function delCard(id) {
  data.value.disabled = true
  let params = {
    lx: 'delcardbyid',
    id: id
  }
  postJson('WxpayService', params, 2).then(res => {
    data.value.disabled = false
    if (res != null) {
      if (res.data.ret == 0) {
        router.back(-1)
      } else {
        showNotify(
          {
            message: '解绑失败',
            type: 'danger',
          });
      }
    }
  }).catch(err => {
    data.value.disabled = false
  });
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.show-card {
  width: 100%;
}

.show-card-mainer {
  width: 100%;
}

.show-card-footer {
  width: 100%;
  margin-top: 1rem;
}

.card-button {
  width: 100%;
}

.qrcode {
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}


</style>