<template>
  <div class="mainer">

    <van-cell-group>
      <van-cell size="large" :title="office.ksmc" is-link @click.native="xzks(office.ksbm, office.ksmc)"
        v-for="office in data.officelist" :key="office.ksbm" />
    </van-cell-group>

  </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson,postJson_async } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

const emit = defineEmits(['getks'])
const props = defineProps([
    "id"
]
)

var data = ref({
  officelist: [],
}
)


onMounted(() => {
  getofficelist();
  
}
)



 function getofficelist() {

  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });
console.log('开始查询科室列表')
  postJson('WxpayService', { 'lx': 'getks_wisdom','id':props.id }).then(res => {
    console.log('正常返回officelist')
    console.log(res)
    data.value.officelist = res.data
    closeToast();
  }).catch(err => {
    console.log('查询科室列表错误返回')
    console.log(err)
    showFailToast(err)
  })
}


function xzks(ksbm, ksmc) {
  emit('getks', {'ksbm':ksbm, 'ksmc':ksmc})

}




</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.byxx {
  background-color: #888;
}

.office {
  width: 100%;
}
</style>