<template>
      <div v-if="data.list.length">
            <van-checkbox-group v-model="checked" shape="square">
              <!-- 通过 CellGroup 的 inset 属性，可以将单元格转换为圆角卡片风格 -->
              <van-cell-group inset>                            
                <van-cell size="large" v-for="(item, key) in data.list" :key="key">
                  <template #title>
                    <van-checkbox :name="item.bh">{{ item.mc }}</van-checkbox>
                  </template>                 
                  <template #right-icon> ¥{{ item.je }}
                  </template>
                </van-cell>

        
               <van-cell >
                  <template #title>
                    合计金额
                  </template>                 
                  <template #right-icon><span style="color:#F56C6C">¥{{ data.je }}</span> 
                  </template>
                </van-cell>

              </van-cell-group>
            </van-checkbox-group>
            <!-- <div class="shuoming"><van-checkbox  shape="square" v-model="invoice" checked-color="#67C23A" icon-size="16px">需要纸制发票，选中即可到病员服务中心领取</van-checkbox></div> -->
            <div style="margin: 16px;">
              <van-button round block type="primary" @click="payOrder">
                确认复印种类
              </van-button>
           
            </div>
          </div>             
      <div v-else>
        <van-empty description="暂无病历复印种类" />
      </div>

</template>

<script setup>

import { onMounted, ref,watch } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify, timePickerProps } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

const emit = defineEmits(['getcopise'])
var data = ref({
openid: '',
list:[],
je:0,
checked:[]
}
)

var checked=ref([])

watch(checked,
(newValue, oldValue) => {
     console.log(newValue)
     data.value.je=0
    newValue.forEach(element => {
     console.log('element'+element)
       data.value.list.forEach(item=>{
            if (element==item.bh){
               data.value.je=data.value.je+item.je
            }
       })
    });
  },
  )


onMounted(() => {
data.value.openid = useopenidStore().data.openid
dictionary()
}
)

function dictionary(){
     let params={lx:'copiseofdictionary'}
     postJson('WxpayService',params,2).then(res=>{
          if (res.error==0){
               data.value.list=res.data
          }
     })
}

function payOrder(){

let copise={
  je:data.value.je,
  copiselist:[]
}
checked.value.forEach(element=>{
  data.value.list.forEach(item=>{
            if (element==item.bh){
               copise.copiselist.push(item)
            }
       })

})

  emit('getcopise',copise)
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>