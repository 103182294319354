<template>
  <div class="mainer">
    <!-- 导航栏 -->
    <van-nav-bar :title=data.ghlxmc @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>

      

        <div class="parter">
            <div v-if="data.yyrylist.length">
                <van-cell-group inset v-for="(info, i) in data.yyrylist" :key="k">

                    <van-cell title="卡号" size="large" :value="info.kh" />
                    <van-cell title="姓名" size="large" :value="info.byxm" />
                    <van-cell title="科室名称" size="large" :value="info.ksmc" />
                    <van-cell title="申请日期" size="large" :value="new Date(info.lrrq).format('yyyy-MM-dd')" />
                    <van-cell title="联系电话" size="large" :value="info.lxdh" />
                    <van-cell title="备注" size="large" :value="info.bz" />
                    <van-cell title="医院回复" size="large" :value="info.yyhf" />
                    <van-cell title="回复时间" size="large" :value="new Date(info.hfsj).format('yyyy-MM-dd')" />
                   
                    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
                        下一个记录
                    </van-divider>
                 
                </van-cell-group>


            </div>

            <van-empty description="暂无预约记录" v-else />
        </div>

    </div>  

</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data = ref({
    kh:'',
    yyrylist:[],
}
)




function getyyryxx() {
    let params = {
        lx: 'yyrycx',
        kh: data.value.kh
    }
    postJson('Wechat/Service', params, 2).then(res => {
        data.value.yyrylist = res.data
    })
}

onMounted(() => {
    data.value.openid = useopenidStore().data.openid
    data.value.kh=router.currentRoute.value.query.kh
    getyyryxx()
}
)



function onClickLeft() {
    history.back();
}




</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #1989fa;
}

.office {
    width: 100%;
}

.button {
    margin-top: 20px;
}
</style>