<template>
  <div class="mainer">
    <van-nav-bar :title=data.ghlxmc @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        切换就诊人
        <van-icon name="exchange" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>

    <div class="bodyer">
      <van-cell-group>
        <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
          :to="'/ShowCard?id=' + data.defaultCard.id"  v-if="data.defaultCard"></van-cell>
        <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
          <template #title>
            <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
          </template>
        </van-cell>

      </van-cell-group>
      <van-cell-group>
        <van-row class="doctor-row">
          <van-col span="8" style="text-align: center;">
            <van-image round width="90px" height="90px" :src="data.doctor.photoUrl">
              <template v-slot:error v-if="data.doctor.surname"><span class="xingshi">{{ data.doctor.surname }}</span></template>
            </van-image>
          </van-col>
          <van-col span="16">
            <div class="xm">{{ data.doctor.ysxm }} {{ data.doctor.jszw }}</div>
            <div class="lbmc">{{ data.doctor.lbmc }}</div>
            <div class="ghrq">{{ data.doctor.ghrq }}</div>
            <div class="haoyuan">{{ data.doctor.bbmc }} 
              <div><span class="zhuti">{{ data.doctor.bbxx }} </span></div>
            </div>            
          </van-col>
        </van-row>
      </van-cell-group>

      <van-tabs v-model:active="data.active" swipeable>
        <van-tab title="号源列表">
          <div class="source">
            <van-cell-group style="padding-top: 12px;">
              <van-grid :gutter="10" :column-num="3">
                <van-grid-item v-for="(item, k) in data.resource" :key="k"
                  @click="confirm(item.status, item.numSourceId, item.CurNum, item.seedate)">
                  <div v-if="item.status == 1">
                    <div class="haoma">{{ item.CurNum }}号</div>
                    <div class="shijian">{{ item.sjd }}</div>
                  </div>
                  <div v-else>
                    <div class="haoma2">{{ item.CurNum }}号</div>
                    <div class="shijian">{{ item.sjd }}</div>
                  </div>
                </van-grid-item>
              </van-grid>
            </van-cell-group>
          </div>
        </van-tab>
        <van-tab title="号源信息">
          <van-cell size="large" title="所属科室" :value="data.ksmc" />
          <van-cell size="large" title="坐诊时间" :value="data.doctor.bbmc" />
          <van-cell size="large" title="号源总数" :value="data.doctor.ghs" />
          <van-cell size="large" title="剩余号数" :value="data.doctor.syhs" />
          <van-cell size="large" title="挂号费">
            <template #right-icon>
              <div style="color: #ED6A0C;">¥{{ data.doctor.ghfy || 0 }}</div>
            </template>
          </van-cell>
          <div class="ysjj">{{ data.doctor.ysjs || "暂无医生简介" }}</div>
        </van-tab>
      </van-tabs>
    </div>

    <van-popup v-model:show="data.show" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

const onClickLeft = () => history.back();

var data = ref({
  ghlx:0,
  ghlxmc:'',
  ksbm: '',
  ksmc: '',
  doctor:null,
  openid: '',
  show: false,
  cardlist: [],
  defaultCard: null,
  active: 0,
  activeName: '1',
  doctor: {},
  resource:[]

})

Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

onMounted(() => {
  console.log('号源入参')
  console.log(router.currentRoute.value.query)
  data.value.openid = useopenidStore().data.openid
  let resourceparam =JSON.parse(router.currentRoute.value.query.resourceparam)
  data.value.ghlx=resourceparam.ghlx
  if (data.value.ghlx==1){
    data.value.ghlxmc='号源选择（当日挂号）'
  }
  if (data.value.ghlx==2){
    data.value.ghlxmc='号源选择（预约挂号）'
  }
  data.value.ksbm=resourceparam.ksbm
  data.value.ksmc = resourceparam.ksmc
  data.value.doctor=resourceparam.doctor
  console.log("医师信息")
  console.log(data.value.doctor)
  data.value.defaultCard=resourceparam.defaultCard
  if (!data.value.openid) {
    showNotify({ type: warn, message: '没有opendi' })
    return
  }
  console.log('缺省卡')
  console.log(data.value.defaultCard)

  getresource(data.value.doctor)
  getcardlist()

})


//获取号源
function getresource(doctor){

  let params = {
    lx: 'getworktime',
    // rq: doctor.ghrq.Format("yyyy-MM-dd"),
    rq:doctor.ghrq,
    ysbh:doctor.ysbh,
    ysh_lx:doctor.ysh_lx
  }
  console.log('获取号源入参')
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });

  postJson('Wechat/Service', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        console.log('获取号源出参')
        data.value.resource = res.data
        console.log(data.value.resource)
        closeToast();
      } else {
        showToast('没有号源');
        closeToast();
      }
    }
  }).catch(err => {
    console.log('获取号源出错')
    console.log(err)
    showFailToast(err)
  })

}

//订单确认
function confirm(status, numSourceId, CurNum, seedate) {
  if (status == 2) {
    showFailToast("该号源已失效")
    return;
  }
  if (status == 3) {
    showFailToast("该号源已被占")
    return;
  }
  if (status == 4) {
    showFailToast("该号源已被锁")
    return;
  }
  if (status != 1) {
    showFailToast("该号源无法用")
    return;
  }
  router.push({
    path: "/Order",
    query: {
      ghlx:data.value.ghlx,
      defaultCard:JSON.stringify(data.value.defaultCard),
      ksbm: data.value.ksbm,
      ksmc: data.value.ksmc,

      ysbh: data.value.doctor.ysbh,
      ysxm: data.value.doctor.ysxm,

      bb: data.value.doctor.bb,
      bbmc:data.value.doctor.bbmc,

      ghlb:data.value.doctor.ghlb,
      lbmc:data.value.doctor.lbmc,
      ghf:data.value.doctor.ghf,
      yhje:data.value.doctor.yhje,
      numSourceId: numSourceId,
      CurNum: CurNum,
      ghrq:data.value.ghrq,
      seedate: seedate, 
    }
  })
}

//获取卡信息
function getcardlist() {
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });
  console.log("查询cardlist")
  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        console.log('查询cardlist正常返回')
        data.value.cardlist = res.data
        console.log(data.value.cardlist)
        closeToast();
      } else {
        showToast('请先绑定就诊卡');
        showFailToast(err)
      }
    }
  }).catch(err => {
    console.log('查询cardlist错误返回')
    console.log(err)
    showFailToast(err)
  })
}
//获取医生信息
function getDoctor() {
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  });
  api.hospitalTodaySource(this.ysbh, this.bb).then(res => {
    closeToast();
    data.value.doctor = res.data
    console.log("data.value.doctor")
    console.log(data.value.doctor)
  })
}

function onClickRight() {
  data.value.show = true
}

function choosecard(k) {
      data.value.defaultCard = data.value.cardlist[k]
      data.value.show = false
      setDefault(data.value.defaultCard.id)
    }

    function setDefault(id) {
      console.log('默认值id:'+id)
      let params={
        lx:'setdefaultcard',
        id:id,
        openid:data.value.openid,
      }
       postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret==0){
         getcardlist()
        
        }
        else{

          showFailToast(res.data.reinfo);
        }
       })

      data.value.sheetVisible = false
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 0px;
}

.lbmc {
  font-size: 14px;
  color: #646566;
}
.ghrq {
  font-size: 14px;
  color: #646566;
}

.xm {
  font-size: 16px;
  font-weight: 700;
}

.doctor-row {
  padding-top: 12px;
  padding-bottom: 12px;
}

.xingshi {
  font-size: 3em;
  font-weight: 700;
}

.haoyuan {
  margin-top: 12px;
  font-size: 14px;
  color: #323233;
}

.zhuti {
  color: #EE0A24;
}

.ysjj {
  margin-top: 6px;
  background: #fff;
  padding: 1.5em 1em;
  font-size: 1em;
  color: #646566;
}

.source {
  background: #fff;
}

.haoma {
  color: #66CC00;
  font-size: 24px;
  text-align: center;
}

.haoma2 {
  color: #FF9933;
  font-size: 24px;
  text-align: center;
}

.shijian {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.feiyong {
  color: #323233;
  font-size: 12px;
}

.louceng {
  color: #000;
  font-size: 12px;
}

.footer {
  margin-top: 3em;
}

.button {
  width: 90%;
  margin: 0 auto;
  margin-top: 2em;
}
</style>
