<template>
  <van-nav-bar title="设置卡片" @click-left="onClickLeft" @click-right="onClickRight">
    <template #right>
      <van-icon name="wap-home-o" size="18" />
    </template>
    <template #left>
      <van-icon name="arrow-left" /> 返回
    </template>
  </van-nav-bar>

  <div class='card'>
    <div class='card-toper' v-if="data.count > 0">
      您还可以绑定{{ data.count }}张就诊卡
    </div>
   
  
    <div class='card-mainer'>
      <div class="card-list" v-for="(vo, key)  in data.cardlist" @click='handleClick(vo.id)'>
        <div class='card-top'>
          <div class="card-top-title">
            <div class="card-top-icon">{{ key + 1 }}</div>
            <div class="card-top-name">{{ data.hospital }}</div>
          </div>
          <!-- 默认标志 -->
          <div class="card-bizhi" v-if="vo.xz == 1">默认</div>
        </div>
        <div class='card-body'>
          <div class='card-name'>{{ vo.xm }}</div>
          <div class='card-number'>就诊卡：{{ vo.kh }}</div>
        </div>
        <div class='card-foot'><router-link :to="{ path: '/ShowCard', query: { id: vo.id } }">详情 >></router-link></div>
      </div>
    </div>
    <div class="card-footer" v-if="data.count > 0">
      <van-button type="primary" block round class="card-button" @click.native="bindCard">绑定就诊卡</van-button>
      <!-- <van-button type="primary" block round class="card-button" @click.native="createCard">新建就诊卡</van-button> -->
    </div>


    <van-popup v-model:show="data.sheetVisible" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="设为默认" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="defaultCard(card.id)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>

  </div>
</template>

<script setup>
import config from "@/config/deploy";
import { postJson } from '@/api/axiosInstance.js'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import { useopenidStore } from '@/stores'
import { ref, onMounted } from "vue";
import router from '@/router'

const onClickLeft = () => history.back();
const onClickRight = () => router.push({ path: "/" });

var data = ref({
  openidstroe: {},
  hospital: config.hospital,
  max_card: config.max_card,
  name: 'UserCard',
  cardlist: [],
  count: 5,
  default: {},
  sheetVisible: false,
  openid: ""
}

)




function getcardlist() {
  console.log("查询cardlist")
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }
  console.log(params)

  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });


  postJson('WxpayService', params, 2).then(res => {
    console.log('查询cardlist正常返回')
    data.value.cardlist = res.data
    data.value.count = data.value.max_card - res.data.length
    closeToast();
    console.log(res)

  }).catch(err => {
    console.log('查询cardlist错误返回')
    console.log(err)
    closeToast();
    showFailToast(err)
  })
}


onMounted(() => {
  console.log("进入到cards onmounted")
  data.value.openidstroe = useopenidStore();
  data.value.openid = data.value.openidstroe.data.openid
  console.log("data.value.openid:" + data.value.openid)
  if (data.value.openid == '') {
    showFailToast("没有获取到openid");
    return
  }
  console.log("进入到getcardlist")
  getcardlist();

})


function bindCard() {
  router.push({ path: '/bindCard' })
}

function createCard() {
  router.push({ path: '/createCard' })
}

function handleClick(id) {
  data.value.default = id
  data.value.sheetVisible = true
}

function defaultCard(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })

  data.value.sheetVisible = false
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.card {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
}

.card-toper {
  background: #ffffff;
  padding: 0.5rem;
  font-size: 1rem;
}

.card-mainer {
  width: 90%;
  margin: 0 auto;
}

.card-list {
  margin-top: 1rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px #cccccc;
  border-top: 8px solid #409EFF;
}

.card-top {
  background: #409EFF;
  display: flex;
  padding-bottom: 8px;
  padding-left: 0.5rem;
  justify-content: space-between;
}

.card-top-title {
  display: flex;
}

.card-top-name {
  font-size: 0.75rem;
  color: #FFFfff;
}

.card-top-icon {
  width: 1rem;
  height: 1rem;
  background: #ffffff;
  border-radius: 1rem;
  margin-right: 0.5rem;
  text-align: center;
  line-height: 1rem;
  font-size: 0.75rem
}

.card-bizhi {
  font-size: 0.75rem;
  color: #FFFfff;
  padding-right: 1rem;
}

.card-body {
  padding: 1rem;
}

.card-name {
  color: #303133;
}

.card-number {
  color: #606266;
}

.card-foot {
  text-align: right;
  font-size: 0.75rem;
  padding: 1rem;
  padding-top: 0;
}

.card-footer {
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
}

.card-button {
  width: 100%;
  margin-top: 1rem;
}
.card-tip{
  color: #f19307;
  padding: 0.5rem;
  font-size: 1rem;
}

</style>