<template>
  <div class="mainer">
    <van-nav-bar title="门诊缴费结果" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
      <!-- <template #left>
        <van-icon name="arrow-left" /> 返回
      </template> -->
    </van-nav-bar>
    <div class="bodyer">
      <van-cell-group inset>
        <van-cell size="large" title="订单编号" :value="data.result.payRecord" />
        <van-cell size="large" title="订单时间" :value="new Date(data.result.skrq).format('yyyy-MM-dd hh:mm:ss')" />

        <van-cell size="large" title="订单总金额">   
        <template #right-icon>
            <div style="color: #ED6A0C;">¥{{ data.result.zfje || 0 }}</div>
          </template>  
        </van-cell>

        <van-cell size="large" title="统筹支付"  > 
          <template #right-icon>
            <div style="color: #ED6A0C;">¥{{ data.result.tczfe || 0 }}</div>
          </template>      
      </van-cell> 

        <van-cell size="large" title="医保个账" >   
          <template #right-icon>
            <div style="color: #ED6A0C;">¥{{ data.result.sbzhzfe || 0 }}</div>
          </template>      
      </van-cell> 
         
        <van-cell size="large" title="微信金额" > 
        <template #right-icon>
            <div style="color: #ED6A0C;">¥{{ data.result.Grzhzf || 0 }}</div>
          </template>      
      </van-cell> 
        
        <van-cell size="large" title="就诊卡号" :value="data.result.tmh" />
        <van-cell size="large" title="门 诊 号" :value="data.result.mzh" />
        <van-cell size="large" title="就诊科室" :value="data.result.ksmc" />
        <van-cell size="large" title="开单医生" :value="data.result.ysxm" />
        <van-cell size="large" title="订单状态">
          <template #right-icon>
            <div style="color: #07C160;" v-if="data.status == 3 ">缴费成功</div>
            <div style="color: #576b95;" v-else-if="data.status == 1 ">缴费中，请等待系统通知</div>
            <div style="color: #ED6A0C;" v-else="data.status > 3 ">缴费失败，系统将自动退费</div>         
          </template>
        </van-cell>

        <div v-if="data.result.dzfp" >
              <div class="shouming">
                电子发票
             </div>
              <div class="qrcode">
                    <vue-qr :logoSrc="data.imageUrl" :text="data.result.dzfp" :size="200"  ></vue-qr>
             </div>
            </div>

      </van-cell-group> 

      <div class="button">
          <van-button round type="primary" @click="getjg" block >刷新结果</van-button>        
      </div>    



    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

var data=ref({
    orderid:'',
    result:{},
    status:0,
    imageUrl:''
})
function getjg(){
  let params={
  lx:'queryorder',
  orderid: router.currentRoute.value.query.orderid
 }

  postJson('WxpayService', params, 2).then(res => {
     // Indicator.close();
     console.log(res)
      if (res != null ) {                    
       data.value.result=res.data.result.data      
       data.value.status=res.data.status
       console.log(data.value)
       

      }
    }).catch(err => {
   //   Indicator.close();
    });
}

onMounted(()=>{
  data.value.orderid=router.currentRoute.value.query.orderid;
  data.value.imageUrl = new URL('./image/hyxrmyylogo.png', import.meta.url).href
 getjg()

})

function onClickLeft() {
//  history.back();
}

function onClickRight(){
  router.push({ path: "/" });
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 1em;
}

.shouming {
  text-align: center;
}
.qrcode {
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}
</style>
