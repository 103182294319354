<template>
  <div class="mainer">
    <van-nav-bar title="挂号记录" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        切换就诊人
        <van-icon name="exchange" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>


    <!-- 病人信息 -->
    <van-cell-group class="byxx">
      <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
        :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
      </van-cell>

      <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
        <template #title>
          <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
        </template>

      </van-cell>
    </van-cell-group>



    <div class="bodyer">
      <van-tabs v-model:active="activeName" swipeable>
        <van-tab title="当日挂号记录" name="a">
          <div v-if="data.todayList.length">
            <van-cell-group inset class="lister" v-for="(order, k) in data.todayList" :key="k">
              <div v-if="order.gh_out.mzh">
                <div class="shouming">
                  门诊号二维码（签到用）
                </div>
                <div class="qrcode">
                  <vue-qr :logoSrc="data.imageUrl" :text="order.gh_out.mzh" :size="200"></vue-qr>
                </div>
              </div>
              <van-cell size="large" title="诊室位置" :value="order.gh_out.zswz" />
              <van-cell size="large" title="挂号类别" :value="order.gh_out.lbmc" />
              <van-cell size="large" title="坐诊医生" :value="order.gh_out.ysxm" />
              <van-cell size="large" title="门 诊 号" :value="order.gh_out.mzh" />
              <van-cell size="large" title="挂号时间" :value="order.gh_out.ghrq" />
              <van-cell size="large" title="就诊序号" :value="order.gh_out.ysh" />
              <van-cell size="large" title="社保减免" :value="order.gh_out.tczf" />
              <van-cell size="large" title="个人支付" :value="order.gh_out.hj-order.gh_out.tczf" />
              <van-cell size="large" title="挂号费用">
                <template #right-icon>
                  <span style="color: #ED6A0C;">¥{{ order.gh_out.hj }}</span>
                </template>
              </van-cell>
              <div v-if="order.gh_out.dzfp">
                <div class="shouming">
                  电子发票
                </div>
                <div class="qrcode">
                  <vue-qr :logoSrc="data.imageUrl" :text="order.gh_out.dzfp" :size="200"></vue-qr>
                </div>
              </div>



              <van-button round type="primary" @click="getghxxbymzh(order)" block :disabled="data.buttondisabled" >申请退款</van-button>
            </van-cell-group>
          </div>
          <van-empty description="暂无当日挂号记录" v-else />

        </van-tab>

        <van-tab title="预约挂号记录" name="b">
          <div v-if="data.subList">
            <van-cell-group inset class="lister" v-for="(order, k) in data.subList" :key="k">
              <div v-if="order.gh_out.mzh">
                <div class="shouming">
                  门诊号二维码（签到用）
                </div>
                <div class="qrcode">
                  <vue-qr :logoSrc="data.imageUrl" :text="order.gh_out.mzh" :size="200"></vue-qr>
                </div>
              </div>

              <van-cell size="large" title="诊室位置" :value="order.gh_out.zswz" />
              <van-cell size="large" title="挂号类别" :value="order.gh_out.lbmc" />
              <van-cell size="large" title="坐诊医生" :value="order.gh_out.ysxm" />
              <van-cell size="large" title="门 诊 号" :value="order.gh_out.mzh" />
              <van-cell size="large" title="就诊日期" :value="order.gh_out.ghrq" />
              <van-cell size="large" title="就诊时段" :value="order.gh_out.sjd" />
              <van-cell size="large" title="社保减免" :value="order.gh_out.tczf" />
              <van-cell size="large" title="个人支付" :value="order.gh_out.hj-order.gh_out.tczf" />
              <van-cell size="large" title="挂号费用" v-if="order.gh_out.hj">

                <template #right-icon>
                  <span style="color: #ED6A0C;">¥{{ order.gh_out.hj }}</span>
                </template>
              </van-cell>
              <div v-if="order.gh_out.dzfp">
                <div class="shouming">
                  电子发票
                </div>
                <div class="qrcode">
                  <vue-qr :logoSrc="data.imageUrl" :text="order.gh_out.dzfp" :size="200"></vue-qr>
                </div>
              </div>
              <van-button round type="primary" @click="getghxxbymzh(order)" block :disabled="data.buttondisabled">申请退款</van-button>
            </van-cell-group>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>

  <!--切换就诊人 -->
  <van-popup v-model:show="data.show" round closeable position="bottom">
    <div class="footer">
      <van-cell-group>
        <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
          v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
      </van-cell-group>
    </div>
  </van-popup>
</template>

<script setup>
import { postJson } from '@/api/AxiosINstance'
import { showToast, Button, showNotify, showFailToast, showLoadingToast, closeToast } from 'vant';
import { onMounted, ref, watch } from 'vue'
import router from '@/router'
import 'vant/es/Button/style';
import { useopenidStore } from '@/stores'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

var data = ref({
  openid: '',
  show: false,
  buttondisabled:false,
  activeName: 'a',
  todayList: [],
  subList: [],
  cardlist: [],
  defaultCard: {},
  imageUrl: ''
})

var bindcard_checked = ref(false)

function choosecard(k) {
  data.value.defaultCard = data.value.cardlist[k]
  data.value.show = false;
  setDefault(data.value.defaultCard.id)
  data.value.todayList = [],
    data.value.subList = []
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  });
  getTodayRecords()
  getyyghRecords()

}

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })

}

const onClickLeft = () => history.back();
function onClickRight() {
  data.value.show = true
}

function getcardlist() {
  console.log('进入getcardlist')
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        data.value.cardlist = res.data
        bindcard_checked.value = true
        // bindcard()

      } else {
      }
    }
  }).catch(err => {
    console.log(err)
    showFailToast(err)
  })
}

async function getcardlist_async() {
  console.log('进入getcardlist')
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }

}

function bindcard() {
  console.log('进入绑卡')
  if (data.value.cardlist.length > 0) {
    data.value.cardlist.forEach(element => {
      console.log(element)
      if (element.xz) {
        data.value.defaultCard = element
        //
        getRecords('drgh')
        getRecords('yygh')
      }

    });
  }
  else {
    showNotify({ type: "danger", message: "没有卡号" })
  }
}

function getRecords(order_type) {

  let params = {
    lx: 'selectorderlist',
    kh: data.value.defaultCard.kh,
    order_type: order_type
  }

  postJson('WxpayService', params, 2).then(res => {
    if (order_type == 'drgh') {
      closeToast()
    }
    if (res.error == 0) {
      let list = res.data
      list.forEach(function (order, i) {
        list[i].gh_in = JSON.parse(order.hisinputjosn)
        list[i].gh_out = JSON.parse(order.hisoutputjosn).data

      }
      )

      if (order_type == 'drgh') {
        data.value.todayList = list;
        console.log('当日挂号记录')
        console.log(data.value.todayList)
      } else
        if (order_type == 'yygh') {
          data.value.subList = list;
          console.log('预约挂号记录')
          console.log(data.value.subList)
        }

    } else {
      console.log('挂号记录res.error<>0')
      showFailToast('res.error<>0')
    }
  }).catch(err => {
    console.log('当日挂号记录err')
    console.log(err)
    showFailToast(err)
    closeToast()

  })
}

onMounted(() => {
  // data.value.openid='oWvrv0i816ls5U0n2c4cU8VvQmrY'

  data.value.openid = useopenidStore().data.openid
  data.value.type = router.currentRoute.value.query.type
  data.value.imageUrl = new URL('./image/hyxrmyylogo.png', import.meta.url).href
  if (data.value.type) {
    data.value.activeName = data.value.type
  }
  showLoadingToast({
    message: '加载中1...',
    forbidClick: true,
    duration: 10000
  });
  getcardlist()

})

function Refund(order) {
  let params = {
    lx: 'refund',
    out_trade_no: order.out_trade_no
  }
  console.log('退单入参')
  console.log(params)
  postJson('WxpayService', params, 2).then(res => {
    router.replace({ path: '/todayResult', query: { orderid: order.out_trade_no, ghlx: 3 } });
  }).catch(err => {
    console.log('退单异常:' + err)
  })
}


function getghxxbymzh(order) {
data.value.buttondisabled=true;
  let params = {
    lx: 'getghxxbymzh',
    mzh: order.gh_out.mzh
  }
  console.log('按门诊号获取挂号信息入参')
  console.log(params)

  postJson('Wechat/Service', params, 2).then(res => {
    console.log('按门诊号获取挂号信息出参')
    console.log(res)
    if (res.data.yfsfy) {
      showToast('已经就诊，不能申请退款')
    } else {
      //  showToast('还未就诊，可以退费')
      Refund(order)
    }

  }).catch(err => {

  })
}

watch(bindcard_checked, (newdata, olddata) => {
  if (newdata) {
    console.log('bindcard_checked', newdata, olddata)
    bindcard()
  }

})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 0px;
}

.lister {
  margin-top: 12px;
}

.qrcode {
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.shouming {
  text-align: center;
}
</style>
