/**
 * 配置编译环境和线上环境之间的切换
 */

var baseUrl = ''
var key = ''
var iv = ''

if (process.env.NODE_ENV === 'development') {
    baseUrl = 'http://127.0.0.1:9555'
    key = '700040D41F47592C'
    iv = '7AF3C134BEAA99E5'
} else if (process.env.NODE_ENV === 'production') {
    baseUrl = 'http://wx.xdbxyy.com'
    key = 'A89226D089EE57FA'
    iv = '57EF7B0E6E582178'
}

export {
    baseUrl,
    key,
    iv,
}