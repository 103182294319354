<template>
  <div class="mainer">
    <!-- 导航 -->
    <van-nav-bar title="选择医生(预约)" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        切换就诊人
        <van-icon name="exchange" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>
<!-- 缺省卡显示 -->
    <div class="bodyer">
        <van-cell-group >
      <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
        :to="'/ShowCard?id=' + data.defaultCard.id"  v-if="data.defaultCard">
      </van-cell>
      <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
        <template #title>
          <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
        </template>
      </van-cell>
    </van-cell-group>



    </div>

    <van-tabs v-model:active="active" swipeable>
    

      <van-tab :title="list.title" v-for="(list, k, i) in data.lists" :key="i">

        <van-cell-group v-if="list.doctors.length">
            <van-row v-for="doctor in list.doctors" :key="doctor" class="doctor-row" @click="choosedoctor(doctor)">
              <van-col span="8" style="text-align: center;">
                <van-image round width="90px" height="90px" :src="doctor.photoUrl">
                  <template v-slot:error v-if="doctor.surname"><span class="xingshi">{{ doctor.surname}}</span>
                  </template>
                </van-image>
              </van-col>
              <van-col span="14">
                <div class="xm">{{ doctor.ysxm }}</div>
                <div class="lbmc">{{ doctor.lbmc }}</div>
                <div class="bbmc">{{ doctor.bbmc }}</div>
                <div class="bbxx">{{ doctor.bbxx }}</div>
                <div class="ghs">余号：{{ doctor.syhs}} / {{ doctor.ghs }}</div>
                <div class="ghfy">挂号费：¥{{ doctor.ghf || 0 }}</div>
              </van-col>
              <van-col span="2">
                <van-icon name="arrow" />
              </van-col>
              <van-col span="24" style="margin-top: 12px;">
                <div class="van-hairline--bottom"></div>
              </van-col>
            </van-row>
          </van-cell-group>
          <van-empty description="没有坐诊医生" v-else />

      </van-tab>

     <!-- <van-tab v-for="index in 4" :title="'选项 ' + index">
     内容 {{ index }}  
  </van-tab>  -->

    </van-tabs>



<!-- 切换病人 -->
    <van-popup v-model:show="data.show" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref ,reactive} from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'



var data=ref({
      show:false,
      openid:"",
      ksbm:'',
      ksmc:'',
      defaultCard:{},
      cardlist:[],
       lists:[],
      active: 0,
      activeName: '1',
 
})
//var lists=reactive([])



onMounted(()=>{
  console.log("进入Doctor_yygh.onmounted")
    data.value.openid=useopenidStore().data.openid;
    if (data.value.openid){      
      let officeparams=JSON.parse(router.currentRoute.value.query.officeparams);
      data.value.ksbm = officeparams.ksbm
      data.value.ksmc = officeparams.ksmc
      data.value.defaultCard=officeparams.defaultCard
      data.value.cardlist=officeparams.cardlist;
      console.log("接受到的卡")
      console.log(data.value.defaultCard)

      get_cardlist()
      get_hylist(data.value.ksbm)
} 
  else {
  showToast('系统异常');
} 
})
     
const onClickLeft = () => history.back();
function onClickRight() {
      data.value.show = true
    }

function choosecard(k) {
      data.value.defaultCard = data.value.cardlist[k]
      data.value.show = false;

      setDefault(data.value.defaultCard.id)
   
    }

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
     get_cardlist()
    }
    else {
      showFailToast(res.data.reinfo);
    }
  })
} 
    // 后台使用存储过程 wxgzh_getyyhy_date，wxgzh_yygh_gethy 
function get_hylist(ksbm){
let params={
  lx:'getyyghhy2',
  ksbm:ksbm
 }
  console.log("预约挂号号源入参"+params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });

  postJson('Wechat/Service', params, 2).then(res => {
    console.log(res)
      if (res != null ) {
        if (res.data.length) {
          console.log('预约挂号号源')
          console.log(res.data) 
           data.value.lists = res.data   
         
           console.log('预约挂号号源1')
          console.log(data.value.lists)        

          

          closeToast();
        } 
      }
    }).catch(err => {
      console.log('查询预约挂号号源错误返回')
      showFailToast(err)
    })
  }

function get_cardlist(){
let params={
  lx:'getcardbyopenid',
  openid:data.value.openid
 }
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
      if (res != null ) {
        if (res.data.length) {
          console.log('查询cardlist正常返回')
          data.value.cardlist = res.data   
          console.log(data.value.cardlist)           
          closeToast();
        } else {
          showToast('请先绑定就诊卡');
          showFailToast(err)
        }
      }
    }).catch(err => {
      console.log('查询cardlist错误返回')
      console.log(err)
      showFailToast(err)
    })
  }

  function choosedoctor(doctor) {
  let resourceparam={
        ghlx:2,
        ksbm: data.value.ksbm,
        ksmc: data.value.ksmc,
        doctor:doctor,
        defaultCard:data.value.defaultCard
  }

  if (doctor.syhs > 0) {
    router.push({
      path: '/Resource', query: {
            resourceparam:JSON.stringify(resourceparam)
               }
    })
  } else {
    showToast('已无余号');
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 0px;
}

.lbmc {
  font-size: 14px;
  color: #646566;
}
.bbmc {
  font-size: 18px;
  color: red;
}

.bbxx {
  font-size: 14px;
  color: #646566;
}

.xm {
  font-size: 18px;
  font-weight: 700;
}

.doctor-row {
  padding-top: 12px;
  padding-bottom: 12px;
}

.xingshi {
  font-size: 3em;
  font-weight: 700;
}

.haoyuan {
  margin-top: 12px;
  font-size: 14px;
  color: #323233;
}

.zhuti {
  color: #EE0A24;
}

.ysjj {
  margin-top: 6px;
  background: #fff;
  padding: 1.5em 1em;
  font-size: 1em;
  color: #646566;
}

.source {
  background: #fff;
}

.haoma {
  color: #66CC00;
  font-size: 24px;
  text-align: center;
}

.haoma2 {
  color: #FF9933;
  font-size: 24px;
  text-align: center;
}

.shijian {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.feiyong {
  color: #323233;
  font-size: 18px;
}

.louceng {
  color: #000;
  font-size: 14px;
}

.footer {
  margin-top: 3em;
}</style>
