<template>
  <van-nav-bar title="绑定卡片" @click-left="onClickLeft" @click-right="onClickRight">
    <template #right>
      <van-icon name="wap-home-o" size="18" />
    </template>
    <template #left>
      <van-icon name="arrow-left" /> 返回
    </template>
  </van-nav-bar>


  <div class="bind">
    <div class="bind-top">请填写身份信息</div>
    <div class="bind-form">
      <van-field label="姓名：" placeholder="请输入姓名" v-model="data.name" :state="data.nameState"></van-field>
      <!-- <van-field label="就诊卡号：" placeholder="请输入卡号" v-model="data.cardno" type="number":state="data.cardState"></van-field> -->
      <van-field label="身份证号：" placeholder="请输入本人或监护人证件号" v-model="data.idcard" :state="data.idState"></van-field>
      <van-field label="手机号：" placeholder="请输入手机号" type="tel" v-model="data.mobile" :state="data.phoneState"></van-field>
      <van-field label="验证码：" placeholder="请输入验证码" type="number" v-model="data.captcha" :state="data.capState">
        <template #button>
          <van-button type="danger" size="small" :disabled="data.discap" @click.native="sending">{{ data.content
          }}</van-button>
        </template>
      </van-field>
    </div>

    <div class="bind-protocol">
      <div class="bind-pro-check">
        <input type="checkbox" v-model="data.agree">
      </div>
      <div class="bind-pro-txt">已阅读并同意<router-link to="/Protocol">《用户绑卡协议》 </router-link></div>
    </div>

    <div class="bind-footer">
      <van-button type="primary" class="bind-button" :disabled="data.disabled" @click.native="bindCard">绑定就诊卡</van-button>
    </div>
    <div class="tips">
      为了您和家人的合法权益不被侵害，如果没有预留手机号，需往前台找工作人员设置方可绑定就诊卡！
    </div>
  </div>
</template>

<script setup>

import config from "@/config/deploy";
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

var data = ref({
  name: '',
  cardno: '',
  idcard: '',
  mobile: '',
  captcha: '', //验证码
  content: '发送验证码',
  agree: false,

  nameState: '',
  cardState: '',
  idState: '',
  phoneState: '',
  capState: '',
  disabled: false,
  //验证码发送按钮显示
  discap: false,
  total: 60,
  openidstroe: {}

})

const onClickLeft = () => history.back();
const onClickRight = () => router.push({ path: "/" });


onMounted(() => {
  data.value.openidstroe = useopenidStore();
  if (!data.value.openidstroe.data.openid) {
    showToast('系统异常');
    router.back();
  }

})

function sending() {
  console.log('sending')
  data.value.cardno = data.value.idcard
  if (!data.value.name || !data.value.cardno || !data.value.mobile || !data.value.idcard) {
    showToast({
      message: '姓名、卡号和手机都不能为空',
      position: 'top'
    });
    return;
  }

  if (data.value.mobile.length != 11) {
    data.value.phoneState = 'error'
    showToast({
      message: '电话号码不等于11位',
      position: 'top'
    });
    return;
  }

  if (data.value.idcard.length != 18) {
    data.value.phoneState = 'error'
    showToast({
      message: '身份证号码不等于18位',
      position: 'top'
    });
    return;
  }

  data.value.discap = true
  const params = {
    lx: "getverificationcode",
    name: data.value.name,
    cardno: data.value.cardno,
    mobile: data.value.mobile,
    idcard: data.value.idcard,
  }

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res.data.ret != 0) {
      showToast({
        message: res.data.reinfo + '发送失败请重新发送！',
        position: 'top'
      });
      data.value.discap = false
      //发送失败，马上可以重新发送

    } else {

      showToast({
        message: res.data.reinfo,
        position: 'top'
      });

      //发送成功，没有收到信息 60秒后可以重新发送
      let clock = window.setInterval(() => {
        data.value.total--
        data.value.content = data.value.total + 's后重新发送'
        if (data.value.total <= 0) {
          window.clearInterval(clock)
          data.value.content = '发送验证码'
          data.value.total = 60
          data.value.discap = false
        }
      }, 1000)

    }
  })
}

function bindCard() {
  if (!data.value.agree) {
    showToast({
      message: '请阅读并同意绑卡协议',
      position: 'top'
    });
    return;
  }

  if (data.value.name == '') {
    data.value.nameState = 'error'
    showToast({
      message: '姓名不能为空',
      position: 'top'
    });
    return;
  } else {
    data.value.nameState = 'success'
  }

  // if (data.value.cardno == '') {
  //   data.value.cardState = 'error'
  //   showToast({
  //     message: '卡号不能为空',
  //     position: 'top'
  //   });
  //   return;
  // } 

  if (data.value.cardno.length < 8) {
    data.value.cardState = 'error'
    showToast({
      message: '卡号不足八位请补足八位，前面加0',
      position: 'top'
    });
    return;
  } else {
    data.value.cardState = 'success'
  }

  if (data.value.idcard == '') {
    data.value.idState = 'error'
    showToast({
      message: '身份证号不能为空',
      position: 'top'
    });
    return;
  } else {
    data.value.cardno = data.value.idcard
    data.value.idState = 'success'
  }


  if (data.value.mobile.length != 11) {
    data.value.phoneState = 'error'
    showToast({
      message: '电话号码不等于11位',
      position: 'top'
    });
    return;
  }

  if (data.value.mobile == '') {
    data.value.phoneState = 'error'
    showToast({
      message: '电话号码不能为空',
      position: 'top'
    });
    return;
  } else {
    data.value.phoneState = 'success'
  }

  if (data.value.captcha == '') {
    data.value.capState = 'error'
    showToast({
      message: '验证码不能为空',
      position: 'top'
    });
    return;
  } else {
    data.value.capState = 'success'
  }

  //绑卡按钮不显示
  data.value.disabled = true

  const params = {
    lx: 'bingcard',
    name: data.value.name,
    cardno: data.value.cardno,
    idcard: data.value.idcard,
    mobile: data.value.mobile,
    code: data.value.captcha,
    openid: data.value.openidstroe.data.openid
  }
  console.log('绑卡入参')
  console.log(params)
  postJson('WxpayService', params, 2).then(res => {
    console.debug('绑卡出参：')
    console.debug(res)
    //返回成功后绑卡按钮显示
    data.value.disabled = false

    if (res != null) {
      if (res.data.ret == 0) {
        showToast({
          message: '卡号绑定成功',
          position: 'top',
        });
        router.back(-1)
      } else if (res.data.ret == -2) {
        showToast({
          message: res.data.reinfo,
          position: 'top',
          duration: 8000
        });
      }
      else if (res.data.ret == -1) {
        showToast({
          message: '没有对应卡号，请新建就诊卡',
          position: 'top',
          duration: 3000
        });

        router.push({
          path: '/createCard', query: {
            name: data.value.name,
            cardno: data.value.cardno,
            idcard: data.value.idcard,
            mobile: data.value.mobile,
            code: data.value.captcha,
            openid: data.value.openidstroe.data.openid
          }
        })
      }
    }
  }).catch(error => {
    data.value.disabled = false

    data.value.name = ''
    data.value.cardno = ''
    data.value.idcard = ''
    data.value.mobile = ''
    data.value.captcha = ''
    data.value.nameState = ''
    data.value.cardState = ''
    data.value.idState = ''
    data.value.phoneState = ''
    data.value.capState = ''
  });
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bind {
  width: 100%;
}

.bind-top {
  padding: 0.75rem;
  font-size: 0.75rem;
  color: #909399;
}

.bind-form {
  width: 100%;
}

.bind-protocol {
  padding: 0.75rem;
  display: flex;
}

.bind-pro-check {
  margin-right: 6px;
}

.bind-pro-txt {
  font-size: 0.75rem;
  color: #303133;
  margin-top: 2px;
}

.bind-footer {
  padding: 1rem 0.75rem;
}

.bind-button {
  width: 100%;
}

.tips {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  color: #E6A23C;
}
</style>