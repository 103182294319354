<script setup>
import { RouterLink, RouterView } from 'vue-router'
import swipe from '@/components/swipe.vue'
import { ref, onMounted } from 'vue'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import { useopenidStore } from '@/stores'
import { getUrlParam, postJson } from '@/api/axiosInstance'
import router from '@/router'
import wx from 'weixin-js-sdk';
import { looseEqual } from '@vue/shared';

var data = ref({
  leftimage: new URL('@/Assets/logo.png', import.meta.url).href,
  msgCount: 0,
  openidstroe: {},
  wx_code: '',
  dabh: '',
  callbacklx:'',//回调类型 通知收费 tzsf, 医保回调 yb_gh_callback ,yb_mzsf_callback
  orderid: '',//回调订单号
  state: '',
  openid: '',

})
function daohang() {
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  });

  let params = {
    lx: 'wxconfig',
    url: window.location.href
  }

  postJson('WxpayService', params, 2).then(res => {
    closeToast()
    if (res.error === 0) {
      res.data.jsApiList = ['getLocation', 'openLocation']
      // res.data.debug=true
      console.log(res.data)
      let wxconfig = {}
      //  wxconfig.debug=true
      wxconfig.appId = res.data.appId
      wxconfig.timestamp = res.data.timestamp
      wxconfig.nonceStr = res.data.nonceStr
      wxconfig.signature = res.data.signature
      wxconfig.jsApiList = ['getLocation']
      wx.config(wxconfig);


      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        wx.getLocation({
          type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: function (res) {
            var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。


            // var speed = res.speed; // 速度，以米/每秒计
            // var accuracy = res.accuracy; // 位置精度 
            window.location.href = 'https://uri.amap.com/navigation?from=' + longitude + ',' + latitude + ',&to=103.37974,29.901463,洪雅县人民医院&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0'
            //  window.location.href ='https://uri.amap.com/navigation?from=116.478346,39.997361,&to=103.38,29.90,洪雅县人民医院&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0'
          },
          fail: function () {
            showFailToast('地理位置获取失败');
          }
        });
      });// config成功调用



    }

    else {
      showFailToast(res.message);
    }
  })
}
function getopid() {
  data.value.wx_code = getUrlParam("code")
  data.value.openidstroe = useopenidStore();
  if (!data.value.wx_code) {
    console.log('没有微信code')
  }
  else {
    console.log('微信code1:' + data.value.wx_code)

    showLoadingToast({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 100000
    });

    postJson('WxpayService', { lx: 'WxUserInfo', 'code': data.value.wx_code }, 2).then(res => {
      console.log("微信用户信息：" + JSON.stringify(res.data));
      data.value.openidstroe.data.openid = res.data.openid;
      closeToast();


      data.value.state = getUrlParam('state')
  if (data.value.state) {
    data.value.dabh = getUrlParam('state')
  }   

  if (data.value.state) {
         router.push('/payment?dabh=' + data.value.dabh)
      } 

    }).catch(err => {
      showToast('获取微信openid失败');
      console.log("获取微信openid失败" + JSON.stringify(res.data));
    });

  }
}

onMounted(() => {

 console.log(__App.VITE_APP_DEV);
  //回调类型 通知收费 tzsf, 医保回调 yb_gh_callback ,yb_mzsf_callback
  data.value.callbacklx = getUrlParam('callbacklx')
  data.value.orderid = getUrlParam('orderid')
  data.value.openid = getUrlParam('openid')
  console.log('回调类型:' +  data.value.callbacklx);
  console.log('回调订单号:' +  data.value.orderid);
  console.log('回调openid:' +  data.value.openid);

  data.value.openidstroe = useopenidStore()

  if (data.value.openid){
    data.value.openidstroe.data.openid = data.value.openid
  }


  if (data.value.openidstroe.data.openid==false) {
    console.log('获取获取open'+data.value.openidstroe.data.openid);
    getopid()
  }
//通知待收费消息需要 到getopid中获取 因为异步
  if (data.value.callbacklx=='drgh'){
        data.value.ghlx = getUrlParam('ghlx')
        console.log('挂号类型：' +  data.value.ghlx); 
        router.replace({ path: '/todayResult', query: { orderid: data.value.orderid, ghlx: 1 } });
      }

if (data.value.callbacklx=='yygh'){
        data.value.ghlx = getUrlParam('ghlx')
        console.log('挂号类型：' +  data.value.ghlx); 
        router.replace({ path: '/todayResult', query: { orderid: data.value.orderid, ghlx: 2 } });
      }  

    if (data.value.callbacklx=='mzsf'){
        data.value.ghlx = getUrlParam('ghlx')
        console.log('档案编号' +  data.value.ghlx); 
        router.replace({ path: '/PaymentResult', query: { orderid: data.value.orderid } });

      }  
})


function getcode() {

  let params={
    action: 'GetPayAuthNo',
    openid: data.value.openidstroe.data.openid
  }
  console.log("获取移动支付autocode参数：" + JSON.stringify(params));

  postJson('YdzfService', params, 2).then(res => {

    console.log(res.data);
    window.location.href = res.data.url;
    
   
  }).catch(err => {
    showToast(err);
    console.log(err);
    console.log('进入异常')
  });

}


function tz(){
  window.location.href ="http://wxgzh.mshyxrmyy.com?callbacklx=mzsf&openid=ooJ811XpfVpm-euqq8t7T_zRv_vM&orderid=33dd4fb76ace4dfdb67365580ddc51d7&ghlx=mzsf&channel=ybpay";

}

function getAuthcode() {

  let params={
    action: 'GetPayAuthNo',
    openid: data.value.openidstroe.data.openid
  }
  console.log("获取移动支付autocode参数：" + JSON.stringify(params));

postJson('YdzfService', params, 2).then(res => {

  console.log(res.data);


    window.location.href = res.data.url;
  // }
  // else{
  //   showFailToast(res.data.url);
  // }
 
}).catch(err => {
  showToast(err);
  console.log(err);
  console.log('进入异常')
});

}

function isEmptyStr(s) {
	if (s == undefined || s == null || s == '') {
		return true
	}
	return false
}



function test() {

  router.replace({ path: 'ZyjkResult', query: { orderid: 'ec33051cc7a84a33ad7f2a2a6a7407c0' } });
}

function waitefor() {
  showFailToast('正在建设中，请稍后...');
}

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })
}
const qicheUrl = new URL('@/assets/tubiao/qiche.png', import.meta.url).href

</script>

<template>
  <van-nav-bar  title="洪雅县人民医院">
    <template #left>
      <van-image width="32px" height="32px" :src=data.leftimage />
    </template>
    <template #right>
      <van-icon name="chat-o" :badge="data.msgCount" size="24" v-if="data.msgCount" />
      <van-icon name="chat-o" size="24" v-else />
    </template>
  </van-nav-bar>

  <!-- 2轮播图 -->
  <!-- <div>
    <swipe />
  </div> -->



  <!-- 导航grid -->
  <!-- 门诊部分 -->
  <div>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      门诊服务
    </van-divider>
    <van-grid :column-num="4">
      <van-grid-item icon="./image/drgh.png" text="当日挂号" to="/Office?ghlx=1" />
      <van-grid-item icon="./image/yygh.png" text="预约挂号" to="/Office?ghlx=2" />
      <van-grid-item icon="./image/mzjf.png" text="门诊缴费" to="/payment" />
      <van-grid-item icon="./image/ghjl.png" text="挂号记录" to="/RegRecord" />
      <van-grid-item icon="./image/jfjl.png" text="缴费记录" to="/Paymentlist" />
      <van-grid-item icon="./image/jcjy.png" text="检查检验报告" to="/Report" />
      <van-grid-item icon="./image/hzcx.png" text="候诊查询" to="/WaitInfo" />
      <van-grid-item icon="./image/yspb.png" text="医生排班" to="/Scheduling" />
      <van-grid-item icon="./image/yspb.png" text="我的卡片" to="/Cards" />
      <van-grid-item icon="./image/hushi.png" text="智能导诊" to="/Wisdom" />
      <van-grid-item icon="./image/nvnisheng.png" text="预约检查检验" to="/jcjy_yydj" />

    </van-grid>
  </div>

  <!-- 住院部分 -->
  <div>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      住院服务
    </van-divider>
    <van-grid :column-num="4">
      <van-grid-item icon="./image/zycx.png" text="住院预交款" to="/HospitalInfo" />
      <van-grid-item icon="./image/bingli.png" text="病历复印" to="/CopiseofCase" />
      <van-grid-item icon="./image/sscx.png" text="手术查询" to="/Ssxx" />
      <van-grid-item icon="./image/zycx.png" text="预约住院" to="/Yyry" />
    </van-grid>
  </div>
  <!-- 其他部分 -->
  <div>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      常用服务
    </van-divider>
    <van-grid :column-num="4">

      <van-grid-item icon="./image/yiyuan.png" text="医院简介" url="https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA" />
      <van-grid-item icon="./image/bingfang.png" text="科室介绍" url="https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA" />
      <van-grid-item icon="./image/nanyisheng.png" text="医生简介" url="https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA" />
      <van-grid-item icon="./image/xintiao.png" text="健康宣教" url="https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA" />
      <van-grid-item icon="./image/jiankang.png" text="楼层分布" url="https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA" />
      <van-grid-item icon="./image/yhzn.png" text="用户指南" url="https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA" />

      <van-grid-item icon="./image/dianhua1.png" text="联系我们" to="/Contact" />
      <van-grid-item icon="./image/qiche.png" text="交通导航" @click="daohang" />
      <van-grid-item icon="./image/manyidu.png" text="满意度调查" to="/Satisfied" />
      <van-grid-item icon="./image/fankui.png" text="投诉建议" to="/Feedback" />
      <van-grid-item icon="./image/zycx.png" text="移动支付测试6" @click="getAuthcode" />
      <van-grid-item icon="./image/zycx.png" text="跳转测试1" @click="tz" />
    </van-grid>
  </div>


  <!-- <van-image width=100% height="200" src="./image/jiayou.png" margin-top:200px /> -->

  <div class="shuoming">
    网站备案号：蜀ICP备17028655号-1<br />
    ©2024-2024 洪雅县人民医院 版权所有<br />
  </div>
</template>



<style scoped lang="less">
.bar {
 background-color: #1989fa;
 font-size:10px;
}

.shuoming {
  width: 90%;
  margin: 12px auto;
  font-size: 14px;
  color: #969799;
  text-align: center;
}
</style>
