<template>
  <div class="protocol">
    <div class="pro-title">洪雅人民医院绑卡协议</div>
    <div class="pro-footer">
      <van-button type="primary" class="pro-button" @click.native="backPage">确定</van-button>
    </div>
    <div class="pro-content">
      4.1 你的微信号在登录状态下发出的支付指令均视为你本人发出的支付指令。你理解并同意，因资金划转的及时性，支付指令一旦发出即立即生效、不可撤销，本公司有权根据你发出的支付指令进行资金扣划和归集操作。你应妥善保管你的手机等电子设备、支付密码、短信校验码、数字证书、电子签名、以及用于微信支付的条码、二维码等信息和资料，因你自身泄露、遗失、复制、转交前述信息和资料而导致的损失，由你自行承担。

      4.2 为了给你带来更好的支付体验，你理解并同意，对于一定额度、一定次数内的支付交易（具体以相关使用规则为准），本公司无需验证你的微信支付密码即可根据你或商户或收款人的交易指令对你的“零钱”和关联银行账户进行资金扣划和归集操作（如“向商户付款”和“免密支付”功能）。

      4.3 你自愿向本公司合作银行（具体以你在操作界面确认的银行为准，下称“开户银行”）申请开通银行账户，并通过该银行账户完成支付交易。你同意并授权：1）本公司将你申请开通银行账户所需的个人信息（包括但不限于姓名、身份证号码、手机号码、银行卡信息等）传输至开户银行；2）本公司可将交易款项从你的零钱余额划转至你指定的用于支付的银行账户；3）本公司可将交易款项从你指定的用于支付的银行账户进行划扣，以完成交易。你应按照你与开户银行的约定使用银行账户，如出现下列情况之一，本公司有权立即中止该项服务：

      （1） 你将本服务用于非法目的；

      （2） 你违反法律法规、协议约定或账户使用规则；

      （3） 本公司认为向你提供本服务存在风险的；

      （4） 你的银行账户无效、有效期届满或注销等情况；

      （5） 银行的系统故障、系统升级。

      4.4 若你开通了微信指纹支付功能，即视为你同意本公司在你使用微信指纹支付时，无需校验你的支付密码和短信动态码，依据你的手机等移动终端设备发出的指纹校验信息即可按照你或商户或收款人的交易指令对你的“零钱”和关联银行账户进行资金扣划和归集操作。

      4.5 你理解并同意，本公司并非银行或金融机构，无法提供资金即时到账服务，你认可资金于途中流转需要合理时间。

      4.6 你使用本公司服务期间，本公司无须对为你保管、代收或代付款项的货币贬值、汇率损失和利息损失及其他风险担责，并且本公司无需向你支付此等款项的孳息。

      4.7 当你使用本服务时，你在开户之日起6个月内无交易记录的，本公司有权暂停对你提供本服务，待重新核实身份后，可以恢复服务。

      4.8 你使用本服务时，应当仔细确认交易及其金额后进行支付。本公司有权根据你的指令扣划资金给收款人，届时你不应以未在交易单据中签名、签名不符、非本人意愿交易等原因要求本公司退款或承担其他责任。

      4.9 你对使用本服务过程中发出指令的合法性、真实性、完整性及有效性承担责任，本公司依照你的指令进行操作的责任由你自行承担。

      4.10 你应按照本公司的要求完善你的身份信息以最终达到实名认证， 否则你可能会受到收款、提现和（或）支付（包括但不限于零钱、红包、理财通）的限制，且本公司有权对你的微信支付账户暂停收付款，直至你达到实名认证的标准。

      4.11 你同意本公司有权以电子邮件、财付通网站公告、电话或传真等方式通知你关于使用本服务的信息。

      4.12 本公司向你提供实时到账、普通到账、次日到账等多种转账方式供你选择（微信红包除外），你可以设置默认转账方式（你首次选择的转账方式即为默认转账方式，你也可以调整默认转账方式），为了保障你的资金安全，本公司将根据你账户的风险管理情况设置转账日限额和笔数，或你自行设置的日限额和笔数进行转账，超出限额和笔数的，本公司将不再办理转账业务。

      4.13 你承诺不为任何非法目的或以任何非法方式使用本服务，也不将本服务用于法律、法规禁止或限制持有或交易物品的交易。

      4.14 你不得利用本服务实施下列任一行为：

      （1） 反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一；

      （2） 侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权及其他权利；

      （3） 冒用他人名义使用本服务或你提交的电话号码与身份证号无法通过校验的；

      （4） 从事任何不法交易行为，如贩卖枪支、毒品、禁药、盗版软件，在网上买卖POS机（包括MPOS）、刷卡器等受理终端，或贩卖其他违禁物；

      （5） 提供赌博资讯或以任何方式引诱他人参与赌博；

      （6） 涉嫌实施或实施洗钱、套现或进行传销活动；

      （7） 使用无效信用卡或他人信用卡进行交易；

      （8） 使用无效银行账户或他人银行账户进行交易；

      （9） 侵害本服务系統；

      （10） 违反法律法规、监管规定、本公司网站（https://www.tenpay.com/v3/）的条款、协议、规则、通告等相关规定；

      （11）出租、出借、出售、购买银行账户或者支付账户，假冒他人身份或者虚构代理关系开立银行账户或者支付账户；

      （12） 从事其他违反国家法律、法规、规章及政策法令的行为，或其他违反本协议的行为。
    </div>
   
  </div>    
</template>

<script setup>
import config from "@/config/deploy";
import { postJson } from '@/api/axiosInstance.js'
import { Button, showToast  } from 'vant';
import  {useopenidStore} from '@/stores'
import { ref } from "vue";
import router from '@/router'
import 'vant/es/Button/style';

const openidstroe=useopenidStore();
    if (openidstroe.data.openid)  {
      showToast('已登录');
    } else {
      showToast('系统异常');
    } 

    function backPage() {
      router.back(-1)
    }    

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.protocol {
  width: 100%;
  background: #ffffff;
  padding: 0;
  margin: 0;
}
.pro-title {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  text-align: center;
}
.pro-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
}
.pro-footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.pro-button {
  width: 100%;
}
</style>