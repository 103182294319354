<template>


      <!-- 导航栏 -->
      <van-nav-bar title="预约住院" @click-left="onClickLeft" @click="data.show = true">
            <template #right>
                切换就诊人
                <van-icon name="exchange" size="18" />
            </template>
            <template #left>
                <van-icon name="arrow-left" /> 返回
            </template>
        </van-nav-bar>

        <van-popup v-model:show="data.show" round closeable position="bottom">
            <div class="footer">
                <van-cell-group>
                    <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
                        v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
                </van-cell-group>
            </div>
        </van-popup>


    <van-cell-group class="byxx">
            <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
                :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
            </van-cell>
            <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
                <template #title>
                    <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
                </template>

            </van-cell>
        </van-cell-group>


    <div class="bind">
        <div class="bind-top">请填写预约信息</div>
        <div class="bind-form">

            <van-field label="卡号：" placeholder="卡号" v-model="data.kh"></van-field>
            <van-field label="姓名：" placeholder="请输入姓名" v-model="data.xm"></van-field>
            <!--显示科室-->
            <van-field label="预约科室：" placeholder="选择科室" v-model="data.ksmc" @click="data.showksxx = true" is-link
                readonly />
            <van-popup v-model:show="data.showksxx" round position="bottom">
                <div class="footer">
                    <van-picker title="选择科室" :show-cancel-button="false" :columns="data.kslist"
                        @cancel="data.showksxx = false" @confirm="onksConfirm" />
                </div>
            </van-popup>

         <!--预约日期-->
            <van-field label="预约入院日期：" placeholder="预约入院日期" v-model="data.yyrq" @click="data.showyyrq = true" is-link readonly />
            <van-popup v-model:show="data.showyyrq" round position="bottom">
                <div class="footer">
                    <van-date-picker v-model="data.currentDate" title="选择日期" :min-date="data.minDate"  @cancel="data.showyyrq = false" 
                        :max-date="data.maxDate" @confirm="onyyrqConfirm" />
                </div>
            </van-popup>
            <van-field label="联系电话：" placeholder="请输入联系电话" type="tel" v-model="data.lxdh"></van-field>
            <van-field label="备注：" placeholder="几人间预约"  v-model="data.bz"></van-field>
        </div>


        <div class="bind-footer">
            <van-button type="primary" class="bind-button" :disabled="data.disabled"
                @click.native="yyry">预约入院</van-button>
        </div>

        <div class="bind-footer">
            <van-button type="primary" class="bind-button" :disabled="data.disabled"
                @click.native="yyrycx">预约记录查询</van-button>
        </div>

        <div class="tips">
            为了您和家人的合法权益不被侵害，如果没有预留手机号，需往前台找工作人员设置方可绑定就诊卡！
        </div>
    </div>
</template>
    
<script setup>


import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

var data = ref({
    show: false,
    showksxx: false,
    showyyrq: false,
    disabled: false,

    kslist: [
        { text: '汉族', value: '汉族' },
        { text: '蒙古族', value: '蒙古族' },
        { text: '回族', value: '回族' },
        { text: '藏族', value: '藏族' },
        { text: '维吾尔族', value: '维吾尔族' },
        { text: '苗族', value: '苗族' },
        { text: '彝族', value: '彝族' },
        { text: '壮族', value: '壮族' },
        { text: '布依族', value: '布依族' }
    ],

   
    minDate: new Date(),
    maxDate: new Date(),
    currentDate:[],

    defaultCard: {},
    openidstroe: {},

    kh :'',  //卡号
    ksbm: '', //科室编码
    ksmc: '', //科室名称
    yyrq: '', //预约日期
    lxdh: '', //联系电话
    bz: '',//备注
})

const onClickLeft = () => history.back();

function yyrycx(){
    router.push({ path: '/Yyrycx', query: { kh: data.value.kh  }})
}


function onksConfirm({ selectedOptions }) {
    console.log(data.value.xb)
    data.value.ksmc = selectedOptions[0].text;
    data.value.ksbm = selectedOptions[0].value;
    data.value.showksxx = false;

    console.log('科室名选择：' + data.value.ksmc);
    console.log('科室编码选择：' + data.value.ksbm)
}


function choosecard(k) {
    data.value.defaultCard = data.value.cardlist[k]
    data.value.show = false;
    setDefault(data.value.defaultCard.id)
    data.value.kh = data.value.defaultCard.kh
    data.value.xm = data.value.defaultCard.xm
    data.value.lxdh=data.value.defaultCard.mobile
}

function setDefault(id) {
    console.log('默认值id:' + id)
    let params = {
        lx: 'setdefaultcard',
        id: id,
        openid: data.value.openid,
    }
    postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret == 0) {
            getcardlist()

        }
        else {

            showFailToast(res.data.reinfo);
        }
    })
}  
function onyyrqConfirm() {
     data.value.yyrq= data.value.currentDate[0] + '-' + data.value.currentDate[1] + '-' + data.value.currentDate[2]
     data.value.showyyrq = false
    console.log('预约日期选择：' +  data.value.yyrq)
}

//获取字典
function getxblist(zdlx) {

    let params = {
        lx: zdlx
    }
    console.log('获取字典')
    postJson('Wechat/Service', params, 2).then(res => {
        console.log(res.data)
        if (zdlx == 'getxblist') {
            data.value.xblist = res.data
        }
        if (zdlx == 'getmzlist') {
            data.value.mzlist = res.data
        }
        if (zdlx == 'gethyzklist') {
            data.value.hyzklist = res.data
        }
        if (zdlx == 'getzylist') {
            data.value.zylist = res.data
        }
        if (zdlx == 'zyks') {
            data.value.kslist = res.data

        }

    }).catch(err => {
        console.log('获取字典出错')
        showFailToast(err)
    })
}



onMounted(() => {
    console.log('进入预约入院页面');
    data.value.openid = useopenidStore().data.openid

    if (!data.value.openid) {
        showToast('系统异常:没有openid');
        router.back();
    }

  data.value.maxDate.setDate(data.value.maxDate.getDate() + 7);
  data.value.maxDate = new Date(data.value.maxDate);
  getcardlist()
getxblist("zyks")

})

function getcardlist() {
    let params = {
        lx: 'getcardbyopenid',
        openid: data.value.openid
    }
    console.log(params)
    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 100000
    });

    postJson('WxpayService', params, 2).then(res => {
        console.log(res)
        if (res != null) {
            if (res.data.length) {
                console.log('查询cardlist正常返回')
                data.value.cardlist = res.data
                bindcard();
                console.log(data.value.cardlist)
                closeToast();
            } else {
                showToast('请先绑定就诊卡');

            }
        }
    }).catch(err => {
        console.log('查询cardlist错误返回')
        console.log(err)
        showFailToast(err)
    })
}
function bindcard() {
    if (data.value.cardlist.length > 0) {
        data.value.cardlist.forEach(element => {
            console.log(element)
            if (element.xz) {
                data.value.defaultCard = element
                data.value.kh = data.value.defaultCard.kh
                data.value.xm = data.value.defaultCard.xm
                data.value.lxdh=data.value.defaultCard.mobile


                console.log(element)             
            }
        });
    }
    else {
        showNotify({ type: "danger", message: "没有卡号" })
    }
}
function yyry() {


    if (data.value.kh == '') {
        showToast({
            message: '卡号不能为空',
            position: 'top'
        });
        return;
    }

    if (data.value.ksbm == '') {
        showToast({
            message: '预约科室不能为空',
            position: 'top'
        });
        return;
    }

    if (data.value.yyrq == '') {
        showToast({
            message: '预约日期不能为空',
            position: 'top'
        });
        return;
    }

    if (data.value.lxdh == '') {
        showToast({
            message: '电话号码不能为空',
            position: 'top'
        });
        return;
    }




    const params = {
        lx: 'yyry',
        kh: data.value.kh,
        ksbm: data.value.ksbm,
        yyrq: data.value.yyrq,
        lxdh: data.value.lxdh,
        bz: data.value.bz,
    

    }
    console.log('自动建卡入参')
    console.log(params)
    //绑卡按钮不显示
    postJson('Wechat/Service', params, 2).then(res => {
        console.log('出参：')
        console.log(res.data)
        //返回成功后绑卡按钮显示
        data.value.disabled = false

        if (res != null) {
            if (res.data.ret == 0) {
                showToast({
                    message: '预约成功',
                    position: 'top',
                });
             data.value.ksbm=''
             data.value.yyrq=''
             data.value.bz=''

             yyrycx();


            } else {
                showToast({
                    message: res.data.errormessage,
                    position: 'top',
                });
            }
        }
    }).catch(error => {
        data.value.disabled = false
        showToast({
            message: error,
            position: 'top',
        });

    });
}


</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.bind {
    width: 100%;
}

.bind-top {
    padding: 0.75rem;
    font-size: 0.75rem;
    color: #909399;
}

.bind-form {
    width: 100%;
}

.bind-protocol {
    padding: 0.75rem;
    display: flex;
}

.bind-pro-check {
    margin-right: 6px;
}

.bind-pro-txt {
    font-size: 0.75rem;
    color: #303133;
    margin-top: 2px;
}

.bind-footer {
    padding: 1rem 0.75rem;
}

.bind-button {
    width: 100%;
}

.tips {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    color: #E6A23C;
}
</style>