<template>
  <div class="mainer">
    <!-- 导航栏 -->
    <van-nav-bar title="报告查询" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        切换就诊人
        <van-icon name="exchange" size="18" />
      </template>
      <template #left>
        <van-icon name="arrow-left" /> 返回
      </template>
    </van-nav-bar>

    <van-cell-group class="byxx">
      <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
        :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
      </van-cell>
      <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
        <template #title>
          <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-notice-bar mode="closeable">仅能查询部分检查报告，此结果仅作参考，以医院纸质报告为准！</van-notice-bar>

    <div class="bodyer">

      <van-tabs v-model:active="activeName" swipeable>

        <van-tab title="检查报告" name="a">
          <van-cell-group style="margin-top: 1em;" v-if="data.jcList.length">
           <div  v-for="brxx in data.jcList" :key="brxx">
            <van-cell :title="'门诊号：' + brxx.mzh"  :value="brxx.ghrq"    is-link center  @click="showjcxm(brxx)" />
            <div  v-for="jcxm in brxx.jcxm" :key="jcxm">
              <van-cell v-if="jcxm.jcjg.length"  :title="'检查项目：' + jcxm.jcxm" is-link @click="showjcjg(jcxm)" style="color:brown;"/>
              <div v-if="jcxm.showjcjg"  v-for="jcjg in jcxm.jcjg" :key="jcjg"> <!--  v-for="jyjg in jyxm.jyjg" :key="jyjg"-->
                  <van-cell title="检查方法" :value="jcjg.summary" />
                  <van-cell title="诊断结果" :value="jcjg.impression_text" />
                  <van-cell title="检查项目" :value="jcjg.StudyItems" />
                  <van-cell title="影响所见" :value="jcjg.finding_text" />                    
                </div>
            </div> 
          </div>

          </van-cell-group>
       
          <div v-else style="margin-top: 2em;">
            <van-empty description="暂无检查报告" />
          </div>
        </van-tab>


        <van-tab title="检验报告" name="b">
          <van-cell-group style="margin-top: 1em;" v-if="data.jyList.length">
            <!-- <van-cell :title="jy.mzh" :value="jy.ghrq"  v-for="jy in data.jyList" :key="jy"  /> -->
            <!-- <van-cell  :title="'门诊号：'+jy.mzh"   v-for="jy in data.jyList" :key="jy" is-link @click="showjcxm"> -->
            <div class="parter" v-for="brxx in data.jyList" :key="brxx">
              <van-cell :title="'门诊号：' + brxx.mzh" is-link @click="showjcxm(brxx)"  />
              <div v-if="brxx.showjcxm" v-for="jyxm in brxx.jyxm" :key="jyxm">
            
                <van-cell :title="'检验项目：' + jyxm.mc" is-link @click="showjcjg(jyxm)" style="color:brown;"/>
                <div v-if="jyxm.showjcjg"  v-for="jyjg in jyxm.jyjg" :key="jyjg"> <!--  v-for="jyjg in jyxm.jyjg" :key="jyjg"-->
                  <van-cell :title="jyjg.jgmc" :value="jyjg.jgz + '/' + jyjg.unit" :label="jyjg.ckz" 
                    />
                </div>
              </div>

            </div>


          </van-cell-group>

          <div v-else style="margin-top: 2em;">
            <van-empty description="暂无检验报告" />
          </div>
        </van-tab>
      </van-tabs>

    </div>


    <!--切换就诊人 -->
    <van-popup v-model:show="data.show" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data = ref({
  openid: '',
  show: false,
  cardlist: [],
  defaultCard: {},
  jcList: [],
  jyList: [],
}
)

function showjcxm(brxx) {
  brxx.showjcxm = !brxx.showjcxm
}
function showjcjg(jyxm) {
  console.log(jyxm)
 jyxm.showjcjg = !jyxm.showjcjg
}

onMounted(() => {
  data.value.openid = useopenidStore().openid
  getcardlist()
}
)
function getjcxx() {
  closeToast();
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    duration: 10000
  });

  let params = {
    lx: 'getjcxx',
    kh: data.value.defaultCard.kh,
  }
 console.log('获取检查信息参数：' + params)
  postJson('Wechat/Service', params, 2).then(res => {
    data.value.jcList = res.data
    console.log("检查返回数组")
    console.log(data.value.jcList)
    closeToast();
  })
}

function getjyxx() {



  let params = {
    lx: 'getjyxx',
    kh: data.value.defaultCard.kh,
  }

  console.log('获取检验信息参数：' + params)
  postJson('Wechat/Service', params, 2).then(res => {
    data.value.jyList = res.data
    console.log("检验返回数组")
    console.log(data.value.jyList)

  })

}

function bindcard() {
  if (data.value.cardlist.length > 0) {
    data.value.cardlist.forEach(element => {
      console.log(element)
      if (element.xz) {
        data.value.defaultCard = element
        getjcxx()
        getjyxx()
        
        
        console.log(element)
      }
    });
  }
  else {
    showNotify({ type: "danger", message: "没有卡号" })
  }
}

onMounted(() => {
  data.value.openid = useopenidStore().data.openid
  getcardlist()
}
)

function getcardlist() {
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }
  console.log(params)


  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        console.log('查询cardlist正常返回')
        data.value.cardlist = res.data
        bindcard();
        console.log(data.value.cardlist)
       
      } else {
        showToast('请先绑定就诊卡');

      }
    }
  }).catch(err => {
    console.log('查询cardlist错误返回')
    console.log(err)
    showFailToast(err)
  })
}



function onClickLeft() {
  history.back();
}


function onClickRight() {
  data.value.show = true
}
function choosecard(k) {
  data.value.defaultCard = data.value.cardlist[k]
  data.value.show = false;
  setDefault(data.value.defaultCard.id)
}

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.byxx {
  background-color: #888;
}

.office {
  width: 100%;
}

.parter {
  margin-top: 1em;
}
</style>