<template>
    <van-address-edit
  :area-list="data.areaList"
  save-button-text="下一步"
  :area-columns-placeholder="['请选择', '请选择', '请选择']"
  @save="onSave"

/>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'
import { areaList } from '@vant/area-data';

const emit = defineEmits(['getaddress'])

var data = ref({
openid: '',
areaList,
}
)

function onSave(info){
  console.log(info,info.value)
    emit('getaddress',info)

}

onMounted(() => {
data.value.openid = useopenidStore().data.openid
data.value.areaList=areaList
}
)

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>