<template>
    <div class="mainer">
        <!-- 导航栏 -->
        <van-nav-bar title=住院交款 @click-left="onClickLeft" @click-right="onClickRight">
            <template #left>
                <van-icon name="arrow-left" /> 返回
            </template>
            <template #right>
                <van-icon name="wap-home-o" size="18" />
            </template>
        </van-nav-bar>



        <div class="form" v-if="data.info">
            <van-form @submit="onSubmit">
                <van-cell-group inset>
                    <van-cell title="病员姓名" :value="data.info.byxm" size="large" />
                    <van-cell title="科室名称" :value="data.info.ksmc" size="large" />
                    <van-cell title="收费种类" :value="data.info.sfzl" size="large" />
                    <van-cell title="住院号" :value="data.info.zyh" size="large" />
                    <van-cell title="预交款总额" :value="data.info.jkze" size="large" />
                    <van-cell title="费用总额" :value="data.info.fyze" size="large" :border="false" />
                    <van-divider contentPosition="center">预缴住院费</van-divider>
                    <van-field v-model="data.jkje" type="digit" name="缴费金额" label="缴费金额" placeholder="请输入不小于100的整数金额"
                        size="large" :rules="[{ required: true, message: '请输入缴费金额' }]" />
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">
                        确认缴款
                    </van-button>
                </div>
                <div class="shuoming">
                    缴费成功以微信通知为准
                </div>
            </van-form>
        </div>

        <!--切换就诊人 -->
        <van-popup v-model:show="data.show" round closeable position="bottom">
            <div class="footer">
                <van-cell-group>
                    <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
                        v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
                </van-cell-group>
            </div>
        </van-popup>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data = ref({
    openid: '',
    show: false,
    cardlist: [],
    defaultCard: {},
    info: null,
    jkje: 0.00
}
)

function onClickRight() {
    router.push({ path: "/" });
}

function onSubmit() {
    if (!data.value.jkje || data.value.jkje < 1) {
        showToast('缴费1元起');
        return false
    }
    if (!data.value.info.zyh || !data.value.info.zykh) {
        showToast('住院号有误');
        return false
    }
    showLoadingToast({
        message: '订单创建中...',
        forbidClick: true,
        duration: 10000
    });

    let params = {
        lx: 'createorder',
        openid: data.value.openid,
        card_no: data.value.info.zykh,
        zyh: data.value.info.zyh,
        TradeType: 'JSAPI',
        order_type: 'zyjk',
        zje: data.value.jkje,
        discount: 0,
        yhhje: data.value.jkje,
        jkje: data.value.jkje,

    }
    console.log(params)

    postJson('WxpayService', params, 2).then(result => {

        console.log(JSON.stringify(result.data));

        if (result.data != null) {
            console.log("拉起收银台1");
            const temp = {
                appId: result.data.appId,
                timeStamp: result.data.timeStamp,
                nonceStr: result.data.nonceStr,
                package: result.data.packageValue,
                signType: result.data.signType,
                paySign: result.data.paySign,
            }
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', temp,
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        closeToast()
                        router.replace({ path: '/ZyjkResult', query: { orderid: result.data.orderid } });
                        // wx.closeWindow();
                    }
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。     
                }
            );

            // router.replace({ path: '/todayResult', query:{orderid: result.data.orderid}});
        }
        else {
            showNotify({
                type: 'danger',
                message: '下单失败'
            })
        }
    }).catch(err => {
        closeToast()
        console.log(err);
        showFailToast('下单失败')
    });

}




onMounted(() => {
    data.value.info = JSON.parse(router.currentRoute.value.query.info)
    data.value.openid = useopenidStore().data.openid;
    console.log('data.value.info')
    console.log(data.value.info)
}
)

function getcardlist() {
    let params = {
        lx: 'getcardbyopenid',
        openid: data.value.openid
    }
    console.log(params)
    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 100000
    });

    postJson('WxpayService', params, 2).then(res => {
        console.log(res)
        if (res != null) {
            if (res.data.length) {
                console.log('查询cardlist正常返回')
                data.value.cardlist = res.data
                bindcard();
                console.log(data.value.cardlist)
                closeToast();
            } else {
                showToast('请先绑定就诊卡');

            }
        }
    }).catch(err => {
        console.log('查询cardlist错误返回')
        console.log(err)
        showFailToast(err)
    })
}

function bindcard() {
    if (data.value.cardlist.length > 0) {
        data.value.cardlist.forEach(element => {
            console.log(element)
            if (element.xz) {
                data.value.defaultCard = element
                console.log(element)
            }
        });
    }
    else {
        showNotify({ type: "danger", message: "没有卡号" })
    }
}

function onClickLeft() {
    history.back();
}



function choosecard(k) {
    data.value.defaultCard = data.value.cardlist[k]
    data.value.show = false;
    setDefault(data.value.defaultCard.id)
}

function setDefault(id) {
    console.log('默认值id:' + id)
    let params = {
        lx: 'setdefaultcard',
        id: id,
        openid: data.value.openid,
    }
    postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret == 0) {
            getcardlist()

        }
        else {

            showFailToast(res.data.reinfo);
        }
    })
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.office {
    width: 100%;
}

.form {
    margin-top: 1em;
}

.shuoming {
    width: 90%;
    margin: 12px auto;
    font-size: 14px;
    color: #969799;
    text-align: center;
}
</style>