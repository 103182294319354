<template>
      <van-checkbox-group v-model="data.checked" shape="square" @click="getje">
              <!-- 通过 CellGroup 的 inset 属性，可以将单元格转换为圆角卡片风格 -->
              <van-cell-group inset>
  <van-cell size="large" v-for="item in data.xmbmlist" :key="item.bh">
    <template #title>
      <van-checkbox :name="item.bh">{{ item.mc }}</van-checkbox>
    </template>
    <template #label>
      <span style="font-size: 12px"> {{ item.yblx }} 
        <hr />注意事项： {{ item.zysx }}
      </span>
    </template>
    <template #right-icon>
      
      ¥{{ item.je }}
    </template>

  </van-cell>
</van-cell-group>
 </van-checkbox-group>

 <van-cell size="large">
                  <template #title>
                    支付总金额
                  </template>
                  <template #right-icon>
                    <div style="color: #ED6A0C;">¥{{  data.zfje|| 0 }}</div>
                  </template>
    </van-cell>

  <div class="button">
    <van-button type="primary" round block @click="order('wxpay')"> 确定下单</van-button>
 </div>
</template>



<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showFailToast,showLoadingToast } from 'vant';
import router from '@/router'

const props = defineProps([
    "zxks", "kh", "openid","yyrq"
]
)

const emit = defineEmits(['getxmxx'])

var data = ref({
  xmbmlist: [],
  checked: [],
  zfje:0,
}
)

function getje(){
  data.value.zfje = 0
  data.value.checked.forEach(check_bh=> {    
    data.value.xmbmlist.forEach(jyxm => {
      if (jyxm.bh === check_bh) {
        data.value.zfje = data.value.zfje + jyxm.je
      }
    })
  })
}
function order(channel) {
  if (data.value.checked.length < 1) {
    showFailToast("请选择付款项");
    return;
  }
  showLoadingToast({
    message: '提交中...',
    forbidClick: true,
    duration: 10000
  });





  let items = []
  let item = {}
  data.value.zfje=0
  data.value.checked.forEach(check_bh=> {
    item = { 'xmbm': check_bh }
    items.push(item)
    data.value.xmbmlist.forEach(jyxm => {
      if (jyxm.bh === check_bh) {
        data.value.zfje = data.value.zfje + jyxm.je
      }
    })
  })
  console.log(items)
  console.log(data.value.zfje)



  let params = {
    lx: 'createorder',
    openid: props.openid,
    yyrq: props.yyrq,
    card_no: props.kh,
    zxks: props.zxks,
    TradeType: 'JSAPI',
    order_type: 'mzsf',
    zje: data.value.zfje.toFixed(2),
    discount: 0,
    yhhje: data.value.zfje.toFixed(2),
    zfje: data.value.zfje.toFixed(2),
    listsxh: items,
    invoice: 'jcjy_yydj',
    channel: channel,
    sflx: 'jcjy_yydj'
  }

  console.log('收费下单入参')
  console.log(params)


  postJson('WxpayService', params, 2).then(result => {

console.log(JSON.stringify(result.data));

if (result.data != null) {
  console.log("拉起收银台1");
  const temp = {
    appId: result.data.appId,
    timeStamp: result.data.timeStamp,
    nonceStr: result.data.nonceStr,
    package: result.data.packageValue,
    signType: result.data.signType,
    paySign: result.data.paySign,
  }
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest', temp,
    function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        router.replace({ path: '/PaymentResult', query: { orderid: result.data.orderid } });
        // wx.closeWindow();
      } else{
        showNotify({
          type: "danger",
          message: "未支付，或者支付失败"
        })
      }
      // 使用以上方式判断前端返回,微信团队郑重提示：
      // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。     
    }
  );

  // router.replace({ path: '/todayResult', query:{orderid: result.data.orderid}});
}
else {
  
  showNotify({
    type: 'danger',
    message: '下单失败'
  })

}
}).catch(err => {
// Indicator.close();
console.log(err);
showFailToast('下单失败')
unlockSerial()

});_


}

function getxmxx() {
  postJson('Wechat/Service', { 'lx': 'jcjy_getxmbm','zxks': props.zxks }).then(res => {
    console.log('正常返回xmxxlist')
    console.log(res)
    data.value.xmbmlist = res.data

  }).catch(err => {
    console.log('查询项目列表错误返回')
    console.log(err)
    showFailToast(err)
  })
}


onMounted(() => {
  getxmxx()

}
)


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.office {
    width: 100%;
}

.shuoming {
    width: 90%;
    margin: 12px auto;
    font-size: 14px;
    color: #969799;
    text-align: center;
}
</style>