import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useopenidStore = defineStore( 'data', () => {

  const  data= ref({
    openid:"",
    showmessage:true,
  })


  return { data }
}
)
