<template>
    <van-nav-bar title="自助建卡" @click-left="onClickLeft" @click-right="onClickRight">
        <template #right>
            <van-icon name="wap-home-o" size="18" />
        </template>
        <template #left>
            <van-icon name="arrow-left" /> 返回
        </template>
    </van-nav-bar>


    <div class="bind">
        <div class="bind-top">请填写身份信息</div>
        <div class="bind-form">
            <van-field label="姓名：" placeholder="请输入姓名" v-model="data.xm"></van-field>
            <van-field label="身份证号：" placeholder="请输入本人或监护人证件号" v-model="data.sfzh"></van-field>

            <van-field label="性别：" placeholder="选择性别" v-model="data.xb" @click="data.showxb = true" is-link  readonly/>
        
            <!--显示选择性别-->
            <van-popup v-model:show="data.showxb" round  position="bottom">
                <div class="footer">
                    <van-picker 
                     title="性别选择"
                     :show-cancel-button="false"
                     :columns="data.xblist"
                     @cancel="data.showxb = false"
                     @confirm="onxbConfirm" />
                </div>
            </van-popup>

            <van-field label="民族：" placeholder="民族" v-model="data.mz" @click="data.showmz = true" is-link  readonly/>
            <!--显示选择民族-->
            <van-popup v-model:show="data.showmz" round  position="bottom">
                <div class="footer">
                    <van-picker :columns="data.mzlist" @cancel="data.showmz = false" @confirm="onmzConfirm" />
                </div>
            </van-popup>

            <van-field label="职业：" placeholder="职业" v-model="data.zy" @click="data.showzy = true" is-link readonly/>
            <!--显示选择职业-->
            <van-popup v-model:show="data.showzy" round  position="bottom">
                <div class="footer">
                    <van-picker :columns="data.zylist" @cancel="data.showzy = false" @confirm="onzyConfirm"  />
                </div>
            </van-popup>

            <van-field label="婚姻状况：" placeholder="婚姻状况" v-model="data.hyzk" @click="data.showhyzk = true" is-link readonly/>
            <!--显示选择婚姻状况-->
            <van-popup v-model:show="data.showhyzk" round  position="bottom">
                <div class="footer">
                    <van-picker :columns="data.hyzklist" @cancel="data.showhyzk = false" @confirm="onhyzkConfirm" />
                </div>
            </van-popup>


            <van-field label="居住地址：" placeholder="居住地址" v-model="data.jzdz"></van-field>
            <van-field label="联系人姓名：" placeholder="联系人姓名" v-model="data.lxrxm"></van-field>
            <van-field label="联系人电话：" placeholder="联系人电话" v-model="data.lxrdh"></van-field>
            <van-field label="联系人地址：" placeholder="联系人地址" v-model="data.lxrdz"></van-field>



            <van-field label="手机号：" placeholder="请输入手机号" type="tel" v-model="data.sjhm"></van-field>

            <van-field label="验证码：" placeholder="请输入验证码" type="number" v-model="data.captcha">
                <template #button>
                    <van-button type="danger" size="small" :disabled="data.discap" @click.native="sending">{{ data.content
                    }}</van-button>
                </template>
            </van-field>
        </div>

        <div class="bind-protocol">

            <div class="bind-pro-check">
                <input type="checkbox" v-model="data.agree">
            </div>
            <div class="bind-pro-txt">已阅读并同意<router-link to="/Protocol">《自助建卡》 </router-link></div>
        </div>

        <div class="bind-footer">
            <van-button type="primary" class="bind-button" :disabled="data.disabled"
                @click.native="CreateCard">自助建卡</van-button>
        </div>

        <div class="tips">
            为了您和家人的合法权益不被侵害，如果没有预留手机号，需往前台找工作人员设置方可绑定就诊卡！
        </div>
    </div>
</template>
    
<script setup>

import config from "@/config/deploy";
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

var data = ref({
    showmz: false,
    showhyzk: false,
    showxb: false,
    showzy: false,
    show:true,
    disabled:false,

    mzlist: [
        { text: '汉族', value: '汉族' },
        { text: '蒙古族', value: '蒙古族' },
        { text: '回族', value: '回族' },
        { text: '藏族', value: '藏族' },
        { text: '维吾尔族', value: '维吾尔族' },
        { text: '苗族', value: '苗族' },
        { text: '彝族', value: '彝族' },
        { text: '壮族', value: '壮族' },
        { text: '布依族', value: '布依族' }
    ],

    hyzklist: [
    { text: '未婚', value: '未婚' },
    { text: '已婚', value: '已婚' },
    { text: '离异', value: '离异' },
    { text: '丧偶', value: '丧偶' },
  ],
    xblist: [
    { text: '男', value: '1' },
    { text: '女', value: '2' },   
    ],


    zylist: [
    { text: '国家机关党群组织、企事业单位负责人', value: '国家机关党群组织、企事业单位负责人' },
    { text: '各类专业技术人员', value: '各类专业技术人员' },
    { text: '办事人员和有关人员', value: '办事人员和有关人员' },
    { text: '商业、服务业人员', value: '商业、服务业人员' },
    { text: '农、林、牧、渔、水利业生产人员', value: '农、林、牧、渔、水利业生产人员' },
    { text: '生产、运输设备操作人员及有关人员', value: '生产、运输设备操作人员及有关人员' },
    { text: '军人', value: '军人' },
    { text: '不便分类的其他从业人员', value: '不便分类的其他从业人员' },           
    ],
    openidstroe: {},
    dabh:'',
    xm: '', //姓名
    sfzh: '', //身份证号
    mz: '', //民族
    mzbm:'',//民族编码
    sjhm: '', //手机号
    xb:'', //性别
    xbbm:'',//性别编码
    cardno: '', //卡号
    zy: '', //职业
    zybm:'',//职业编码
    hyzk: '', //婚姻状况
    hyzkbm:'',//婚姻状况编码
    jzdz: '', //居住地址
    lxrxm: '',//联系人姓名
    lxrdh: '',//联系人电话
    lxrdz: '',//联系人地址
    captcha: '', //验证码

    //验证码发送按钮显示
    content: '发送验证码',
    discap: false,
    total: 60,

    //是否同意协议
    agree: false,

})

const onClickLeft = () => history.back();
const onClickRight = () => router.push({ path: "/" });



function onxbConfirm({ selectedOptions }) {
    console.log(data.value.xb)
    data.value.xb = selectedOptions[0].text;
    data.value.xbbm=selectedOptions[0].value;
    data.value.showxb = false;
    console.log('性别选择：'+data.value.xb)
    console.log('性别编码选择：'+data.value.xbbm)
}

function onmzConfirm({ selectedOptions }) {
    data.value.mz = selectedOptions[0].text
    data.value.mzbm = selectedOptions[0].value
    data.value.showmz = false
    console.log('民族选择：'+data.value.mz+data.value.mzbm)
}

function onzyConfirm({ selectedOptions }) {
    data.value.zy = selectedOptions[0].text
    data.value.zybm = selectedOptions[0].value
    data.value.showzy = false
    console.log('职业选择：'+data.value.zy+data.value.zybm)
}


function onhyzkConfirm({ selectedOptions }) {
    data.value.hyzk =selectedOptions[0].text
    data.value.hyzkbm  =selectedOptions[0].value
    data.value.showhyzk = false
    console.log('婚姻状况选择：'+data.value.hyzk+data.value.hyzkbm)
}

//获取字典
function getxblist(zdlx) {
   
  let params = {
    lx: zdlx
  }
  console.log('获取性别字典')
  postJson('Wechat/Service', params, 2).then(res => {
    console.log(res.data )
    if (zdlx=='getxblist') {
      data.value.xblist = res.data
    }
    if (zdlx=='getmzlist') {
      data.value.mzlist = res.data
    }
    if (zdlx=='gethyzklist') {
      data.value.hyzklist = res.data
    }
    if (zdlx=='getzylist') {
      data.value.zylist = res.data
    }
               
  }).catch(err => {
    console.log('获取字典出错')
    showFailToast(err)
  })
}


// 过身份证号判断年龄、性别、出生日期
function idCard(sfzh, num) {
//获取出生日期
var birth ;
    if (num == 1) {
        birth = userCard.substring(6, 10) + "-" + userCard.substring(10, 12) + "-" + userCard.substring(12, 14);
        return birth;
    }
//获取性别
    if (num == 2) {
        if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
            return "男";
        } else {
            return "女";
        }
    }
//获取年龄
    if (num == 3) {
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - userCard.substring(6, 10) - 1;
        if (userCard.substring(10, 12) < month || userCard.substring(10, 12) == month && userCard.substring(12, 14) <= day) {
            age++;
        }
        return age;
    }
}

onMounted(() => {
    data.value.openidstroe = useopenidStore();
    if (!data.value.openidstroe.data.openid) {
        showToast('系统异常');
         //  router.back();
    }
    
    data.value.xm =router.currentRoute.value.query.name
    data.value.sfzh =router.currentRoute.value.query.idcard
    data.value.sjhm =router.currentRoute.value.query.mobile

 


    getxblist("getxblist")
    getxblist("getmzlist")
    getxblist("gethyzklist")
    getxblist("getzylist")

//    data.value.xb= idCard(data.value.sfzh,2)
//     if (data.value.xb=='男'){
//         data.value.xbbm=1
//     }else{
//         data.value.xbbm=2
//     }

//     console.log('性别：'+data.value.xb+'编码：'+data.value.xbbm)

})

function sending() {
    console.log('sending')
    if (!data.value.xm || !data.value.sfzh || !data.value.sjhm) {
        showToast({
            message: '姓名:'+data.value.xm+'身份证号：'+data.value.sfzh+'手机:'+data.value.sjhm+'都不能为空',
            position: 'top'
        });
        return;
    }

    data.value.discap = true
    const params = {
        lx: "getverificationcode",
        name: data.value.xm,
        cardno: data.value.sfzh,
        mobile: data.value.sjhm,
        idcard: data.value.sfzh,
    }

    postJson('WxpayService', params, 2).then(res => {
        console.log(res)
        if (res.data.ret != 0) {
            showToast({
                message: res.data.reinfo + '发送失败请重新发送！',
                position: 'top'
            });
            data.value.discap = false
            //发送失败，马上可以重新发送

        } else {

            showToast({
                message: res.data.reinfo,
                position: 'top'
            });

            //发送成功，没有收到信息 60秒后可以重新发送
            let clock = window.setInterval(() => {
                data.value.total--
                data.value.content = data.value.total + 's后重新发送'
                if (data.value.total <= 0) {
                    window.clearInterval(clock)
                    data.value.content = '发送验证码'
                    data.value.total = 60
                    data.value.discap = false
                }
            }, 1000)

        }
    })
}


function bindCard() {

  const params = {
    lx: 'bingcard',
    name: data.value.xm,
    cardno: data.value.cardno,
    idcard: data.value.sfzh,
    mobile: data.value.sjhm,
    code: data.value.captcha,
    openid: data.value.openidstroe.data.openid
  }
  console.log('绑卡入参')
  console.log(params)
  postJson('WxpayService', params, 2).then(res => {
    console.debug('绑卡出参：')
    console.debug(res)

    if (res != null) {
      if (res.data.ret == 0) {
        showToast({
          message: '卡号绑定成功',
          position: 'top',
        });
        router.push({
          path: '/Cards',
        })
      }
      else {
        showToast({
          message: res.data.reinfo,
          position: 'top',
        });
      }
    }
  }).catch(error => {
    showToast({
      message: error,
      position: 'top',
    });
  });
}


function CreateCard() {
    if (!data.value.agree) {
        showToast({
            message: '请阅读并同意绑卡协议',
            position: 'top'
        });
        return;
    }

    if (data.value.xm == '') {        
        showToast({
            message: '姓名不能为空',
            position: 'top'
        });
        return;
    }

    if (data.value.xm == '') {        
        showToast({
            message: '姓名不能为空',
            position: 'top'
        });
        return;
    }

    if (data.value.sfzh == '') {       
        showToast({
            message: '身份证号不能为空',
            position: 'top'
        });
        return;
    } 

    if (data.value.mz == '') {      
        showToast({
            message: '民族不能为空',
            position: 'top'
        });
        return;
    } 

    if (data.value.sjhm == '') {      
        showToast({
            message: '电话号码不能为空',
            position: 'top'
        });
        return;
    } 

    if (data.value.captcha == '') {
        data.value.capState = 'error'
        showToast({
            message: '验证码不能为空',
            position: 'top'
        });
        return;
    } 



    const params = {
        lx: 'zdzc',
        xm: data.value.xm,
        sfzh: data.value.sfzh,
        mz: data.value.mz,
        mzbm: data.value.mzbm,
        sjhm: data.value.sjhm,
        jkkh: '',
        xb: data.value.xb,
        xbbm:data.value.xbbm,
        zy:data.value.zy,
        zybm:data.value.zybm,
        hyzk:data.value.hyzk,
        hyzkbm:data.value.hyzkbm,
        jzdz:data.value.jzdz,
        lxrxm:data.value.lxrxm,
        lxrdh:data.value.lxrdh,
        lxrdz:data.value.lxrdz,
        openid: data.value.openidstroe.data.openid,
        captcha: data.value.captcha,

    }
    console.log('自动建卡入参')
    console.log(params)
        //绑卡按钮不显示
        data.value.disabled = true
    postJson('Wechat/Service', params, 2).then(res => {
        console.log('自动建卡出参：')
        console.log(res.data)
        //返回成功后绑卡按钮显示
        data.value.disabled = false

        if (res != null) {
            if (res.data.code == 10000) {
                showToast({
                    message: '自动建卡成功',
                    position: 'top',
                });      
                data.value.cardno = res.data.cardno
                data.value.xm = res.data.xm
                bindCard()
               
            } else {
                showToast({
                    message:  res.data.msg,
                    position: 'top',
                });
            }
        }
    }).catch(error => {
        data.value.disabled = false
        showToast({
                    message: error,
                    position: 'top',
                });
      
    });
}


</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.bind {
    width: 100%;
}

.bind-top {
    padding: 0.75rem;
    font-size: 0.75rem;
    color: #909399;
}

.bind-form {
    width: 100%;
}

.bind-protocol {
    padding: 0.75rem;
    display: flex;
}

.bind-pro-check {
    margin-right: 6px;
}

.bind-pro-txt {
    font-size: 0.75rem;
    color: #303133;
    margin-top: 2px;
}

.bind-footer {
    padding: 1rem 0.75rem;
}

.bind-button {
    width: 100%;
}

.tips {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    color: #E6A23C;
}
</style>