<template>
  <div class="mainer">
    <!-- 导航栏 -->
    <van-cell-group inset v-if="data.info">
      <van-cell title="病员姓名" size="large" :value="data.info.byxm" />
      <van-cell title="性别" size="large" :value="data.info.xb" />
      <van-cell title="就诊卡" size="large" :value="data.info.zykh" />
      <van-cell title="住院号" size="large" :value="data.info.zyh" />
      <!-- <van-cell title="科室" size="large" :value="data.info.ksmc" /> -->
      <!-- <van-cell title="病床号" size="large" :value="data.info.bch" /> -->
      <van-cell title="入院时间" size="large" :value="new Date(data.info.rysj).format('yyyy-MM-dd hh:mm:ss')" />
      <!-- <van-cell title="收费种类" size="large" :value="data.info.sfzl" /> -->
      <van-cell title="预交款总额" size="large">
        <template #right-icon>
          <span style="color: #ED6A0C;">¥{{ data.info.jkze }}</span>
        </template>
      </van-cell>
      <van-cell title="费用总额" size="large">
        <template #right-icon>
          <span style="color: #ED6A0C;">¥{{ data.info.fyze }}</span>
        </template>
      </van-cell>

      <div class="button">
        <van-button type="primary" round block @click="postinfo"> 下一步</van-button>
      </div>

    </van-cell-group>
    <van-empty description="没有住院信息" v-else></van-empty>

  </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

const emit = defineEmits(['zyxx'])

const props = defineProps(['kh'])
var data = ref({
   info:null
 
}
)


onMounted(() => {
  console.log('进入zyxx')
  console.log(props.kh)
  data.value.openid = useopenidStore().data.openid
  getzyxx()
  
}
)

function postinfo(){
  console.log('emit')
  emit('zyxx',data.value.info)
}

function getzyxx() {
  showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 10000
    });
    let params = {
        lx: 'getzyxx',
        kh: props.kh,
    }
    postJson('Wechat/Service', params, 2).then(res => {
      closeToast()
        data.value.info = res.data[0]
    })
}


 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.byxx {
  background-color: #888;
}

.office {
  width: 100%;
}
</style>