import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'
import Office from '@/views/reg/Office.vue'
import Doctor from '@/views/reg/Doctor.vue'

import Order from '@//views/reg/order.vue'
import YdzfOrder  from '@/views/reg/ydzfOrder/index.vue'

import Result from '@/views/reg/Result.vue'



import Resource from '@/views/reg/Resource.vue'
import Doctor_yygh from '@/views/reg/Doctor_yygh.vue'
import RegRecord from '@/views/reg/RegRecord.vue'


import Cards from '@/views/user/Cards.vue'
import BindCard from '@/views/user/BindCard.vue'
import Protocol from '@/views/user/Protocol.vue'
import ShowCard from '@/views/user/showCard.vue'
import CreateCard from '@/views/user/CreateCard.vue'

import payment from '@/views/payment/payment.vue'
import PaymentResult from '@/views/payment/PaymentResult.vue'
import Paymentlist from '@/views/payment/Paymentlist.vue'

import Report from '@/views/report/Report.vue'
import ReportExamine from '@/views/report/ReportExamine.vue'
import ReportInspect from '@/views/report/ReportInspect.vue'

import HospitalInfo from '@/views/hospital/HospitalInfo.vue'
import Jkjl from '@/views/hospital/jkjl.vue'
import zyjk from '@/views/hospital/zyjk.vue'
import ZyjkResult from '@/views/hospital/ZyjkResult.vue'
import Ssxx from '@/views/hospital/Ssxx.vue'
import Yyry from '@/views/hospital/Yyry.vue'
import Yyrycx from '@/views/hospital/Yyrycx.vue'

import Scheduling from '@/views/other/Scheduling.vue'
import WaitInfo from '@/views/other/WaitInfo.vue'
import Contact from '@/views/other/Contact.vue'
import Satisfied from '@/views/other/Satisfied.vue'
import Feedback from '@/views/other/Feedback.vue'

import CopiseofCase from '@/views/CopiseofCase.vue'
import Wisdom from '@/views/Wisdom.vue'

//医院简介
import Yyjj from '@/views/html/yyjj.vue'

//预约登记
import jcjy_yydj from '@/views/jcjy_yydj.vue'



const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },

    {
      path: '/Yyrycx',
      name: 'Yyrycx',
      component: Yyrycx,
    },

    {
      path: '/Yyry',
      name: 'Yyry',
      component: Yyry,
    },

    {
      path: '/Ssxx',
      name: 'Ssxx',
      component: Ssxx,
    },

    {
      path: '/jcjy_yydj',
      name: 'jcjy_yydj',
      component: jcjy_yydj,
    },

    {
      path: '/yyjj',
      name: 'yyjj',
      component: Yyjj,
    },

    {
      path: '/Wisdom',
      name: 'Wisdom',
      component: Wisdom,
    },

    {
      path: '/CopiseofCase',
      name: 'CopiseofCase',
      component: CopiseofCase,
    },

    {
      path: '/Satisfied',
      name: 'Satisfied',
      component: Satisfied,
    },

    {
      path: '/Feedback',
      name: 'Feedback',
      component: Feedback,
    },

    {
      path: '/ZyjkResult',
      name: 'ZyjkResult',
      component: ZyjkResult,
    },
    {
      path: '/Contact',
      name: 'Contact',
      component: Contact,
    },

    {
      path: '/zyjk',
      name: 'zyjk',
      component: zyjk,
    },

    {
      path: '/HospitalInfo',
      name: 'HospitalInfo',
      component: HospitalInfo,
    },

    {
      path: '/Jkjl',
      name: 'Jkjl',
      component: Jkjl,
    },

    {
      path: '/Report',
      name: 'Report',
      component: Report,

    },
    {
      path: '/ReportExamine',
      name: 'ReportExamine',
      component: ReportExamine,

    },

    {
      path: '/ReportInspect',
      name: 'ReportInspect',
      component: ReportInspect,

    },
    {
      path: '/PaymentResult',
      name: 'PaymentResult',
      component: PaymentResult,

    },
    {
      path: '/WaitInfo',
      name: 'WaitInfo',
      component: WaitInfo,

    },

    {
      path: '/Paymentlist',
      name: 'Paymentlist',
      component: Paymentlist,

    },

    {
      path: '/payment',
      name: 'payment',
      component: payment,

    },
    
    {
      path: '/Scheduling',
      name: 'Scheduling',
      component: Scheduling,

    },

    {
      path: '/RegRecord',
      name: 'RegRecord',
      component: RegRecord,

    },

    {
      path: '/Resource',
      name: 'Resource',
      component: Resource,

    },

    {
      path: '/Doctor_yygh',
      name: 'Doctor_yygh',
      component: Doctor_yygh,

    },

    {
      path: '/Showcard',
      name: 'Showcard',
      component: ShowCard,

    },

    {
      path: '/Protocol',
      name: 'Protocol',
      component: Protocol,

    },

    {
      path: '/bindCard',
      name: 'bindCard',
      component: BindCard,
      meta: {
        title: '绑定医院卡片'
      }
    },
    {
      path: '/createCard',
      name: 'createCard',
      component: CreateCard,
      meta: {
        title: '自助建卡'
      }
    },
    {
      path: '/Cards',
      name: 'cards',
      component: Cards,
      meta: {
        title: '选择医生'
      }
    },

    {
      path: '/Office',
      name: 'Office',
      component: Office
    },
    {
      path: '/todayResult',
      name: 'todayResult',
      component: Result
    },
    {
      path: '/Order',
      name: 'Order',
      component: Order
    },

    {
      path: '/YdzfOrder',
      name: 'YdzfOrder',
      component: YdzfOrder
    },

    {
      path: '/Doctor',
      name: 'Doctor',
      component: Doctor,
      meta: {
        title: '选择医生'
      }
    },
   

  ]
})

export default router
