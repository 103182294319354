<template>
    <div class="mainer">
        <!-- 导航栏 -->
        <van-nav-bar title="预约检查检验" @click-left="onClickLeft" @click-right="onClickRight">
            <template #right>
                切换就诊人
                <van-icon name="exchange" size="18" />
            </template>
            <template #left>
                <van-icon name="arrow-left" /> 返回
            </template>
        </van-nav-bar>

        <van-cell-group class="byxx">
            <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
                :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
            </van-cell>
            <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
                <template #title>
                    <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
                </template>

            </van-cell>
        </van-cell-group>

        <van-steps :active="data.step" @click-step="onstep">
            <van-step>预约时间</van-step>
            <van-step>选择科室</van-step>
            <van-step>选择项目</van-step>
         
        </van-steps>

        <div v-if="data.defaultCard.kh">
            <div v-if="data.step == 0">
                <Yyrq  @getyyrq="getyyrq"></Yyrq>
            </div>
            <div v-else-if="data.step == 1">
                <Ksxx @xzks="xzks"></Ksxx>
            </div>
            <div v-else-if="data.step == 2">
                <Xmxx :zxks="data.ksbm" :kh="data.defaultCard.kh" :openid="data.openid" :yyrq="data.yyrq" @getxmxx="getxmxx"></Xmxx>
            </div>
        <!--      <div v-else-if="data.step == 3">
                <Order :zyxx="data.zyxx" :Address="data.Address" :copise="data.copise" @getorderid="getorderid"></Order>
            </div>
           <div v-else="data.step==4">
                <Result :orderid="data.orderid" ></Result>
            </div> -->
        </div>
    </div>

    <div class="button">
                <van-button type="primary" round block @click="back" v-if="data.step">返回上一步</van-button>
    </div>

    <!--切换就诊人 -->
    <van-popup v-model:show="data.show" round closeable position="bottom">
        <div class="footer">
            <van-cell-group>
                <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
                    v-for="(card, k) in data.cardlist" :key="k" @click="back"></van-cell>
            </van-cell-group>
        </div>
    </van-popup>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

import Yyrq from '@/components/jcjy_yydj/Yyrq.vue'
import Ksxx from '@/components/jcjy_yydj/Ksxx.vue'
import Xmxx from '@/components/jcjy_yydj/Xmxx.vue'
// import Order from '@/components/copiseofcase/Order.vue'
// import Result from '@/components/copiseofcase/Result.vue'

var data = ref({
    yyrq:'',
    ksbm:'',
    xmxx:[],
    show: false,
    step: 0,
    openid: '',
    cardlist: [],
    defaultCard: {},    
    Address:{},
    copise:[],
    orderid:""
 
}
)

function back(){
    
 data.value.step=data.value.step-1

}

// 第一步获取预约日期
function getyyrq(yyrq){
    data.value.yyrq=yyrq
    console.log('收到yyrq')
    console.log(data.value.yyrq)
    data.value.step=1

}



// 第二步 获取科室
function xzks(ksbm){
    data.value.ksbm=ksbm
    console.log('收到ksbm')
    console.log(data.value.ksbm)
    data.value.step=2

}
// 第三步 获取项目信息
function getxmxx(xmxx){
    data.value.xmxx=xmxx
    console.log('获取项目信息xmxx')
    console.log(data.value.xmxx)
    data.value.step=3
}

function getorderid(orderid){
    data.value.orderid=orderid
    console.log('data.value.orderid')
    console.log(data.value.orderid)
    data.value.step=4
}


onMounted(() => {
    data.value.openid = useopenidStore().data.openid
 getcardlist()
}
)

function onstep(index) {
    console.log(index)

    data.value.step = index

}

function getcardlist() {
    let params = {
        lx: 'getcardbyopenid',
        openid: data.value.openid
    }
    console.log(params)
    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 100000
    });

    postJson('WxpayService', params, 2).then(res => {
        console.log(res)
        if (res != null) {
            if (res.data.length) {
                console.log('查询cardlist正常返回')
                data.value.cardlist = res.data
                bindcard();
                console.log(data.value.cardlist)
                closeToast();
            } else {
                showToast('请先绑定就诊卡');

            }
        }
    }).catch(err => {
        console.log('查询cardlist错误返回')
        console.log(err)
        showFailToast(err)
    })
}

function bindcard() {
    if (data.value.cardlist.length > 0) {
        data.value.cardlist.forEach(element => {
            console.log(element)
            if (element.xz) {
                data.value.defaultCard = element
                console.log(element)
            }
        });
    }
    else {
        showNotify({ type: "danger", message: "没有卡号" })
    }
}

function onClickLeft() {
    history.back();
}


function onClickRight() {
    data.value.step=1
    data.value.show = true
    
}
function choosecard(k) {
   
    data.value.defaultCard = data.value.cardlist[k]
    data.value.show = false;
    setDefault(data.value.defaultCard.id)

    console.log('data.value.step:'+data.value.step)
    data.value.step=0
    console.log('data.value.step:'+data.value.step)
}

function setDefault(id) {
    console.log('默认值id:' + id)
    let params = {
        lx: 'setdefaultcard',
        id: id,
        openid: data.value.openid,
    }
    postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret == 0) {
            getcardlist()

        }
        else {

            showFailToast(res.data.reinfo);
        }
    })
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.office {
    width: 100%;
}
.button{
    margin: 5px;
    

}
</style>