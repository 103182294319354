<template>
    <div class="mainer">
        <!-- 导航栏 -->
        <van-nav-bar title="住院信息" @click-left="onClickLeft" @click-right="onClickRight">
            <template #right>
                切换就诊人
                <van-icon name="exchange" size="18" />
            </template>
            <template #left>
                <van-icon name="arrow-left" /> 返回
            </template>
        </van-nav-bar>

        <van-cell-group class="byxx">
            <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
                :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
            </van-cell>
            <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
                <template #title>
                    <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
                </template>

            </van-cell>
        </van-cell-group>

        <div class="parter">
            <div v-if="data.zyxxlist.length">
                <van-cell-group inset v-for="(info, i) in data.zyxxlist" :key="k">
                    <van-cell title="病员姓名" size="large" :value="info.byxm" />
                    <van-cell title="性别" size="large" :value="info.xb" />
                    <van-cell title="就诊卡" size="large" :value="info.zykh" />
                    <van-cell title="住院号" size="large" :value="info.zyh" />
                    <van-cell title="科室" size="large" :value="info.ksmc" />
                    <van-cell title="病床号" size="large" :value="info.bch" />
                    <van-cell title="入院时间" size="large" :value="new Date(info.rysj).format('yyyy-MM-dd hh:mm:ss')" />
                    <van-cell title="收费种类" size="large" :value="info.sfzl" />
               
                    <van-cell title="预交款总额" size="large">
                        <template #right-icon>
                            <span style="color: #ED6A0C;">¥{{ info.jkze }}</span>
                        </template>
                    </van-cell>
                    <van-cell title="费用总额" size="large">
                        <template #right-icon>
                            <span style="color: #ED6A0C;">¥{{ info.fyze }}</span>
                        </template>
                    </van-cell>
                    <div class="button">
                        <van-button type="primary" round block @click="zyjkcx(info.zyh)">交款记录</van-button>
                    </div>
                    <div class="button">
                        <van-button type="primary" round block @click="zyjk(info)">住院交款</van-button>
                    </div>
                    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
                        下一个记录
                    </van-divider>
                </van-cell-group>


            </div>

            <van-empty description="暂无住院记录" v-else />
        </div>






        <!--切换就诊人 -->
        <van-popup v-model:show="data.show" round closeable position="bottom">
            <div class="footer">
                <van-cell-group>
                    <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
                        v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
                </van-cell-group>
            </div>
        </van-popup>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'


var data = ref({
    openid: '',
    show: false,
    cardlist: [],
    defaultCard: {},
    zyxxlist: []
}
)

function zyjkcx(zyh) {
    router.push({
        path: '/Jkjl',
        query: {
            zyh: zyh

        }
    })
}

function zyjk(info) {
if (data.value.zdrq == '1') {
    showToast('重点人群,用交款');
    return
}

    router.push({
        path: '/zyjk',
        query: {
            info: JSON.stringify(info)
        }
    })
}
function getzyxx() {
    let params = {
        lx: 'getzyxx',
        kh: data.value.defaultCard.kh
    }
    postJson('Wechat/Service', params, 2).then(res => {
        data.value.zyxxlist = res.data
    })
}

onMounted(() => {
    data.value.openid = useopenidStore().data.openid
    getcardlist()
}
)

function getcardlist() {
    let params = {
        lx: 'getcardbyopenid',
        openid: data.value.openid
    }
    console.log(params)
    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 100000
    });

    postJson('WxpayService', params, 2).then(res => {
        console.log(res)
        if (res != null) {
            if (res.data.length) {
                console.log('查询cardlist正常返回')
                data.value.cardlist = res.data
                bindcard();
                console.log(data.value.cardlist)
                closeToast();
            } else {
                showToast('请先绑定就诊卡');

            }
        }
    }).catch(err => {
        console.log('查询cardlist错误返回')
        console.log(err)
        showFailToast(err)
    })
}

function bindcard() {
    if (data.value.cardlist.length > 0) {
        data.value.cardlist.forEach(element => {
            console.log(element)
            if (element.xz) {
                data.value.defaultCard = element
                console.log(element)
                getzyxx();
            }
        });
    }
    else {
        showNotify({ type: "danger", message: "没有卡号" })
    }
}

function onClickLeft() {
    history.back();
}


function onClickRight() {
    data.value.show = true
}
function choosecard(k) {
    data.value.defaultCard = data.value.cardlist[k]
    data.value.show = false;
    setDefault(data.value.defaultCard.id)
}

function setDefault(id) {
    console.log('默认值id:' + id)
    let params = {
        lx: 'setdefaultcard',
        id: id,
        openid: data.value.openid,
    }
    postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret == 0) {
            getcardlist()

        }
        else {

            showFailToast(res.data.reinfo);
        }
    })
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #1989fa;
}

.office {
    width: 100%;
}

.button {
    margin-top: 20px;
}
</style>