<template>
    <div class="mainer">
      <!-- 导航栏 -->
      <van-nav-bar title='医院简介' @click-left="onClickLeft" @click-right="onClickRight">
        <template #right>
          <van-icon name="wap-home-o" size="18" />
        </template>
        <template #left>
          <van-icon name="arrow-left" /> 返回
        </template>
      </van-nav-bar>
  
     
      <p v-html="data.content"></p>
      
    
  
    </div>
  </template>
  
  <script setup>
  
  import { onMounted, ref } from 'vue'
  import {get} from '@/api/axiosInstance'
  import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
  import router from '@/router'
  import { useopenidStore } from '@/stores'
  
  
  var data = ref({
    openid: '',
    url: 'https://mp.weixin.qq.com/s/FoSoUmjhQhHB91Yj6yRsEA',
    content: '',
 
  }
  )
  
  
  onMounted(() => {
    data.value.openid = useopenidStore().data.openid

    get(data.value.url,'',0).then(res => {
         data.value.content = res.data
        console.log(data.value.content)
        
    }).catch(error => {
    showToast({
      message: error,
      position: 'top',
    });
  })
}
)
  


  function onClickLeft() {
    history.back();
  }
  
  
  function onClickRight() {
      router.push({ path: "/" });
  }
  
  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
  .van-nav-bar {
    background: #1989fa;
  
    /deep/ .van-nav-bar__title {
      color: white;
    }
  
    /deep/ .van-icon {
      color: white;
    }
  
    /deep/ .van-nav-bar__right {
      color: white;
    }
  
    /deep/ .van-nav-bar__left {
      color: white;
    }
  }
  
  .byxx {
    background-color: #888;
  }
  
  .office {
    width: 100%;
  }
  </style>