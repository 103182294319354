<template>
    <div class="mainer">
        <!-- 导航栏 -->
        <van-nav-bar title=智能导诊 @click-left="onClickLeft" @click-right="onClickRight">
            <template #right>
                切换就诊人
                <van-icon name="exchange" size="18" />
            </template>

            <template #left>
                <van-icon name="arrow-left" /> 返回
            </template>
        </van-nav-bar>

        <!-- 病人信息 -->
        <van-cell-group class="byxx">
            <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
                :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
            </van-cell>

            <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
                <template #title>
                    <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
                </template>

            </van-cell>
        </van-cell-group>


        <van-steps :active="data.step" @click-step="onstep">
            <van-step>选择病人类型</van-step>
            <van-step>选择病症</van-step>
            <van-step>推荐科室</van-step>
            <van-step>就诊</van-step>
        </van-steps>


        <div v-if="1 == 1">
            <div v-if="data.step == 0">
                <Choosexb @getbw="getbw" @getxb="getxb" :xbbm="data.xb"></Choosexb>
            </div>
            <div v-else-if="data.step == 1">
                <Symptom :activeIndex="data.activeIndex" @getid="getid" :xb="data.xb"></Symptom>
            </div>
            <div v-else-if="data.step == 2">
                <Office :id="data.id"  @getks="getks"></Office>
            </div>
            <div v-else-if="data.step == 3">
                <Department :department="data.department" :defaultCard="data.defaultCard" :cardlist="data.cardlist"></Department>
            </div>

        </div>

    <!--切换就诊人 -->
    <van-popup v-model:show="data.show" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>

    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

import Choosexb from '@/components/wisdom/Choosexb.vue'
import Symptom from '@/components/wisdom/Symptom.vue'
import Department from '@/components/wisdom/Department.vue'
import Office from '@/components/wisdom/Office.vue'

var data = ref({
    openid: '',
    show: false,
    cardlist: [],
    defaultCard: {},
    step: 0,
    activeIndex: -1,
    department: {},
    xb:1,
    id:1,
}
)


function getcardlist() {
  let params = {
    lx: 'getcardbyopenid',
    openid: data.value.openid
  }
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
    if (res != null) {
      if (res.data.length) {
        console.log('查询cardlist正常返回')
        data.value.cardlist = res.data
        bindcard();
        console.log(data.value.cardlist)
        closeToast();
      } else {
        showToast('请先绑定就诊卡');
        showFailToast(err)
      }
    }
  }).catch(err => {
    console.log('查询cardlist错误返回')
    console.log(err)
    showFailToast(err)
  })
}


function bindcard() {
  if (data.value.cardlist.length > 0) {
    data.value.cardlist.forEach(element => {
      console.log(element)
      if (element.xz) {
        data.value.defaultCard = element
        console.log(element)
      }
    });
  }
  else {
    showNotify({ type: "danger", message: "没有卡号" })
  }
}

function choosecard(k) {
  data.value.defaultCard = data.value.cardlist[k]
  data.value.show = false;
  setDefault(data.value.defaultCard.id)
  data.value.step=0
}

onMounted(() => {
    data.value.openid=useopenidStore().data.openid;
    if (data.value.openid){    

    getcardlist()}
    else {
        showFailToast('没有openid，不能使用')
       history.back();
    }
}
)

function onClickLeft() {
    history.back();
}


function onClickRight() {
  data.value.show = true
}

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()

    }
    else {

      showFailToast(res.data.reinfo);
    }
  })
} 

function onstep(index) {
    console.log(index)
    data.value.step = index
}

function getimageUrl(imageUrl) {
    data.value.imageUrl = imageUrl;

}

function getbw(activeIndex) {
    data.value.activeIndex = activeIndex;
    data.value.step++
}

function getxb(xb) {
    console.log('性别编码：'+xb)
    data.value.xb = xb;
 
}

function getid(id) {
    console.log('选择的病症id：'+id)
    data.value.id = id;
    data.value.step++
 
}

function getks(department) {
    data.value.department = department
    data.value.step++
    console.log('选择的科室：' +JSON.stringify(data.value.department))
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.office {
    width: 100%;
}
</style>