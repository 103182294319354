<template>
   <van-date-picker
  v-model="data.currentDate"
  title="选择日期"
  :min-date="data.minDate"
  :max-date="data.maxDate"
  @confirm="onconfirm"

/>

</template>

<script setup>

import { onMounted, ref } from 'vue'



const emit = defineEmits(['getyyrq'])

var data = ref({
  minDate: new Date(),
  maxDate: new Date(),
  currentDate:[]
}
)


function onconfirm(){

  console.log(data.value.currentDate[0],data.value.currentDate[1],data.value.currentDate[2])
  var yyrq= data.value.currentDate[0] + '-' + data.value.currentDate[1] + '-' + data.value.currentDate[2]
   emit('getyyrq',yyrq)

}

onMounted(() => {
  data.value.maxDate.setDate(data.value.maxDate.getDate() + 7);
  data.value.maxDate = new Date(data.value.maxDate);

}
)


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>