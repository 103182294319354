<template>
  <van-cell-group>
      <van-cell size="large" :title="office.ksmc" is-link @click.native="xzks(office.ksbm)"
        v-for="office in data.officelist" :key="office.ksbm" />
    </van-cell-group>

</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import {  showFailToast } from 'vant';

const emit = defineEmits(['xzks'])

var data = ref({
  officelist: [],
}
)


function xzks(ksbm){
  console.log(ksbm)
   emit('xzks',ksbm)

}

function getksxx() {
  postJson('Wechat/Service', { 'lx': 'jcjy_getksxx' }, 2).then(res => {
    console.log('正常返回officelist')
    console.log(res)
    data.value.officelist = res.data
   
  }).catch(err => {
    console.log('查询科室列表错误返回')
    console.log(err)
    showFailToast(err)
  })
}


onMounted(() => {
getksxx()

}
)


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>