<template>

   <div class="mainer">
      <van-nav-bar :title="data.ghlxmc" @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
      <!-- <template #left>
        <van-icon name="arrow-left" /> 返回
      </template> -->
    </van-nav-bar>
  
    <!-- <van-notice-bar mode="closeable" v-if="data.status< 3">挂号中，请等待系统通知！</van-notice-bar>
    <van-notice-bar mode="closeable"   v-if="data.status = 3 ">挂号成功</van-notice-bar>
    <van-notice-bar mode="closeable"    v-if="data.status >3 ">挂号失败，系统将自动退费！</van-notice-bar> -->

   <div class="bodyer">
      <van-cell-group inset>
        <van-row class="doctor-row">
          <van-col span="8" style="text-align: center;">
            <van-image round width="90px" height="90px" :src="data.result.photoUrl">
              <template v-slot:error v-if="data.result.surname"><span class="xingshi">{{ result.surname }}</span></template>
            </van-image>
          </van-col>
          <van-col span="16">
            <div class="xm">{{ data.result.ysxm }}</div>
            <div class="lbmc">{{ data.result.lbmc }}</div>
          </van-col>
        </van-row>
        <van-cell size="large" title="卡号" :value="data.result.tmh" />
        <van-cell size="large" title="病人姓名" :value="data.result.byxm" />
        <van-cell size="large" title="门诊号" :value="data.result.mzh" />    
        <van-cell size="large" title="就诊科室" :value="data.result.ksmc" />
        <van-cell size="large" title="就诊日期" :value="data.result.ghrq" />
        <van-cell size="large" title="就诊时间" :value="data.result.shiduan" />
        <van-cell size="large" title="就诊序号" :value="data.result.ysh" />
        <van-cell size="large" title="统筹支付" :value="data.result.tczf" />
        <van-cell size="large" title="医保个账支付" :value="data.result.zhzf" />
        <van-cell size="large" title="微信支付" :value="data.result.ydzf" />
        <van-cell size="large" title="挂号总费用">
          <template #right-icon>
            <div style="color: #ED6A0C;">¥{{ data.result.hj || 0 }}</div>
          </template>
        </van-cell>

        <van-cell size="large" title="状态">
          <template #right-icon>
            <div style="color: #07C160;" v-if="data.status == 3 && data.ghlx<3">挂号成功</div>
            <div style="color: #07C160;" v-else-if="data.status == 3 && data.ghlx==3">退单成功</div>

            <div style="color: #576b95;" v-else-if="data.status == 1 && data.ghlx<3 ">挂号中，请等待系统通知</div>
            <div style="color: #576b95;" v-else-if="data.status == 1 && data.ghlx==3 ">退单中，请等待系统通知</div>

            <div style="color: #ED6A0C;" v-else="data.status > 3 && data.ghlx <3 ">挂号失败，系统将自动退费</div>
            <div style="color: #ED6A0C;" v-else="data.status > 3 && data.ghlx==3  ">退单失败，请联系医院</div>
          </template>
        </van-cell>

        <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">就诊患者</van-divider>
        <van-cell size="large" :title="data.result.byxm" :value="data.result.tmh" />
      </van-cell-group>


            <div v-if="data.result.dzfp" >
              <div class="shouming">
                电子发票
             </div>
              <div class="qrcode">
                    <vue-qr :logoSrc="data.imageUrl" :text="data.result.dzfp" :size="200"  ></vue-qr>
             </div>
            </div>
      <div class="shouming1">
        退费操作，会有4-5秒延时，请勿重复操作！请耐心等到微信退费到账通知后，点击刷新按钮，查看情况！
      </div>

      <div class="button">
          <van-button round type="primary" @click="getjg" block >刷新结果</van-button>       
      </div>       
      <div class="button">
        <van-button round type="primary" to="/RegRecord" block >挂号记录</van-button>
      </div>     
    </div>
    
  </div>

</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

function onClickLeft() {
  // history.back();
}
const onClickRight = () => router.push({ path: "/" });



var data=ref({
  querylx:'',
  status:0,
  ghlx:'' ,
  ghlxmc:"",
  result:{},
  imageUrl:'',
  channel: 'wxpay',
})



onMounted(()=>{
  data.value.ghlx=router.currentRoute.value.query.ghlx;
  data.value.channel=router.currentRoute.value.query.channel;
  data.value.imageUrl = new URL('./image/hyxrmyylogo.png', import.meta.url).href

  if (data.value.ghlx==1){
    data.value.ghlxmc='当日挂号结果'
    data.value.querylx='queryorder'
  }
  if (data.value.ghlx==2){
    data.value.ghlxmc='预约挂号结果'
    data.value.querylx='queryorder'
  }
  if (data.value.ghlx==3){
    data.value.ghlxmc='退单结果'
    data.value.querylx='RefundqueryOrder'
  }


  getjg();


})

   
 

function getjg(){
  let params={
  lx:data.value.querylx,
  orderid: router.currentRoute.value.query.orderid

 }

  postJson('WxpayService', params, 2).then(res => {
     // Indicator.close();
     console.log(res)
      if (res != null ) {                    
       data.value.result=res.data.result.data      
       data.value.status=res.data.status
       console.log(data.value)

      }
    }).catch(err => {
   //   Indicator.close();
    });
}


    
   function closePage() {
      wx.closeWindow()
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.bodyer {
  margin-top: 1em;
}

.button {
  margin: 0 auto;
  margin-top: 2em;
  width: 90%;
}

.doctor-row {
  margin-top: 12px;
}

.lbmc {
  font-size: 14px;
  color: #646566;
}

.xm {
  font-size: 18px;
  font-weight: 700;
}

.keshi {
  font-size: 14px;
  margin-top: 20px;
}
.xingshi {
  font-size: 3em;
  font-weight: 700;
}
.shouming {
  text-align: center;

}
.shouming1 {
  text-align: center;
  font-size: 14px;
  color:  #ED6A0C;
}
.qrcode {
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}
</style>