<template>
  <van-nav-bar title="选择医生" @click-left="onClickLeft" @click-right="onClickRight">
    <template #right>
      切换就诊人
      <van-icon name="exchange" size="18" />
    </template>
    <template #left>
      <van-icon name="arrow-left" /> 返回
    </template>
  </van-nav-bar>

     <!-- 病人信息 -->
     <van-cell-group class="byxx">
      <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
        :to="'/ShowCard?id=' + data.defaultCard.id"  v-if="data.defaultCard">
      </van-cell>

      <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
        <template #title>
          <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
        </template>

      </van-cell>
    </van-cell-group>

  <div class="doctor">
    <div class="doctor-mainer">
      <div class="doctor-lister" v-for="(v, k)  in data.doctors" @click="choosedoctor(v)">
        <div class="doctor-photo">
          <div class="doctor-img"><img :src="v.photoUrl || doctorImg" width="60" height="80" /></div>
        </div>
        <div class="doctor-body">
          <div class="doctor-title">
            <div class="doctor-name">{{ v.ysxm }}</div>
            <div class="doctor-tx">{{ v.lbmc }}</div>
          </div>
          <div class="doctor-intro">
            {{ v.ysjs || '完善中...' }}
          </div>
          <div class="doctor-plan">
            <div>{{ v.bbmc }}</div>
            <div>余号：{{ v.syhs }}</div>
            <div>挂号费：{{ v.ghf }}</div>
            <div>></div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!--切换就诊人 -->
  <van-popup v-model:show="data.show" round closeable position="bottom">
      <div class="footer">
        <van-cell-group>
          <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
            v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
        </van-cell-group>
      </div>
    </van-popup>

</template>

<script setup>
import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'

const onClickLeft = () => history.back();

var data=ref({
      show:false,
      openid:"",
      ksbm:'',
      ksmc:'',
      doctors:[],
      defaultCard:{},
      cardlist:[]
})

// doctorImg: '../../../static/img/doctor_male.png'

onMounted(()=>{
  console.log("进入Doctor.onmounted")
    data.value.openid=useopenidStore().data.openid;
    if (data.value.openid){      
      let officeparams=JSON.parse(router.currentRoute.value.query.officeparams);
      data.value.ksbm = officeparams.ksbm
      data.value.ksmc = officeparams.ksmc
      data.value.defaultCard=officeparams.defaultCard
      data.value.cardlist=officeparams.cardlist;
      console.log("接受到的卡")
      console.log(data.value.defaultCard)

      get_doctors()
      get_cardlist()
} 
  else {
  showToast('系统异常');
} 
     



function get_doctors(){
  let params = { "lx": 'getdrhy', "ksbm": data.value.ksbm}  
       postJson('Wechat/Service', params, 2).then(res => {
       data.value.doctors = res.data
       console.log("获取当日号源")
       console.log(res.data)
   }
       )
}


})
function choosedoctor(doctor) {
  let resourceparam={
        ghlx:1,
        ksbm: data.value.ksbm,
        ksmc: data.value.ksmc,
        doctor:doctor,
        defaultCard:data.value.defaultCard
  }

  if (doctor.syhs > 0) {
    router.push({
      path: '/Resource', query: {
            resourceparam:JSON.stringify(resourceparam)
               }
    })
  } else {
    showToast('已无余号');
  }
}

function onClickRight() {
      data.value.show = true
    }
function choosecard(k) {
      data.value.defaultCard = data.value.cardlist[k]
      data.value.show = false;

      setDefault(data.value.defaultCard.id)
   
    }

function setDefault(id) {
  console.log('默认值id:' + id)
  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }
  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
     get_cardlist()
    }
    else {
      showFailToast(res.data.reinfo);
    }
  })
} 

function get_cardlist(){
let params={
  lx:'getcardbyopenid',
  openid:data.value.openid
 }
  console.log(params)
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    loadingType: 'spinner',
    duration: 100000
  });

  postJson('WxpayService', params, 2).then(res => {
    console.log(res)
      if (res != null ) {
        if (res.data.length) {
          console.log('查询cardlist正常返回')
          data.value.cardlist = res.data   
          console.log(data.value.cardlist)           
          closeToast();
        } else {
          showToast('请先绑定就诊卡');
          showFailToast(err)
        }
      }
    }).catch(err => {
      console.log('查询cardlist错误返回')
      console.log(err)
      showFailToast(err)
    })
  }

 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}
.doctor {
  width: 100%;
}

.doctor-lister {
  background: #ffffff;
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
}

.doctor-photo {
  width: 80px;
  padding-top: 2rem;
}

.doctor-img {
  width: 60px;
  height: 80px;
  margin: 0 auto;
  border-radius: 60px;
  overflow: hidden;
}

.doctor-body {
  padding-left: 1rem;
  flex: 1;
}

.doctor-title {
  display: flex;
}

.doctor-name {
  margin-right: 0.5rem;
}

.doctor-tx {
  border: 1px solid #E6A23C;
  color: #E6A23C;
  font-size: 0.5rem;
  border-radius: 4px;
  padding: 2px;
  height: 0.6rem;
  overflow: hidden;
}

.doctor-intro {
  color: #909399;
  font-size: 0.75rem;
  padding-top: 0.5rem;
}

.doctor-plan {
  border-top: 1px solid #E4E7ED;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
}</style>