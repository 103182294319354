/**
* axios.js提供request请求封装
* 包括 get、post、delete、put等方式
* @author: ldy
*/
import axios from 'axios';
import {aes, sign} from '@/api/crypto';
import { showToast, Button, showNotify, showFailToast, showLoadingToast, closeToast } from 'vant';

axios.defaults.withCredentials = true

const ajax = axios.create({
 baseURL: 'http://wxgzh.mshyxrmyy.com', // url前缀
 // baseURL: 'http://127.0.0.1:8081', // url前缀


  timeout: 30000, // 超时毫秒数
  withCredentials: true, // 携带认证信息cookie
});

const ajax_await = axios.create({
  baseURL: 'http://wxgzh.mshyxrmyy.com', // url前缀

  timeout: 30000, // 超时毫秒数
  withCredentials: true, // 携带认证信息cookie
});

const getUrlParam=(name) =>{
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}


/**
* get方式请求，url传参
* @param url 请求url
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const get = (url, params, level) => ajax(getConfig(url, 'get', true, params, level)).then(res => successback(res)).catch(error => errback(error));

/**
* post方式请求 json方式传参
* @param url 请求url
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const postJson = (url, params, level) => ajax(getConfig(url, 'post', true, params, level)).then(res => successback(res)).catch(error => errback(error));

const   postJson_async = (url, params, level) =>  ajax_await(getConfig(url, 'post', true, params, level)).then(res => successback(res)).catch(error => errback(error));

/**
* post方式请求 表单传参
* @param url 请求url
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const post = (url, params, level) => ajax(getConfig(url, 'post', false, params, level)).then(res => successback(res)).catch(error => errback(error));

/**
* delete方式请求 url传参
* @param url 请求url
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const del = (url, params, level) => ajax(getConfig(url, 'delete', true, params, level)).then(res => successback(res)).catch(error => errback(error));

/**
* put方式请求 json传参
* @param url 请求url
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const putJson = (url, params, level) => ajax(getConfig(url, 'put', true, params, level)).then(res => successback(res)).catch(error => errback(error));

/**
* put方式请求 表单传参
* @param url 请求url
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const put = (url, params, level) => ajax(getConfig(url, 'put', false, params, level)).then(res => successback(res)).catch(error => errback(error));


// 参数转换
const param2String = data => {
  // console.log('data', data);
  if (typeof data === 'string') {
    return data;
  }
  let ret = '';
  for (let it in data) {
    let val = data[it];
    if (typeof val === 'object' && //
        (!(val instanceof Array) || (val.length > 0 && (typeof val[0] === 'object')))) {
      val = JSON.stringify(val);
    }
    ret += it + '=' + encodeURIComponent(val) + '&';
  }
  if (ret.length > 0) {
    ret = ret.substring(0, ret.length - 1);
  }
  return ret;
};

// 错误回调函数
const errback = error => {
  console.log('错误回调')
  console.log(error)
  if (error) {
    var msg = ''
    if (typeof error.errormessage == 'string') {
      msg = error.errormessage
    } else {
      msg = error.msg
    }
    showLoadingToast({
      message: msg,
      position: 'top',
      duration: 3000
    })
    
    return Promise.reject(null);
  }
};

// 成功回调函数
const successback = res => {
  if (res.status === 200 && res.data.error === 0) {
    return res.data;
  } else if (res.status === 200 && res.data.error !== 0) {
   
    let errMsg = {22: '非法数据请求', 23: '签名验证失败', 24: '用户认证失败'};
    let msg = errMsg[res.data.code];
    if (msg) {
      showLoadingToast({
        message: errMsg[res.data.code],
        position: 'top',
        duration: 3000
      })
      return Promise.reject(null);
    } else {
      return Promise.reject(res.data);
    }
  } else {
    return Promise.reject(res.data);
  }
};

/**
* @param url 请求url
* @param method get,post,put,delete
* @param isjson 是否json提交参数
* @param params 参数
* @param level 0:无加密，1：参数加密，2: 签名+时间戳； 默认0
*/
const getConfig = (url, method, isjson, params, level = 0) => {
  let config_ = {
    url,
    method,
    // params, data,
    headers: {
      level
    }
  };
  // 时间戳
  if (level === 1) { // 加密
      params = {encrypt: aes.en(JSON.stringify(params))};
  } else if (level === 2) { // 签名
    let timestamp = new Date().getTime();
    // 获取token
    // let token = store.state.token;
    let token = '123456';
    if (!token) {
      showLoadingToast({
        message: "系统异常",
        position: 'top',
        duration: 3000
      })
      return;     
    }
    // 签名串
    let signstr = sign(token, timestamp, params);
    // console.log('token', token);
    // console.log('signstr', signstr);
    config_.headers = {
       level,
       timestamp,
       signstr,
       token,
    };
  }
  
   // 表单提交参数
  if (!isjson) {
    config_.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config_.responseType = 'text';
    config_.transformRequest = [function (data) {
      return param2String(data);
   }];
  }
  // 设置参数
  if (method in {'get': true, 'delete': true}) {
    config_.params = params;
  } else if (method in {'post': true, 'put': true}) {
    config_.data = params;
  }
  return config_;
};

// 统一方法输出口
export {
  ajax,
  get,
  postJson,
  postJson_async,
  post,
  del,
  putJson,
  put,
  getUrlParam
};