<template>
    <div class="body" v-if="data.zhuanshen == 1">
        <van-image width="400px" :src="imgUrl_nan1" v-if="data.imageUrl == 'imgUrl_nan'" />
        <van-image width="400px" :src="imgUrl_nv1" v-else-if="data.imageUrl == 'imgUrl_nv'" />
        <van-image width="400px" :src="imgUrl_tong1" v-else-if="data.imageUrl == 'imgUrl_tong'" />
    </div>
    <div v-else>
        <van-image width="400px" :src="imgUrl_nan2" v-if="data.imageUrl == 'imgUrl_nan'" />
        <van-image width="400px" :src="imgUrl_nv2" v-else-if="data.imageUrl == 'imgUrl_nv'" />
        <van-image width="400px" :src="imgUrl_tong2" v-else-if="data.imageUrl == 'imgUrl_tong'" />
    </div>

    <div class="xingbie">
        <van-button :icon="imgUrl_nan" text="男性" class="gender" @click="xuanze('imgUrl_nan',1)">
        </van-button>
        <van-button :icon="imgUrl_nv" text="女性" class="gender" @click="xuanze('imgUrl_nv',2)">
        </van-button>
        <van-button :icon="imgUrl_tong" text="儿童" class="gender" @click="xuanze('imgUrl_tong',3)">
        </van-button>
    </div>

    <div class="dibu">
        <van-button :icon="imgUrl_zhuan" text="转身" class="gender" @click="zhuanshen">
        </van-button>
    </div>



    <!-- 男性正面 -->
    <div v-if="data.imageUrl == 'imgUrl_nan' && data.zhuanshen == 1" class="dingwei">
        <div class="dw-nan-tou">
            <van-row><van-col offset="10" span="14" @click="luyou(11)" style="height: 80px ;"></van-col></van-row>
        </div>
        <div class="dw-nan-jing">
            <van-row><van-col offset="10" span="14" @click="luyou(5)" style="height: 50px;"></van-col></van-row>
        </div>
        <div class="dw-nan-xiong">
            <van-row>
                <van-col span="12" @click="luyou(10)" style="height: 80px;"></van-col>
                <van-col span="12" @click="luyou(14)" style="height: 80px;"></van-col>            
            </van-row>
        </div>
        
        <div class="dw-nan-fu">
            <van-row><van-col offset="10" span="14" @click="luyou(3)" style="height: 50px;"></van-col></van-row>
        </div>
        <div class="dw-nan-shou">
            <van-row>
                <van-col span="8" @click="luyou(10)" style="height: 70px;"></van-col>

                <van-col offset="8" span="8" @click="luyou(10)" style="height: 50px;"></van-col>
            </van-row>
        </div>
        <div class="dw-nan-tui">
            <van-row><van-col span="16" @click="luyou(13)" style="height: 230px;"></van-col></van-row>
        </div>
        <div class="dw-nan-jiao">
            <van-row><van-col offset="10" span="14" @click="luyou(13)" style="height: 60px; "></van-col></van-row>
        </div>
    </div>
    <!-- 男性背面 -->
    <div v-if="data.imageUrl == 'imgUrl_nan' && data.zhuanshen == 2" class="dingwei">
        <div class="dw-nan-bei">
            <van-row><van-col offset="8" span="16" @click="luyou(4)" style="height: 100px;"></van-col></van-row>
        </div>
        <div class="dw-nan-yao">
            <van-row><van-col offset="8" span="16" @click="luyou(16)" style="height: 75px;"></van-col></van-row>
        </div>
        <div class="dw-nan-tun">
            <van-row><van-col offset="8" span="16" @click="luyou(12)" style="height: 65px;"></van-col></van-row>
        </div>
    </div>


        <!-- 女性正面 -->
        <div v-if="data.imageUrl == 'imgUrl_nv' && data.zhuanshen == 1" class="dingwei">
        <div class="dw-nan-tou">
            <van-row><van-col offset="10" span="14" @click="luyou(27)" style="height: 80px ;"></van-col></van-row>
        </div>
        <div class="dw-nan-jing">
            <van-row><van-col offset="10" span="14" @click="luyou(21)" style="height: 50px;"></van-col></van-row>
        </div>
        <div class="dw-nan-xiong">
            <van-row>
                <van-col span="12" @click="luyou(26)" style="height: 80px;"></van-col>
                <van-col span="12" @click="luyou(30)" style="height: 80px;"></van-col>            
            </van-row>
        </div>
        
        <div class="dw-nan-fu">
            <van-row><van-col offset="10" span="14" @click="luyou(19)" style="height: 50px;"></van-col></van-row>
        </div>
        <div class="dw-nan-shou">
            <van-row>
                <van-col span="8" @click="luyou(26)" style="height: 70px;"></van-col>

                <van-col offset="8" span="8" @click="luyou(26)" style="height: 50px;"></van-col>
            </van-row>
        </div>
        <div class="dw-nan-tui">
            <van-row><van-col span="16" @click="luyou(29)" style="height: 230px;"></van-col></van-row>
        </div>
        <div class="dw-nan-jiao">
            <van-row><van-col offset="10" span="14" @click="luyou(29)" style="height: 60px; "></van-col></van-row>
        </div>
    </div>
    <!-- 女性背面 -->
    <div v-if="data.imageUrl == 'imgUrl_nv' && data.zhuanshen == 2" class="dingwei">
        <div class="dw-nan-bei">
            <van-row><van-col offset="8" span="16" @click="luyou(20)" style="height: 100px;"></van-col></van-row>
        </div>
        <div class="dw-nan-yao">
            <van-row><van-col offset="8" span="16" @click="luyou(32)" style="height: 75px;"></van-col></van-row>
        </div>
        <div class="dw-nan-tun">
            <van-row><van-col offset="8" span="16" @click="luyou(28)" style="height: 65px;"></van-col></van-row>
        </div>
    </div>

    <!-- 儿童正面 -->
    <div v-if="data.imageUrl == 'imgUrl_tong' && data.zhuanshen == 1" class="dingwei">
        <div class="dw-nan-tou_tong">
            <van-row><van-col offset="10" span="14" @click="luyou(0)"
                    style="height: 80px;"></van-col></van-row>
        </div>
        <div class="dw-nan-jing_tong">
            <van-row><van-col offset="10" span="14" @click="luyou(1)"
                    style="height: 45px;"></van-col></van-row>
        </div>

        <div class="dw-nan-xiong_tong">
            <van-row>
                <van-col span="12" @click="luyou(3)" style="height: 70px;"></van-col>
                <van-col  span="12" @click="luyou(2)" style="height: 70px;"></van-col>
              
            </van-row>
        </div>

        <div class="dw-nan-fu-tong">
            <van-row><van-col offset="10" span="14" @click="luyou(4)"
                    style="height: 55px;"></van-col></van-row>
        </div>
        <div class="dw-nan-shou">
            <van-row>
                <van-col span="8" @click="luyou(5)" style="height: 70px;"></van-col>


            </van-row>
        </div>
        <div class="dw-nan-tui-tong">
            <van-row><van-col span="16" @click="luyou(6)" style="height: 100px;"></van-col></van-row>
        </div>
        <div class="dw-nan-jiao">
            <van-row><van-col offset="10" span="14" @click="luyou(7)"
                    style="height: 60px;"></van-col></van-row>
        </div>
    </div>

    <!-- 儿童背面 -->
    <div v-if="data.imageUrl == 'imgUrl_tong' && data.zhuanshen == 2" class="dingwei">
        <div class="dw-tong-bei-tong">
            <van-row><van-col offset="8" span="16" @click="luyou(8)" style="height: 100px;"></van-col></van-row>
        </div>
        <div class="dw-tong-yao">
            <van-row><van-col offset="8" span="16" @click="luyou(9)" style="height: 40px;"></van-col></van-row>
        </div>
        <div class="dw-tong-tun-tong">
            <van-row><van-col offset="8" span="16" @click="luyou(10)" style="height: 50px;"></van-col></van-row>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant'
import router from '@/router'
import { useopenidStore } from '@/stores'

import imgUrl_nan from '@/assets/guide/nan.png'
import imgUrl_nv from '@/assets/guide/nv.png'
import imgUrl_tong from '@/assets/guide/tong.png'

import imgUrl_zhuan from '@/assets/guide/zhuan.png'

import imgUrl_nan1 from '@/assets/guide/nansheng1.png'
import imgUrl_nv1 from '@/assets/guide/nvsheng1.png'
import imgUrl_tong1 from '@/assets/guide/ertong1.png'

import imgUrl_nan2 from '@/assets/guide/nansheng2.png'
import imgUrl_nv2 from '@/assets/guide/nvsheng2.png'
import imgUrl_tong2 from '@/assets/guide/ertong2.png'


var data = ref({
    openid: '',
    imageUrl: 'imgUrl_nan',
    zhuanshen: 1
}
)

const emit = defineEmits(['getbw','getxb'])
const props = defineProps([
    "xbbm"
]
)

onMounted(() => {
    data.value.openid = useopenidStore().data.openid

    if (props.xbbm == 1) {
        data.value.imageUrl = 'imgUrl_nan'
    } else if (props.xbbm == 2) {
        data.value.imageUrl = 'imgUrl_nv'
    } else if (props.xbbm == 3) {
        data.value.imageUrl = 'imgUrl_tong'
    }
}
)

function xuanze(xb,xbbm) {
    data.value.imageUrl = xb
    console.log(data.value.imageUrl)
    emit('getxb',xbbm)
}

function zhuanshen() {
    if (data.value.zhuanshen == 1) {
        data.value.zhuanshen = 2
    } else {
        data.value.zhuanshen = 1
    }
    console.log(data.value.zhuanshen)
}

function luyou(buwei) {
    emit('getbw',buwei)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.body {
    text-align: right;
}

.xingbie {
    position: absolute;
    top: 220px;
    width: 80px;
    z-index: 999
}

.gender {
    border: none;
    font-size: 14px;
    background: none;
}

.dibu {
    position: fixed;
    bottom: 50px;
    z-index: 999
}

.dingwei {
    position: absolute;
    width: 100%;
    top: 178px;
}



.dw-nan-tou {
    width: 100%;
    height: 80px;
}

.dw-nan-tou_tong {
    width: 100%;
    height: 80px;
    margin-top: 40px;
}

.dw-nan-jing_tong {
    width: 100%;
    height: 45px;
}

.dw-nan-xiong {
    width: 100%;
    height: 80px;
}

.dw-nan-xiong_tong {
    width: 100%;
    height: 70px;
}

.dw-nan-bi {
    width: 100%;
    height: 40px;
}

.dw-nan-fu {
    width: 100%;
    height: 50px;
}

.dw-nan-fu-tong {
    width: 100%;
    height: 55px;
}

.dw-nan-shou {
    width: 100%;
    height: 70px;
}

.dw-nan-tui {
    width: 100%;
    height: 230px;
}
.dw-nan-tui-tong {
    width: 100%;
    height: 100px;
}

.dw-nan-jiao {
    width: 100%;
    height: 60px;
}

.dw-nan-bei {
    width: 100%;
    margin-top: 100px;
    height: 100px;
}

.dw-nan-yao {
    width: 100%;
    height: 75px;
}

.dw-nan-tun {
    width: 100%;
    height: 65px;
}

.dw-tong-bei {
    width: 100%;
    margin-top: 140px;
    height: 70px;
}

.dw-tong-bei-tong {
    width: 100%;
    margin-top: 140px;
    height: 100px;
}
.dw-tong-yao {
    width: 100%;
    height: 40px;
}

.dw-tong-tun {
    width: 100%;
    height: 40px;
}
.dw-tong-tun-tong {
    width: 100%;
    height: 50px;
}

.dw-tong-tou {
    width: 100%;
    margin-top: 30px;
    height: 70px;
}

.dw-tong-jing {
    width: 100%;
    height: 40px;
}

.dw-tong-xiong {
    width: 100%;
    height: 35px;
}

.dw-tong-bi {
    width: 100%;
    height: 30px;
}

.dw-tong-fu {
    width: 100%;
    height: 50px;
}

.dw-tong-shou {
    width: 100%;
    height: 50px;
}

.dw-tong-tui {
    width: 100%;
    height: 120px;
}

.dw-tong-jiao {
    width: 100%;
    height: 50px;
}</style>