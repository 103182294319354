
<template>
    <van-nav-bar title=缴费记录 @click-left="onClickLeft" @click-right="onClickRight">
        <template #right>
            切换就诊人
            <van-icon name="exchange" size="18" />
        </template>
        <template #left>
            <van-icon name="arrow-left" /> 返回
        </template>
    </van-nav-bar>
    <!-- 病人信息 -->
    <van-cell-group class="byxx">
        <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
            :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard">
        </van-cell>

        <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
            <template #title>
                <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
            </template>

        </van-cell>
    </van-cell-group>

    <!-- 缴费记录 -->
    <div v-if="data.list.length">
        <div class="lister" v-for="(item, k) in data.list" :key="k">
            <van-cell-group inset>

                <van-cell class=fymx size="large">
                    <template #title>
                        收款标识
                    </template>
                    <template #right-icon>
                        {{ item.skbs }}
                    </template>
                </van-cell>

                <van-cell size="large">
                    <template #title>
                        门诊号
                    </template>
                    <template #right-icon>
                        {{ item.mzh }}
                    </template>
                </van-cell>


                <van-cell size="large">
                    <template #title>
                        订单号
                    </template>
                    <template #right-icon>
                        {{ item.out_trade_no }}
                    </template>
                </van-cell>
                <van-cell size="large">
                    <template #title>
                        病员姓名
                    </template>
                    <template #right-icon>
                        {{ item.gh_out.byxm }}
                    </template>
                </van-cell>

                <van-cell size="large">
                    <template #title>
                        缴费时间
                    </template>
                    <template #right-icon>
                        {{ item.xdsj }}
                    </template>
                </van-cell>
                <van-cell size="large">
                    <template #title>
                        缴费总额
                    </template>
                    <template #right-icon>
                        <span style="color: #ED6A0C;">¥{{ item.total }}</span>
                    </template>
                </van-cell>

                <van-cell size="large" title="缴费明细" is-link arrow-direction="down" value="查看明细"
                    @click="showDetail(item.skbs)" />

                <van-cell-group v-if="data.showOrder == item.skbs">
                    <van-cell v-for="(li, key) in item.list" :key="key">
                        <template #title>
                            <span style="color:#FAAB0C;">{{ li.xmmc }}</span>
                        </template>
                        <template #label>
                            ¥{{ li.dj }} / {{ li.dw }} * {{ li.sl }} <hr/>注意事项： {{item.zysx}}
                        </template>
                        <template #right-icon>
                            <span style="color:#FAAB0C;">¥{{ li.yhhje }}</span>
                        </template>
                    </van-cell>
                </van-cell-group>

               
                <div v-if="item.dzfp" >
              <div class="shouming">
                电子发票
             </div>
              <div class="qrcode">
                    <vue-qr :logoSrc="data.imageUrl" :text="item.dzfp" :size="200"  ></vue-qr>
             </div>
            </div>


            </van-cell-group>
        </div>
    </div>
    <div v-else>
        <van-empty description="暂无门诊缴费记录" />
    </div>


    <!--切换就诊人 -->
    <van-popup v-model:show="data.show" round closeable position="bottom">
        <div class="footer">
            <van-cell-group>
                <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
                    v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
            </van-cell-group>
        </div>
    </van-popup>
</template>
<script  setup >

import { onMounted, ref } from 'vue'
import { postJson } from '@/api/axiosInstance.js'
import { showLoadingToast, showFailToast, closeToast, showToast, showNotify } from 'vant';
import router from '@/router'
import { useopenidStore } from '@/stores'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'



var data = ref({
    openid: '',
    showOrder: '',
    show: false,
    defaultCard: {},
    officelist: [],
    cardlist: [],
    list: [],
    imageUrl:''

})

function showDetail(skbs) {
    data.value.showOrder = skbs
}

function onClickLeft() {
    history.back();
}

function onClickRight() {
    data.value.show = true
}

function bindcard() {
    if (data.value.cardlist.length > 0) {
        data.value.cardlist.forEach(element => {
            
            if (element.xz) {
                data.value.defaultCard = element
          
            }
        });
    }
    else {
        showNotify({ type: "danger", message: "没有卡号" })
    }
}

function getcardlist() {
    let params = {
        lx: 'getcardbyopenid',
        openid: data.value.openid
    }
   
    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 100000
    });

    postJson('WxpayService', params, 2).then(res => {
        closeToast();
    
        if (res != null) {

            if (res.data.length) {
             
                data.value.cardlist = res.data
                bindcard();
          
                getRecords()

            } else {
                showToast('请先绑定就诊卡');

            }
        }
    }).catch(err => {
        showFailToast(err)
    })
}

function choosecard(k) {
    data.value.defaultCard = data.value.cardlist[k]
    data.value.show = false;
    setDefault(data.value.defaultCard.id)
    getRecords()
}

function setDefault(id) {
    console.log('默认值id:' + id)
    let params = {
        lx: 'setdefaultcard',
        id: id,
        openid: data.value.openid,
    }
    postJson('WxpayService', params, 2).then(res => {
        if (res.data.ret == 0) {
            getcardlist()

        }
        else {

            showFailToast(res.data.reinfo);
        }
    })
}


function getRecords() {

    let params = {
        lx: 'selectorderlist',
        kh: data.value.defaultCard.kh,
        order_type: 'mzsf'
    }

    postJson('WxpayService', params, 2).then(res => {
 
        if (res.error == 0) {
            let list = res.data
            list.forEach(function (order, i) {
                list[i].gh_in = JSON.parse(order.hisinputjosn)
                list[i].gh_out = JSON.parse(order.hisoutputjosn).data
                list[i].dzfp = JSON.parse(order.hisoutputjosn).data.dzfp
                list[i].xdsj = new Date(order.created_at).format("yyyy-MM-dd hh:mm:ss");
            }
            )
            data.value.list = list
            console.log(data.value.list)
        } else {
            showFailToast(res.errormessage)
        }
    }).catch(err => {
        console.log('缴费记录err')
        console.log(err)
        showFailToast(err)
        closeToast()

    })
}

onMounted(() => {
    data.value.openid = useopenidStore().data.openid;
    data.value.imageUrl = new URL('./image/hyxrmyylogo.png', import.meta.url).href
    getcardlist()
    
})

</script>

<style lang="less" scoped>
.van-nav-bar {
    background: #1989fa;

    /deep/ .van-nav-bar__title {
        color: white;
    }

    /deep/ .van-icon {
        color: white;
    }

    /deep/ .van-nav-bar__right {
        color: white;
    }

    /deep/ .van-nav-bar__left {
        color: white;
    }
}

.byxx {
    background-color: #888;
}

.fymx {
    background: #1989fa;
}

.shouming {
  text-align: center;
}
.qrcode {
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}
</style>